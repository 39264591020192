import React, { useState, useEffect } from 'react';
import { jiraService } from '../services/jiraService';
import { FiX, FiAlertCircle, FiCheck } from 'react-icons/fi';
import { ConsolidatedContent } from '../services/fileSystemService';

interface JiraExportDialogProps {
    isOpen: boolean;
    onClose: () => void;
    items: Array<{
        id: string;
        name: string;
        type: string;
        content?: string;
        consolidatedContent?: ConsolidatedContent;
    }>;
}

export const JiraExportDialog: React.FC<JiraExportDialogProps> = ({ isOpen, onClose, items }) => {
    const [selectedProject, setSelectedProject] = useState<string>('');
    const [projects, setProjects] = useState<Array<{ key: string; name: string }>>([]);
    const [parentStories, setParentStories] = useState<Array<{ key: string; summary: string }>>([]);
    const [selectedParent, setSelectedParent] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [exportStatus, setExportStatus] = useState<{ [key: string]: 'pending' | 'success' | 'error' }>({});
    const [exportMode, setExportMode] = useState<'new' | 'existing'>('new');
    const [existingRequirements, setExistingRequirements] = useState<Array<{ key: string; summary: string }>>([]);
    const [selectedRequirement, setSelectedRequirement] = useState<string>('');

    // Check if any item is a Test Scenario
    const hasTestScenarios = items.some(item => item.type === 'subtask-description');
    // Check if any item is a Requirement
    const hasRequirements = items.some(item => {
        return item.type === 'user-story' ||
            item.type.startsWith('REQ-') ||
            item.type === 'Story' ||
            item.type === 'story';
    });

    useEffect(() => {
        if (isOpen) {
            loadProjects();
        }
    }, [isOpen]);

    useEffect(() => {
        if (selectedProject) {
            if (hasTestScenarios) {
                loadParentStories();
            }
            if (hasRequirements && exportMode === 'existing') {
                loadExistingRequirements();
            }
        }
    }, [selectedProject, exportMode]);

    const loadProjects = async () => {
        try {
            const projectList = await jiraService.getProjects();
            setProjects(projectList);
            if (projectList.length > 0) {
                setSelectedProject(projectList[0].key);
            }
        } catch (error) {
            setError('Failed to load projects');
        }
    };

    const loadParentStories = async () => {
        try {
            const stories = await jiraService.getParentStories(selectedProject);
            setParentStories(stories.map(story => ({ key: story.key, summary: story.summary })));
            if (stories.length > 0) {
                setSelectedParent(stories[0].key);
            }
        } catch (error) {
            setError('Failed to load parent stories');
        }
    };

    const loadExistingRequirements = async () => {
        try {
            const requirements = await jiraService.getRequirementsForProject(selectedProject);
            setExistingRequirements(requirements);
            if (requirements.length > 0) {
                setSelectedRequirement(requirements[0].key);
            }
        } catch (error) {
            setError('Failed to load existing requirements');
        }
    };

    const handleExport = async () => {
        if (!selectedProject) {
            setError('Please select a project');
            return;
        }

        if (hasTestScenarios && !selectedParent) {
            setError('Please select a parent story for Test Scenarios');
            return;
        }

        if (exportMode === 'existing' && !selectedRequirement) {
            setError('Please select a target requirement');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const response = await jiraService.exportToJira(items.map(item => ({
                id: item.id,
                name: item.name,
                type: item.type,
                content: item.content,
                consolidatedContent: item.consolidatedContent,
                parentKey: item.type === 'subtask-description' ? selectedParent : undefined
            })), {
                projectKey: selectedProject,
                mode: exportMode,
                targetRequirement: exportMode === 'existing' ? selectedRequirement : undefined
            });

            // Update status for each item
            const newStatus: { [key: string]: 'pending' | 'success' | 'error' } = {};
            items.forEach(item => {
                newStatus[item.id] = response[item.id]?.status === 'error' ? 'error' : 'success';
            });
            setExportStatus(newStatus);

            // Close dialog after successful export
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (error) {
            setError('Failed to export items to Jira');
        } finally {
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-neutral-800 rounded-lg shadow-xl max-w-2xl w-full mx-4">
                <div className="p-6">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-xl font-semibold text-neutral-900 dark:text-white">
                            Export to Jira
                        </h2>
                        <button
                            onClick={onClose}
                            className="text-neutral-500 hover:text-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-200"
                        >
                            <FiX className="w-5 h-5" />
                        </button>
                    </div>

                    <div className="mb-6">
                        <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-2">
                            Target Project
                        </label>
                        <select
                            value={selectedProject}
                            onChange={(e) => setSelectedProject(e.target.value)}
                            className="input-base w-full"
                            disabled={isLoading}
                        >
                            {projects.map(project => (
                                <option key={project.key} value={project.key}>
                                    {project.name} ({project.key})
                                </option>
                            ))}
                        </select>
                    </div>

                    {hasRequirements && (
                        <div className="mb-6">
                            <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-2">
                                Export Mode
                            </label>
                            <select
                                value={exportMode}
                                onChange={(e) => setExportMode(e.target.value as 'new' | 'existing')}
                                className="input-base w-full"
                                disabled={isLoading}
                            >
                                <option value="new">Create as new requirement</option>
                                <option value="existing">Export to existing requirement</option>
                            </select>

                            {exportMode === 'existing' && (
                                <div className="mt-4">
                                    <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-2">
                                        Target Requirement
                                    </label>
                                    <select
                                        value={selectedRequirement}
                                        onChange={(e) => setSelectedRequirement(e.target.value)}
                                        className="input-base w-full"
                                        disabled={isLoading}
                                    >
                                        <option value="">Select Target Requirement</option>
                                        {existingRequirements.map(req => (
                                            <option key={req.key} value={req.key}>
                                                {req.key} - {req.summary}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="mt-2 flex items-center text-sm text-amber-600 dark:text-amber-400">
                                        <FiAlertCircle className="w-4 h-4 mr-2" />
                                        <span>New content will be appended to the existing requirement</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {hasTestScenarios && (
                        <div className="mb-6">
                            <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-2">
                                Parent Story for Test Scenarios
                            </label>
                            <select
                                value={selectedParent}
                                onChange={(e) => setSelectedParent(e.target.value)}
                                className="input-base w-full"
                                disabled={isLoading}
                            >
                                {parentStories.map(story => (
                                    <option key={story.key} value={story.key}>
                                        {story.summary} ({story.key})
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    <div className="mb-6">
                        <h3 className="text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-2">
                            Items to Export
                        </h3>
                        <div className="space-y-2">
                            {items.map(item => (
                                <div
                                    key={item.id}
                                    className="flex items-center justify-between p-3 bg-neutral-50 dark:bg-neutral-700/50 rounded-lg"
                                >
                                    <div>
                                        <div className="font-medium text-neutral-900 dark:text-white">
                                            {item.name}
                                        </div>
                                        <div className="text-sm text-neutral-500 dark:text-neutral-400">
                                            {item.type}
                                        </div>
                                    </div>
                                    {exportStatus[item.id] && (
                                        <div className="flex items-center">
                                            {exportStatus[item.id] === 'success' ? (
                                                <FiCheck className="w-5 h-5 text-green-500" />
                                            ) : exportStatus[item.id] === 'error' ? (
                                                <FiAlertCircle className="w-5 h-5 text-red-500" />
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    {error && (
                        <div className="mb-6 p-3 bg-red-50 text-red-700 rounded-lg">
                            {error}
                        </div>
                    )}

                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={onClose}
                            className="btn-secondary"
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleExport}
                            className="btn-primary"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Exporting...' : 'Export to Jira'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}; 