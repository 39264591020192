import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Typography,
  Divider,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import axiosInstance from "../../config/axios";
import { useAuth } from "../../contexts/AuthContext";

interface User {
  id: string;
  name: string;
  email: string;
  company: string;
  status: "active" | "inactive" | "suspended";
  role: "super_admin" | "admin" | "user";
  companyRole?: string;
}

interface Company {
  name: string;
  totalCredits: number;
  creditsUsed: number;
  id: string;
}

const UserManagement: React.FC = () => {
  const { user: currentUser } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    company: "",
    subscriptionEnd: "",
  });
  const [creditDialog, setCreditDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [creditAmount, setCreditAmount] = useState<number>(100);

  useEffect(() => {
    fetchUsers();
    if (isSuperAdmin) {
      fetchCompanies();
    }
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get("/admin/users");
      setUsers(response.data);
    } catch (err) {
      setError("Failed to fetch users");
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axiosInstance.get("/admin/companies");
      setCompanies(response.data);
    } catch (err) {
      setError("Failed to fetch companies");
    }
  };

  const handleCreateUser = async () => {
    try {
      await axiosInstance.post("/admin/users", newUser);
      setOpen(false);
      await fetchUsers();
      if (isSuperAdmin) {
        await fetchCompanies();
      }
      setNewUser({
        name: "",
        email: "",
        password: "",
        company: "",
        subscriptionEnd: "",
      });
    } catch (err) {
      setOpen(false);
    }
  };

  const handleUpdateUserStatus = async (
    userId: string,
    status: "active" | "inactive"
  ) => {
    try {
      await axiosInstance.put(`/admin/users/${userId}`, { status });
      fetchUsers();
    } catch (err) {
      setError("Failed to update user status");
    }
  };

  const handleCreditDialogOpen = (company: Company) => {
    setSelectedCompany(company);
    setCreditDialog(true);
    setCreditAmount(100);
  };

  const handleCreditDialogClose = () => {
    setCreditDialog(false);
    setSelectedCompany(null);
  };

  const handleUpdateCompanyCredits = async () => {
    if (!selectedCompany) return;

    try {
      await axiosInstance.put(`/admin/companies/${selectedCompany.id}/credits`, {
        credits: selectedCompany.totalCredits + creditAmount,
        description: `Credit adjustment of ${creditAmount}`
      });
      await fetchCompanies();
      handleCreditDialogClose();
    } catch (err) {
      setError("Failed to update company credits");
    }
  };

  // Check if current user is super_admin
  const isSuperAdmin = currentUser?.role === "super_admin";

  return (
    <Box className="space-y-8">
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* User Management Section */}
      <Typography
        variant="h5"
        sx={{
          mb: 2,
          color: 'white',
          fontSize: '1.5rem',
          fontWeight: 600
        }}
      >
        User Management
      </Typography>

      {isSuperAdmin && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          sx={{
            mb: 2,
            background: 'linear-gradient(to right, #F97316, #EA580C)',
            '&:hover': {
              background: 'linear-gradient(to right, #EA580C, #F97316)',
            },
            boxShadow: '0 4px 6px -1px rgba(249, 115, 22, 0.1)',
            textTransform: 'none'
          }}
        >
          Create New User
        </Button>
      )}

      <TableContainer
        component={Paper}
        sx={{
          mb: 4,
          background: 'rgba(30, 41, 59, 0.1)',
          backdropFilter: 'blur(8px)',
          border: '1px solid rgba(249, 115, 22, 0.2)',
          borderRadius: '16px',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
          '& .MuiTable-root': {
            backgroundColor: 'transparent'
          }
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'rgba(249, 115, 22, 0.8)', fontWeight: 500 }}>Name</TableCell>
              <TableCell sx={{ color: 'rgba(249, 115, 22, 0.8)', fontWeight: 500 }}>Email</TableCell>
              <TableCell sx={{ color: 'rgba(249, 115, 22, 0.8)', fontWeight: 500 }}>Company</TableCell>
              <TableCell sx={{ color: 'rgba(249, 115, 22, 0.8)', fontWeight: 500 }}>Company Role</TableCell>
              <TableCell sx={{ color: 'rgba(249, 115, 22, 0.8)', fontWeight: 500 }}>Status</TableCell>
              <TableCell sx={{ color: 'rgba(249, 115, 22, 0.8)', fontWeight: 500 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                key={user.id}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(30, 41, 59, 0.2)'
                  }
                }}
              >
                <TableCell sx={{ color: 'white' }}>{user.name}</TableCell>
                <TableCell sx={{ color: 'white' }}>{user.email}</TableCell>
                <TableCell sx={{ color: 'white' }}>{user.company}</TableCell>
                <TableCell sx={{ color: 'white' }}>{user.companyRole}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color={user.status === "active" ? "success" : "error"}
                    onClick={() =>
                      handleUpdateUserStatus(
                        user.id,
                        user.status === "active" ? "inactive" : "active"
                      )
                    }
                    sx={{
                      borderRadius: '16px',
                      textTransform: 'none'
                    }}
                  >
                    {user.status}
                  </Button>
                </TableCell>
                <TableCell sx={{ color: 'white' }}>
                  {/* Additional user actions can go here */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Company Credits Section - Only visible to super admin */}
      {isSuperAdmin && (
        <>
          <Divider sx={{ my: 4, borderColor: 'rgba(249, 115, 22, 0.2)' }} />
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 600
            }}
          >
            Company Credits Management
          </Typography>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gap: 2
          }}>
            {companies.map((company) => (
              <Card
                key={company.id}
                sx={{
                  background: 'rgba(30, 41, 59, 0.1)',
                  backdropFilter: 'blur(8px)',
                  border: '1px solid rgba(249, 115, 22, 0.2)',
                  borderRadius: '16px',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                }}
              >
                <CardHeader
                  title={company.name}
                  sx={{
                    '& .MuiCardHeader-title': {
                      color: 'white',
                      fontSize: '1.25rem',
                      fontWeight: 500
                    }
                  }}
                />
                <CardContent>
                  <Typography sx={{ color: 'white', mb: 1 }}>
                    Available Credits: {company.totalCredits - company.creditsUsed}
                  </Typography>
                  <Typography sx={{ color: 'white', mb: 1 }}>
                    Total Credits: {company.totalCredits}
                  </Typography>
                  <Typography sx={{ color: 'white', mb: 2 }}>
                    Credits Used: {company.creditsUsed}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleCreditDialogOpen(company)}
                    sx={{
                      mt: 2,
                      background: 'linear-gradient(to right, #F97316, #EA580C)',
                      '&:hover': {
                        background: 'linear-gradient(to right, #EA580C, #F97316)',
                      },
                      textTransform: 'none'
                    }}
                  >
                    Manage Credits
                  </Button>
                </CardContent>
              </Card>
            ))}
          </Box>
        </>
      )}

      {/* Create User Dialog */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={newUser.name}
            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={newUser.password}
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Company"
            fullWidth
            value={newUser.company}
            onChange={(e) => setNewUser({ ...newUser, company: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Subscription End"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={newUser.subscriptionEnd}
            onChange={(e) =>
              setNewUser({ ...newUser, subscriptionEnd: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleCreateUser} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Company Credit Management Dialog */}
      <Dialog open={creditDialog} onClose={handleCreditDialogClose}>
        <DialogTitle>Manage Credits for {selectedCompany?.name}</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              type="number"
              label="Credits to Add/Remove"
              value={creditAmount}
              onChange={(e) => setCreditAmount(Number(e.target.value))}
              fullWidth
            />
            {selectedCompany && (
              <Typography sx={{ mt: 2 }}>
                Current Available Credits: {selectedCompany.totalCredits - selectedCompany.creditsUsed}<br />
                Total Credits: {selectedCompany.totalCredits}<br />
                Credits Used: {selectedCompany.creditsUsed}<br />
                New Balance will be: {selectedCompany.totalCredits + creditAmount} credits
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreditDialogClose}>Cancel</Button>
          <Button onClick={handleUpdateCompanyCredits} color="primary">
            Update Credits
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserManagement;
