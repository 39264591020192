import React, { useState, useEffect } from 'react';
import { jiraService } from '../services/jiraService';
import { useAuth } from '../contexts/AuthContext';
import axiosInstance from '../services/axiosInstance';
import { FiRefreshCw, FiCheck, FiAlertCircle, FiLock } from 'react-icons/fi';
import logo from './veloai-logo.png';
import { useNavigate } from 'react-router-dom';

interface JiraConfigData {
    baseUrl: string;
    email: string;
    apiToken: string;
    typeMapping?: { [key: string]: string };
}

interface JiraIssueType {
    id: string;
    name: string;
    description?: string;
}

const ARTIFACT_TYPES = ['Requirements', 'Test Cases', 'Test Scenarios', 'Automation Code'] as const;
type ArtifactType = typeof ARTIFACT_TYPES[number];

const JiraConfiguration: React.FC = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [config, setConfig] = useState<JiraConfigData>({
        baseUrl: '',
        email: '',
        apiToken: '',
        typeMapping: {}
    });
    const [issueTypes, setIssueTypes] = useState<JiraIssueType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [testStatus, setTestStatus] = useState<'none' | 'success' | 'error'>('none');
    const [isSaving, setIsSaving] = useState(false);
    const [saveStatus, setSaveStatus] = useState<'none' | 'success' | 'error'>('none');
    const [isLoadingTypes, setIsLoadingTypes] = useState(false);

    const isAdmin = user?.role === 'admin' || user?.role === 'super_admin';

    useEffect(() => {
        // Redirect non-admin users
        if (user && !isAdmin) {
            navigate('/');
            return;
        }

        // Load saved configuration if it exists
        const loadConfig = async () => {
            try {
                const response = await axiosInstance.get('/api/jira/config');
                if (response.data) {
                    setConfig(response.data);
                }
            } catch (error) {
                console.error('Failed to load Jira configuration:', error);
            }
        };

        if (user?.id) {
            loadConfig();
        }
    }, [user, isAdmin, navigate]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setConfig(prev => ({ ...prev, [name]: value }));
    };

    const handleTypeMappingChange = (issueType: string, artifactType: string) => {
        setConfig(prev => ({
            ...prev,
            typeMapping: {
                ...prev.typeMapping,
                [issueType]: artifactType
            }
        }));
    };

    const loadIssueTypes = async () => {
        setIsLoadingTypes(true);
        try {
            const response = await axiosInstance.get('/api/jira/issue-types');
            setIssueTypes(response.data);
        } catch (error) {
            console.error('Failed to load issue types:', error);
        } finally {
            setIsLoadingTypes(false);
        }
    };

    const testConnection = async () => {
        setIsLoading(true);
        try {
            const isConnected = await jiraService.testConnection(config);
            setTestStatus(isConnected ? 'success' : 'error');
            if (isConnected) {
                loadIssueTypes();
            }
        } catch (error) {
            setTestStatus('error');
            console.error('Connection test failed:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const saveConfiguration = async () => {
        setIsSaving(true);
        setSaveStatus('none');
        try {
            const success = await jiraService.saveConfig(config);
            setSaveStatus(success ? 'success' : 'error');
        } catch (error) {
            console.error('Failed to save configuration:', error);
            setSaveStatus('error');
        } finally {
            setIsSaving(false);
        }
    };

    if (!isAdmin) {
        return (
            <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black flex items-center justify-center">
                <div className="text-center">
                    <FiLock className="mx-auto h-12 w-12 text-velo-orange-500/80 mb-4" />
                    <h2 className="text-2xl font-bold text-white mb-2">Access Denied</h2>
                    <p className="text-gray-400">Only administrators can access this page.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                {/* Header Section */}
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center space-x-6">
                        <img src={logo} alt="VeloAI Logo" className="h-6 w-auto filter drop-shadow-glow" />
                        <div>
                            <div className="text-velo-orange-500/80 text-sm font-medium mb-1">
                                Jira Integration
                            </div>
                            <h1 className="text-4xl font-bold text-white tracking-tight">
                                Configuration
                            </h1>
                        </div>
                    </div>
                </div>

                {/* Connection Settings */}
                <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 overflow-hidden shadow-xl mb-6">
                    <div className="px-8 py-6">
                        <h3 className="text-lg font-medium text-velo-orange-500/80 mb-6">Connection Settings</h3>
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-300 mb-2">Jira Base URL</label>
                                <input
                                    type="url"
                                    name="baseUrl"
                                    value={config.baseUrl}
                                    onChange={handleInputChange}
                                    placeholder="https://your-domain.atlassian.net"
                                    className="w-full p-3 bg-[#0B1221] rounded-lg text-white border border-velo-orange-500/20 focus:border-velo-orange-500 focus:ring-1 focus:ring-velo-orange-500 placeholder-gray-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-300 mb-2">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={config.email}
                                    onChange={handleInputChange}
                                    className="w-full p-3 bg-[#0B1221] rounded-lg text-white border border-velo-orange-500/20 focus:border-velo-orange-500 focus:ring-1 focus:ring-velo-orange-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-300 mb-2">API Token</label>
                                <input
                                    type="password"
                                    name="apiToken"
                                    value={config.apiToken}
                                    onChange={handleInputChange}
                                    className="w-full p-3 bg-[#0B1221] rounded-lg text-white border border-velo-orange-500/20 focus:border-velo-orange-500 focus:ring-1 focus:ring-velo-orange-500"
                                />
                                <p className="text-sm text-gray-400 mt-2">
                                    Generate an API token from your
                                    <a
                                        href="https://id.atlassian.com/manage-profile/security/api-tokens"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-velo-orange-400 hover:text-velo-orange-300 ml-1"
                                    >
                                        Atlassian Account Settings
                                    </a>
                                </p>
                            </div>

                            <div className="flex space-x-4 pt-4">
                                <button
                                    onClick={testConnection}
                                    disabled={isLoading}
                                    className="flex items-center px-4 py-2 rounded-lg bg-[#1E293B] text-white hover:bg-[#2D3B4E] disabled:opacity-50 transition-colors"
                                >
                                    {isLoading ? <FiRefreshCw className="animate-spin mr-2" /> : null}
                                    {isLoading ? 'Testing...' : 'Test Connection'}
                                </button>
                                <button
                                    onClick={saveConfiguration}
                                    disabled={isSaving}
                                    className="flex items-center px-4 py-2 rounded-lg bg-[#F97316] hover:bg-[#EA580C] text-white disabled:opacity-50 transition-colors"
                                >
                                    {isSaving ? <FiRefreshCw className="animate-spin mr-2" /> : null}
                                    {isSaving ? 'Saving...' : 'Save Configuration'}
                                </button>
                            </div>

                            {testStatus !== 'none' && (
                                <div className={`mt-4 p-3 rounded-lg flex items-center ${testStatus === 'success'
                                    ? 'bg-green-500/10 text-green-400'
                                    : 'bg-red-500/10 text-red-400'
                                    }`}>
                                    {testStatus === 'success' ? (
                                        <FiCheck className="mr-2" />
                                    ) : (
                                        <FiAlertCircle className="mr-2" />
                                    )}
                                    {testStatus === 'success'
                                        ? 'Connection successful!'
                                        : 'Connection failed. Please check your credentials and try again.'}
                                </div>
                            )}

                            {saveStatus !== 'none' && (
                                <div className={`mt-4 p-3 rounded-lg flex items-center ${saveStatus === 'success'
                                    ? 'bg-green-500/10 text-green-400'
                                    : 'bg-red-500/10 text-red-400'
                                    }`}>
                                    {saveStatus === 'success' ? (
                                        <FiCheck className="mr-2" />
                                    ) : (
                                        <FiAlertCircle className="mr-2" />
                                    )}
                                    {saveStatus === 'success'
                                        ? 'Configuration saved successfully!'
                                        : 'Failed to save configuration. Please try again.'}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Type Mapping */}
                <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 overflow-hidden shadow-xl">
                    <div className="px-8 py-6">
                        <div className="flex justify-between items-center mb-6">
                            <h3 className="text-lg font-medium text-velo-orange-500/80">Issue Type Mapping</h3>
                            <button
                                onClick={loadIssueTypes}
                                disabled={isLoadingTypes}
                                className="flex items-center px-3 py-1.5 rounded-lg bg-[#1E293B] text-white hover:bg-[#2D3B4E] disabled:opacity-50 transition-colors text-sm"
                            >
                                {isLoadingTypes ? <FiRefreshCw className="animate-spin mr-2" /> : null}
                                Refresh Types
                            </button>
                        </div>

                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-velo-blue-500/20">
                                <thead>
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                                            Jira Issue Type
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                                            Maps To
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-velo-blue-500/20">
                                    {issueTypes.map((type) => (
                                        <tr key={type.id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                                {type.name}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <select
                                                    value={config.typeMapping?.[type.name] || ''}
                                                    onChange={(e) => handleTypeMappingChange(type.name, e.target.value)}
                                                    className="bg-[#0B1221] text-white border border-velo-orange-500/20 rounded-lg px-3 py-2 focus:border-velo-orange-500 focus:ring-1 focus:ring-velo-orange-500"
                                                >
                                                    <option value="">Select Type</option>
                                                    {ARTIFACT_TYPES.map((artifactType) => (
                                                        <option key={artifactType} value={artifactType}>
                                                            {artifactType}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JiraConfiguration; 