import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import LandingPage from "./components/LandingPage";
import RequirementsValidator from "./components/requirementsValidator";
import TestScenarioDesign from "./components/TestScenarioDesign";
import TestCaseCreation from "./components/TestCaseCreation";
import AutomationDevelopment from "./components/AutomationDevelopment";
import CoverageOptimisation from "./components/CoverageOptimisation";
import UnitTestGenerator from "./components/UnitTestGenerator";
import UnitTestCoverageAnalyzer from "./components/UnitTestCoverageAnalyzer";
import IntegratedTestingFlow from "./components/IntegratedTestingFlow";
import GlobalContext from "./components/shared/GlobalContext";
import { GlobalContextProvider } from "./components/shared/GlobalContext";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminRoute from "./components/AdminRoute";
import Login from "./pages/Login";
import AdminDashboard from "./pages/admin/Dashboard";
import CreditMetricsPage from "./pages/metrics/CreditMetricsPage";
import SetInitialPassword from "./pages/SetInitialPassword";
import MetricsDashboard from './pages/metrics/MetricsDashboard';
import JiraConfiguration from './components/JiraConfiguration';
import JiraProjectExplorer from './components/JiraProjectExplorer';
import { JiraExportManager } from './components/JiraExportManager';
import CsvExportPage from './pages/CsvExportPage';

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If it's the user's first login and they're not already on the set-password page
  if (user?.isFirstLogin && location.pathname !== '/set-password') {
    return <Navigate to="/set-password" replace />;
  }

  return <>{children}</>;
};

function App() {
  return (
    <AuthProvider>
      <GlobalContextProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/set-password" element={
              <PrivateRoute>
                <SetInitialPassword />
              </PrivateRoute>
            } />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Layout />
                </PrivateRoute>
              }
            >
              <Route index element={<LandingPage />} />
              <Route path="global-context" element={<GlobalContext />} />
              <Route
                path="requirements-validator"
                element={<RequirementsValidator />}
              />
              <Route
                path="test-scenario-design"
                element={<TestScenarioDesign />}
              />
              <Route path="test-case-creation" element={<TestCaseCreation />} />
              <Route
                path="automation-development"
                element={<AutomationDevelopment />}
              />
              <Route
                path="coverage-optimisation"
                element={<CoverageOptimisation />}
              />
              <Route
                path="unit-test-generator"
                element={<UnitTestGenerator />}
              />
              <Route
                path="unit-test-coverage-analyzer"
                element={<UnitTestCoverageAnalyzer />}
              />
              <Route
                path="integrated-testing-flow"
                element={<IntegratedTestingFlow />}
              />
              <Route path="admin" element={
                <AdminRoute>
                  <AdminDashboard />
                </AdminRoute>
              } />
              <Route
                path="/metrics"
                element={
                  <AdminRoute>
                    <MetricsDashboard />
                  </AdminRoute>
                }
              />
              <Route path="jira-config" element={
                <AdminRoute>
                  <JiraConfiguration />
                </AdminRoute>
              } />
              <Route path="jira-projects" element={<JiraProjectExplorer />} />
              <Route path="jira-export" element={<JiraExportManager />} />
              <Route path="csv-export" element={<CsvExportPage />} />
            </Route>
          </Routes>
        </Router>
      </GlobalContextProvider>
    </AuthProvider>
  );
}

export default App;
