import { AxiosInstance } from 'axios';
import axiosInstance from './axiosInstance';
import { ConsolidatedContent } from './fileSystemService';

export interface JiraConfig {
    baseUrl: string;
    email: string;
    apiToken: string;
    typeMapping?: { [key: string]: string };
}

export interface JiraProject {
    id: string;
    key: string;
    name: string;
}

export interface Issue {
    id: string;
    key: string;
    summary: string;
    description?: string;
    issueType: string;
    artifactType?: string;
}

export interface JiraIssue {
    id: string;
    key: string;
    fields: {
        summary: string;
        description?: string;
        issuetype: {
            id: string;
            name: string;
        };
        artifactType?: string;
    };
}

export interface JiraIssueType {
    id: string;
    name: string;
    description?: string;
}

interface JiraFields {
    summary: string;
    description: string;
    issuetype: {
        name: string;
    };
    project: {
        key: string;
    };
    parent?: {
        key: string;
    };
}

export class JiraService {
    private static instance: JiraService;
    private axiosInstance: AxiosInstance;
    private config: JiraConfig | null = null;

    private constructor() {
        this.axiosInstance = axiosInstance;
    }

    public static getInstance(): JiraService {
        if (!JiraService.instance) {
            JiraService.instance = new JiraService();
        }
        return JiraService.instance;
    }

    public async getProjects(): Promise<JiraProject[]> {
        try {
            const response = await this.axiosInstance.get('/api/jira/projects');
            return response.data;
        } catch (error) {
            console.error('Error fetching Jira projects:', error);
            throw error;
        }
    }

    public async loadConfig(): Promise<JiraConfig | null> {
        try {
            const response = await this.axiosInstance.get('/api/jira/config');
            this.config = response.data;
            return response.data;
        } catch (error) {
            console.error('Failed to load Jira configuration:', error);
            return null;
        }
    }

    public async saveConfig(config: JiraConfig): Promise<boolean> {
        try {
            await this.axiosInstance.post('/api/jira/config', config);
            this.config = config;
            return true;
        } catch (error) {
            console.error('Failed to save Jira configuration:', error);
            return false;
        }
    }

    public async testConnection(config: JiraConfig): Promise<boolean> {
        try {
            const response = await this.axiosInstance.post('/api/jira/test-connection', {
                baseUrl: config.baseUrl,
                email: config.email,
                apiToken: config.apiToken
            });

            if (response.data.success) {
                await this.saveConfig(config);
            }

            return response.data.success;
        } catch (error) {
            console.error('Jira connection test failed:', error);
            return false;
        }
    }

    public async getIssueTypes(): Promise<JiraIssueType[]> {
        try {
            if (!this.config) {
                const config = await this.loadConfig();
                if (!config) {
                    throw new Error('Jira configuration not found');
                }
            }

            const response = await this.axiosInstance.get('/api/jira/issue-types');
            return response.data;
        } catch (error) {
            console.error('Failed to fetch Jira issue types:', error);
            throw error;
        }
    }

    public async getProjectIssues(projectKey: string): Promise<Issue[]> {
        try {
            if (!this.config) {
                const config = await this.loadConfig();
                if (!config) {
                    throw new Error('Jira configuration not found');
                }
            }

            const response = await this.axiosInstance.get(`/api/jira/proxy/search`, {
                params: {
                    jql: `project=${projectKey}`,
                    fields: 'summary,description,issuetype'
                }
            });

            return response.data.issues.map((issue: any) => ({
                id: issue.id,
                key: issue.key,
                summary: issue.fields.summary,
                description: issue.fields.description,
                issueType: issue.fields.issuetype.name,
                artifactType: this.config?.typeMapping?.[issue.fields.issuetype.name]
            }));
        } catch (error) {
            console.error('Failed to fetch project issues:', error);
            throw error;
        }
    }

    public async getRequirementsForProject(projectKey: string): Promise<Array<{ key: string; summary: string }>> {
        try {
            if (!this.config) {
                const config = await this.loadConfig();
                if (!config) {
                    throw new Error('Jira configuration not found');
                }
            }

            const response = await this.axiosInstance.get(`/api/jira/proxy/search`, {
                params: {
                    jql: `project=${projectKey} AND issuetype = Story`,
                    fields: 'summary'
                }
            });

            return response.data.issues.map((issue: any) => ({
                key: issue.key,
                summary: issue.fields.summary
            }));
        } catch (error) {
            console.error('Failed to fetch project requirements:', error);
            throw error;
        }
    }

    public async exportToJira(
        items: Array<{
            id: string;
            name: string;
            type: string;
            content?: string;
            consolidatedContent?: ConsolidatedContent;
            parentKey?: string;
        }>,
        options?: {
            mode: 'new' | 'existing';
            targetRequirement?: string;
            projectKey: string;
        }
    ): Promise<{ [key: string]: { status: string; key?: string; error?: string } }> {
        try {
            if (!this.config) {
                const config = await this.loadConfig();
                if (!config) {
                    throw new Error('Jira configuration not found');
                }
            }

            const getJiraIssueType = (internalType: string): string => {
                if (internalType === 'user-story' || internalType.startsWith('REQ-')) {
                    return 'Story';
                } else if (internalType === 'subtask-description' || internalType.startsWith('TS-')) {
                    return 'Subtask';
                } else if (internalType === 'bug-description' || internalType.startsWith('TC-')) {
                    return 'Bug';
                }
                return 'Story';
            };

            // If we're updating an existing requirement
            if (options?.mode === 'existing' && options.targetRequirement) {
                // First fetch the existing issue
                const existingIssue = await this.axiosInstance.get(`/api/jira/proxy/issue/${options.targetRequirement}`, {
                    params: {
                        fields: 'description'
                    }
                });

                // For each item being exported
                const requestData = items.map(item => {
                    const existingDesc = existingIssue.data.fields.description;
                    let descriptionContent: Array<{
                        type: string;
                        content: Array<{
                            type: string;
                            text: string;
                        }>;
                    }> = [];

                    // If there's existing content, use it directly
                    if (typeof existingDesc === 'object' && existingDesc?.content) {
                        descriptionContent = [
                            ...existingDesc.content,
                            {
                                type: 'paragraph',
                                content: [{
                                    type: 'text',
                                    text: '\n---\n'
                                }]
                            }
                        ];
                    }

                    // Split new content by actual line breaks and create paragraph nodes
                    const newContent = (item.consolidatedContent?.exportDescription || item.content || '')
                        .split('\n')
                        .filter(line => line.trim().length > 0)
                        .map(line => ({
                            type: 'paragraph',
                            content: [{
                                type: 'text',
                                text: line
                            }]
                        }));

                    return {
                        id: item.id,
                        key: options.targetRequirement,
                        fields: {
                            description: {
                                type: 'doc',
                                version: 1,
                                content: [
                                    ...descriptionContent,
                                    ...newContent
                                ]
                            }
                        }
                    };
                });

                const response = await this.axiosInstance.post('/api/jira/update', requestData);
                return response.data;
            }

            // Otherwise, create new issues (existing functionality)
            const requestData = items.map(item => ({
                id: item.id,
                fields: {
                    project: {
                        key: options?.projectKey
                    },
                    summary: item.name,
                    description: item.consolidatedContent?.exportDescription || item.content || '',
                    issuetype: {
                        name: getJiraIssueType(item.type)
                    },
                    ...(item.parentKey && getJiraIssueType(item.type) === 'Subtask' ? {
                        parent: {
                            key: item.parentKey
                        }
                    } : {})
                }
            }));

            const response = await this.axiosInstance.post('/api/jira/export', requestData);
            return response.data;
        } catch (error: any) {
            console.error('Failed to export items to Jira:', error);
            throw error;
        }
    }

    public async getParentStories(projectKey: string): Promise<Issue[]> {
        try {
            if (!this.config) {
                const config = await this.loadConfig();
                if (!config) {
                    throw new Error('Jira configuration not found');
                }
            }

            const response = await this.axiosInstance.get(`/api/jira/proxy/search`, {
                params: {
                    jql: `project=${projectKey} AND issuetype = Story`,
                    fields: 'summary,description,issuetype'
                }
            });

            return response.data.issues.map((issue: any) => ({
                id: issue.id,
                key: issue.key,
                summary: issue.fields.summary,
                description: issue.fields.description,
                issueType: issue.fields.issuetype.name
            }));
        } catch (error) {
            console.error('Failed to fetch parent stories:', error);
            throw error;
        }
    }

    public async testSubtaskExport(projectKey: string, parentKey: string): Promise<any> {
        try {
            const response = await this.axiosInstance.post('/api/jira/test-subtask-export', {
                projectKey,
                parentKey
            });
            return response.data;
        } catch (error: any) {
            console.error('Failed to test subtask export:', error);
            throw error;
        }
    }

    public async testUpdateFormats(issueKey: string, data: any): Promise<any> {
        try {
            if (!this.config) {
                const config = await this.loadConfig();
                if (!config) {
                    throw new Error('Jira configuration not found');
                }
            }

            const response = await this.axiosInstance.put(`/api/jira/proxy/issue/${issueKey}`, {
                fields: data
            });
            return response.data;
        } catch (error: any) {
            console.error('Failed to test update format:', error);
            throw error;
        }
    }
}

export const jiraService = JiraService.getInstance(); 