import { ComponentName } from "../services/axiosInstance";
import axiosInstance from "../services/axiosInstance";

export const withCredits = async <T>(
  componentName: ComponentName,
  action: () => Promise<T>
): Promise<T> => {
  try {
    console.log(`Starting credit deduction for ${componentName}`);

    // Deduct credits and get updated credit information
    const deductResponse = await axiosInstance.post("/api/components/deduct-credits", {
      component: componentName,
    });
    console.log('Credit deduction response:', deductResponse.data);

    // If we have updated credit information, dispatch it
    if (deductResponse.data.credits) {
      const updateEvent = new CustomEvent('creditUpdate', {
        detail: {
          Company: {
            totalCredits: deductResponse.data.credits.total,
            creditsUsed: deductResponse.data.credits.used,
            name: deductResponse.data.credits.companyName
          }
        }
      });
      window.dispatchEvent(updateEvent);
      console.log('Credit update event dispatched with:', deductResponse.data.credits);
    }

    // If deduction successful, proceed with the action
    return await action();
  } catch (error: any) {
    if (error.response?.status === 403) {
      throw new Error(error.response.data.message || "Insufficient credits");
    }
    throw error;
  }
};
