import axios from "axios";

// Define component types for better type safety
export type ComponentName =
  | "requirements-validator"
  | "test-scenario-design"
  | "test-case-creation"
  | "automation-development"
  | "coverage-optimisation"
  | "unit-test-generator"
  | "unit-test-coverage-analyzer"
  | "integrated-testing-flow";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://www.velo-ai.tech",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add request logging interceptor
axiosInstance.interceptors.request.use((config) => {
  console.log(
    `Making request to: ${config.method?.toUpperCase()} ${config.url}`
  );
  console.log("Request Headers:", config.headers);

  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Add response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      console.error("Credit deduction failed:", error.response.data);
    }
    return Promise.reject(error);
  }
);

// Update the deductCreditsForComponent function with better error handling
export const deductCreditsForComponent = async (
  componentName: ComponentName
) => {
  try {
    console.log(`Attempting to deduct credits for: ${componentName}`);
    const response = await axiosInstance.post(
      "/api/components/deduct-credits",
      {
        component: componentName,
      }
    );
    console.log("Credit deduction response:", response.data);
    return true;
  } catch (error: any) {
    console.error(
      "Credit deduction error:",
      error.response?.data || error.message
    );
    if (error.response?.status === 403) {
      throw new Error(error.response.data.message || "Insufficient credits");
    }
    throw error;
  }
};

// Export the instance as default
export default axiosInstance;
