import React, { useState, useContext } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { generateTestCases } from "../services/aiService";
import logo from "./veloai-logo.png";
import { TextArea } from "./ui/FormElements";
import { GlobalContext } from "./shared/GlobalContext";
import { TreeNode } from "../services/fileSystemService";
import { withCredits } from "../utils/withCredits";

interface OutletContextType {
  fileSystem: any;
  selectedFile: TreeNode | null;
  refreshCounter: number;
  selectedNodes: TreeNode[];
}

interface TestCase {
  TestCaseID: string;
  TestScenarioID: string;
  TestCaseDescription: string;
  Preconditions: string;
  TestSteps: string[];
  TestData: string;
  ExpectedResult: string;
  ActualResult: string;
  Status: string;
  Comments: string;
}

const defaultTestCase: TestCase = {
  TestCaseID: "N/A",
  TestScenarioID: "N/A",
  TestCaseDescription: "N/A",
  Preconditions: "N/A",
  TestSteps: ["N/A"],
  TestData: "N/A",
  ExpectedResult: "N/A",
  ActualResult: "N/A",
  Status: "N/A",
  Comments: "N/A",
};

function TestCaseCreation() {
  const { contextData } = useContext(GlobalContext);
  const { fileSystem } = useOutletContext<OutletContextType>();
  const [scenarios, setScenarios] = useState("");
  const [testCases, setTestCases] = useState<TestCase[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [savedFileName, setSavedFileName] = useState("");
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const saveToFileSystem = async (testCases: TestCase[]) => {
    try {
      // Get the first test case ID to use in the folder name
      const firstTestCase = testCases[0];
      const shortDesc = firstTestCase.TestCaseDescription
        .split('\n')[0]
        .substring(0, 50)
        .replace(/[^a-zA-Z0-9\s-]/g, '');

      const folderName = `${firstTestCase.TestCaseID} - ${shortDesc}`;

      // Create main folder for this test case set with full content
      await fileSystem.createFolder(["Test Cases", folderName], {
        type: 'test-case-root',
        displayName: folderName,
        fullDescription: firstTestCase.TestCaseDescription
      });

      // Create individual test case files
      for (let index = 0; index < testCases.length; index++) {
        const testCase = testCases[index];
        const testCasePath = ['Test Cases', folderName, `TC-${index + 1}`];
        await fileSystem.createFolder(testCasePath, {

          // type: 'bug-description',
          // displayName: testCaseFolderName,
          // fullDescription: testCase.TestCaseDescription,
          // content: {
          //   type: 'bug-description',
          //   description: testCase.TestCaseDescription
          // }

          type: 'test-case',
          content: {
            id: testCase.TestCaseID,
            testScenarioId: testCase.TestScenarioID,
            description: testCase.TestCaseDescription,
            preconditions: testCase.Preconditions,
            steps: testCase.TestSteps,
            testData: testCase.TestData,
            expectedResults: testCase.ExpectedResult,
            actualResults: testCase.ActualResult,
            status: testCase.Status,
            comments: testCase.Comments
          },
          displayName: testCase.TestCaseDescription,
          fullDescription: testCase.TestCaseDescription
        });

        // Create Description
        await fileSystem.createFolder([...testCasePath, "Description"], {
          type: 'description-folder',
          displayName: "Description",
          fullDescription: testCase.TestCaseDescription
        });

        // Create Preconditions
        await fileSystem.createFolder([...testCasePath, "Preconditions"], {
          type: 'preconditions-folder',
          displayName: "Preconditions",
          fullDescription: testCase.Preconditions
        });

        // Create Steps
        await fileSystem.createFolder([...testCasePath, "Steps"], {
          type: 'steps-folder',
          displayName: "Steps",
          fullDescription: testCase.TestSteps.join('\n')
        });

        // Create Test Data
        await fileSystem.createFolder([...testCasePath, "Test Data"], {
          type: 'test-data-folder',
          displayName: "Test Data",
          fullDescription: testCase.TestData
        });

        // Create Expected Results
        await fileSystem.createFolder([...testCasePath, "Expected Results"], {
          type: 'expected-results-folder',
          displayName: "Expected Results",
          fullDescription: testCase.ExpectedResult
        });

        // Create Actual Results
        await fileSystem.createFolder([...testCasePath, "Actual Results"], {
          type: 'actual-results-folder',
          displayName: "Actual Results",
          fullDescription: testCase.ActualResult
        });

        // Create Status
        await fileSystem.createFolder([...testCasePath, "Status"], {
          type: 'status-folder',
          displayName: "Status",
          fullDescription: testCase.Status
        });

        // Create Comments
        await fileSystem.createFolder([...testCasePath, "Comments"], {
          type: 'comments-folder',
          displayName: "Comments",
          fullDescription: testCase.Comments
        });
      }

      setSavedFileName(folderName);
      setRefreshTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Error saving to file system:", error);
      setError("Error saving test cases");
    }
  };

  const handleGenerateTestCases = async () => {
    if (!scenarios.trim()) {
      setError("Please provide test scenarios");
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const contextString = `Business Context:
${contextData.businessContext}

Technical Context:
${contextData.technicalContext}

Constraints:
${contextData.constraints}

Assumptions:
${contextData.assumptions}

Diagrams:
${Object.entries(contextData.diagrams)
  .map(([fileName, content]) => `${fileName}: ${content}`)
  .join("\n")}

Documents:
${Object.entries(contextData.documents)
  .map(([fileName, content]) => `${fileName}: ${content}`)
  .join("\n")}`;

      const result = await withCredits("test-case-creation", async () =>
        generateTestCases(scenarios, contextString)
      );

      const parsedResult =
        typeof result === "string" ? JSON.parse(result) : result;
      setTestCases(parsedResult.TestCases);
      await saveToFileSystem(parsedResult.TestCases);
    } catch (error: any) {
      console.error("Error generating test cases:", error);
      setTestCases([defaultTestCase]);
      setError(
        error.message ||
          "An error occurred while generating test cases. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <img
              src={logo}
              alt="VeloAI Logo"
              className="h-6 w-auto filter drop-shadow-glow"
            />
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">
                Testing Tools
              </div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Test Case Creation
              </h1>
            </div>
          </div>
          <Link
            to="/"
            className="group flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
          >
            <span>Return to Dashboard</span>
            <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
              →
            </span>
          </Link>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 gap-8">
          {/* Scenarios Input */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <TextArea
                label="Test Scenarios"
                value={scenarios}
                onChange={(e) => setScenarios(e.target.value)}
                placeholder="Enter your test scenarios here..."
                rows={4}
              />
            </div>
          </div>

          {/* Generate Button */}
          <div className="flex justify-center">
            <button
              onClick={handleGenerateTestCases}
              disabled={isLoading || !scenarios.trim()}
              className={`group relative px-8 py-4 rounded-xl font-medium text-base
                ${
                  isLoading
                    ? "bg-velo-blue-900/50 cursor-not-allowed"
                    : "bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500"
                }
                text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
                transform hover:-translate-y-0.5
              `}
            >
              <span className="flex items-center space-x-2">
                {isLoading ? (
                  <>
                    <span className="opacity-70">Generating Test Cases</span>
                    <span className="animate-pulse">...</span>
                  </>
                ) : (
                  <>
                    <span>Generate Test Cases</span>
                    <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                      →
                    </span>
                  </>
                )}
              </span>
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mt-6 backdrop-blur-sm bg-red-500/10 rounded-xl border border-red-500/20 p-4">
              <p className="text-red-400 text-center text-sm">{error}</p>
            </div>
          )}

          {/* Generated Test Cases */}
          {testCases.length > 0 && (
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <h3 className="text-lg font-medium text-velo-orange-500/80 mb-4">
                  Generated Test Cases
                </h3>
                <div className="space-y-6">
                  {testCases.map((testCase, index) => (
                    <div
                      key={index}
                      className="backdrop-blur-sm bg-velo-blue-900/20 rounded-xl border border-velo-blue-500/30 p-6"
                    >
                      <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">
                        Test Case {index + 1}
                      </h3>
                      <div className="space-y-3 text-gray-300">
                        <p>
                          <span className="font-bold text-velo-orange-500">
                            Test Case ID:
                          </span>{" "}
                          {testCase.TestCaseID}
                        </p>
                        <p>
                          <span className="font-bold text-velo-orange-500">
                            Test Scenario ID:
                          </span>{" "}
                          {testCase.TestScenarioID}
                        </p>
                        <p>
                          <span className="font-bold text-velo-orange-500">
                            Test Case Description:
                          </span>{" "}
                          {testCase.TestCaseDescription}
                        </p>
                        <p>
                          <span className="font-bold text-velo-orange-500">
                            Preconditions:
                          </span>{" "}
                          {testCase.Preconditions}
                        </p>
                        <div>
                          <span className="font-bold text-velo-orange-500">
                            Test Steps:
                          </span>
                          <ul className="list-disc pl-6 mt-2">
                            {testCase.TestSteps.map((step, stepIndex) => (
                              <li key={stepIndex}>
                                {typeof step === "object"
                                  ? JSON.stringify(step)
                                  : step}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <p>
                          <span className="font-bold text-velo-orange-500">
                            Test Data:
                          </span>{" "}
                          {typeof testCase.TestData === "object"
                            ? JSON.stringify(testCase.TestData)
                            : testCase.TestData}
                        </p>
                        <p>
                          <span className="font-bold text-velo-orange-500">
                            Expected Result:
                          </span>{" "}
                          {testCase.ExpectedResult}
                        </p>
                        <p>
                          <span className="font-bold text-velo-orange-500">
                            Actual Result:
                          </span>{" "}
                          {testCase.ActualResult}
                        </p>
                        <p>
                          <span className="font-bold text-velo-orange-500">
                            Status:
                          </span>{" "}
                          {testCase.Status}
                        </p>
                        <p>
                          <span className="font-bold text-velo-orange-500">
                            Comments:
                          </span>{" "}
                          {testCase.Comments}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TestCaseCreation;
