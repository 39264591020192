import React, { useEffect, useState } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    PieChart, Pie, Cell, ResponsiveContainer
} from 'recharts';
import { Card, Grid, Typography, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import axiosInstance from '../../services/axiosInstance';

interface MetricsData {
    companyMetrics: {
        totalCredits: number;
        creditsUsed: number;
        creditsRemaining: number;
        companyName?: string;
    };
    userMetrics: Array<{
        userId: string;
        userName: string;
        creditsUsed: number;
    }>;
    componentMetrics: Array<{
        componentName: string;
        creditsUsed: number;
    }>;
    dailyUsage: Array<{
        date: string;
        creditsUsed: number;
    }>;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658'];

const MetricsDashboard: React.FC = () => {
    const { user } = useAuth();
    const [metricsData, setMetricsData] = useState<MetricsData | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const fetchMetrics = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/api/admin/metrics');
            setMetricsData(response.data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch metrics data');
            console.error('Metrics fetch error:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMetrics();
        // Refresh every 5 minutes
        const interval = setInterval(fetchMetrics, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    if (loading) return <div>Loading metrics...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!metricsData) return <div>No metrics data available</div>;

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Credit Usage Metrics for {metricsData.companyMetrics.companyName}
            </Typography>

            {/* Overview Cards */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={2} sx={{ p: 2 }}>
                        <Typography color="textSecondary" gutterBottom>
                            Total Credits
                        </Typography>
                        <Typography variant="h4">
                            {metricsData.companyMetrics.totalCredits}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={2} sx={{ p: 2 }}>
                        <Typography color="textSecondary" gutterBottom>
                            Credits Used
                        </Typography>
                        <Typography variant="h4">
                            {metricsData.companyMetrics.creditsUsed}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={2} sx={{ p: 2 }}>
                        <Typography color="textSecondary" gutterBottom>
                            Credits Remaining
                        </Typography>
                        <Typography variant="h4">
                            {metricsData.companyMetrics.creditsRemaining}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={2} sx={{ p: 2 }}>
                        <Typography color="textSecondary" gutterBottom>
                            Active Users
                        </Typography>
                        <Typography variant="h4">
                            {metricsData.userMetrics.length}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            {/* Daily Usage Chart */}
            <Paper elevation={2} sx={{ p: 2, mb: 4 }}>
                <Typography variant="h6" gutterBottom>
                    Daily Credit Usage
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={metricsData.dailyUsage}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="creditsUsed"
                            stroke="#8884d8"
                            name="Credits Used"
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Paper>

            {/* User Credit Usage Table */}
            <Paper elevation={2} sx={{ p: 2, mb: 4 }}>
                <Typography variant="h6" gutterBottom>
                    User Credit Usage
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>User Name</TableCell>
                                <TableCell align="right">Credits Used</TableCell>
                                <TableCell align="right">Percentage of Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {metricsData.userMetrics
                                .sort((a, b) => b.creditsUsed - a.creditsUsed) // Sort by highest usage first
                                .map((user) => (
                                    <TableRow key={user.userId}>
                                        <TableCell>{user.userName}</TableCell>
                                        <TableCell align="right">{user.creditsUsed}</TableCell>
                                        <TableCell align="right">
                                            {metricsData.companyMetrics.creditsUsed === 0
                                                ? '0%'
                                                : `${((user.creditsUsed / metricsData.companyMetrics.creditsUsed) * 100).toFixed(1)}%`}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {/* Component Usage Chart */}
            <Paper elevation={2} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                    Component Usage Distribution
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                        <Pie
                            data={metricsData.componentMetrics}
                            dataKey="creditsUsed"
                            nameKey="componentName"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            label={(entry) => `${entry.componentName}: ${entry.creditsUsed}`}
                        >
                            {metricsData.componentMetrics.map((entry, index) => (
                                <Cell key={entry.componentName} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </Paper>
        </Box>
    );
};

export default MetricsDashboard; 