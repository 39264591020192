import React from 'react';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
}

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  error?: string;
  options: Array<{ value: string; label: string; }>;
}

export const TextArea = ({ label, error, className = '', ...props }: TextAreaProps) => (
  <div className="space-y-2">
    {label && (
      <label className="block text-lg font-medium text-velo-orange-500 mb-3">
        {label}
      </label>
    )}
    <textarea
      className={`
        w-full p-4 bg-black/40 rounded-lg text-white
        border border-velo-blue-600/50
        placeholder-gray-500
        focus:border-velo-orange-500 focus:ring-1 focus:ring-velo-orange-500
        hover:border-velo-blue-500/50
        transition-all duration-200
        ${className}
      `}
      {...props}
    />
    {error && (
      <p className="text-red-500 text-sm mt-1">{error}</p>
    )}
  </div>
);

export const Select = ({ label, error, options, className = '', ...props }: SelectProps) => (
  <div className="space-y-2">
    {label && (
      <label className="block text-lg font-medium text-velo-orange-500 mb-3">
        {label}
      </label>
    )}
    <select
      className={`
        w-full p-4 bg-black/40 rounded-lg text-white
        border border-velo-blue-600/50
        focus:border-velo-orange-500 focus:ring-1 focus:ring-velo-orange-500
        hover:border-velo-blue-500/50
        transition-all duration-200
        ${className}
      `}
      {...props}
    >
      {options.map((option) => (
        <option 
          key={option.value} 
          value={option.value}
          className="bg-velo-blue-900 text-white"
        >
          {option.label}
        </option>
      ))}
    </select>
    {error && (
      <p className="text-red-500 text-sm mt-1">{error}</p>
    )}
  </div>
);

export const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ className = '', children, disabled, ...props }) => (
  <button
    className={`bg-orange-500 text-white px-6 py-3 rounded-lg 
    hover:bg-orange-600 transition-colors font-bold
    disabled:opacity-50 disabled:cursor-not-allowed ${className}`}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
);

export default {
  TextArea,
  Select,
  Button
}; 