import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { TreeNode } from '../services/fileSystemService';
import { FileSystemService } from '../services/fileSystemService';

interface TestScenarioDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  artifact: TreeNode | null;
  onSave: (updatedArtifact: TreeNode) => void;
}

export default function TestScenarioDetailsDialog({
  open,
  onClose,
  artifact,
  onSave,
}: TestScenarioDetailsDialogProps) {
  const [editedDescription, setEditedDescription] = useState('');
  const [editedDetails, setEditedDetails] = useState('');
  const [editedRequirements, setEditedRequirements] = useState('');
  const fileSystem = FileSystemService.getInstance();

  // Get description and details from the nested structure
  useEffect(() => {
    if (artifact) {
      // Find description folder's content
      const descriptionFolder = artifact.children?.find(child => child.name === 'Description');
      const description = descriptionFolder?.content?.fullDescription || '';

      // Find test details folder's content
      const detailsFolder = artifact.children?.find(child => child.name === 'Test Details');
      const details = detailsFolder?.content?.fullDescription || '';

      // Find requirements folder's content
      const requirementsFolder = artifact.children?.find(child => child.name === 'Requirements');
      const requirements = requirementsFolder?.content?.fullDescription || '';

      setEditedDescription(description);
      setEditedDetails(details);
      setEditedRequirements(requirements);
    }
  }, [artifact]);

  const handleSave = () => {
    if (!artifact) return;
    
    // Update the node's content
    const updatedContent = {
      id: artifact.content.id,
      type: 'test-scenario',
      fullDescription: editedDescription
    };

    try {
      // Get the requirement ID from the scenario ID (e.g., "REQ-1-TS-001" -> "REQ-1")
      const reqId = artifact.content.id.split('-TS-')[0];
      const path = ['Test Scenarios', reqId, artifact.name];
      
      // Update the node in the file system
      fileSystem.updateNode(path, updatedContent);
      
      // Also update the nested structure
      const updatedArtifact: TreeNode = {
        ...artifact,
        content: updatedContent,
        children: [
          {
            id: 'description',
            name: 'Description',
            type: 'folder' as const,
            content: {
              type: 'description-content',
              displayName: editedDescription,
              fullDescription: editedDescription
            }
          },
          {
            id: 'details',
            name: 'Test Details',
            type: 'folder' as const,
            content: {
              type: 'test-details-content',
              displayName: 'Test Details',
              fullDescription: editedDetails
            }
          },
          {
            id: 'requirements',
            name: 'Requirements',
            type: 'folder' as const,
            content: {
              type: 'requirements-content',
              displayName: 'Requirements',
              fullDescription: editedRequirements
            }
          }
        ]
      };

      // Update all sections in the file system
      const descPath = [...path, "Description"];
      fileSystem.updateNode(descPath, {
        type: 'description-folder',
        content: {
          type: 'description-content',
          displayName: editedDescription,
          fullDescription: editedDescription
        }
      });

      const detailsPath = [...path, "Test Details"];
      fileSystem.updateNode(detailsPath, {
        type: 'test-details-folder',
        content: {
          type: 'test-details-content',
          displayName: 'Test Details',
          fullDescription: editedDetails
        }
      });

      const reqPath = [...path, "Requirements"];
      fileSystem.updateNode(reqPath, {
        type: 'requirements-folder',
        content: {
          type: 'requirements-content',
          displayName: 'Requirements',
          fullDescription: editedRequirements
        }
      });

      onSave(updatedArtifact);
      onClose();
    } catch (error) {
      console.error('Error updating test scenario:', error);
    }
  };

  if (!artifact) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#1a1b26',
          color: 'white',
          minWidth: '600px',
          maxHeight: '90vh'
        }
      }}
    >
      <DialogTitle 
        style={{ 
          wordBreak: 'break-word',
          paddingRight: '48px'
        }}
      >
        {artifact?.content?.id || 'Test Scenario Details'}
      </DialogTitle>
      <DialogContent>
        <div className="space-y-4">
          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Description
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }
              }}
            />
          </div>

          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Test Details
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={editedDetails}
              onChange={(e) => setEditedDetails(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }
              }}
            />
          </div>

          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Requirements
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={editedRequirements}
              onChange={(e) => setEditedRequirements(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '16px 24px' }}>
        <Button onClick={onClose} variant="outlined" style={{ color: '#e0e0e0' }}>
          CLOSE
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          SAVE CHANGES
        </Button>
      </DialogActions>
    </Dialog>
  );
}
