import React, { useState } from "react";
import { Link } from "react-router-dom";
import { analyzeCoverage } from "../services/aiService";
import logo from "./veloai-logo.png";
import { TextArea } from "./ui/FormElements";
import { withCredits } from "../utils/withCredits";

interface CoverageAnalysis {
  currentCoverage: number;
  suggestedScenarios: string[];
}

const defaultCoverageAnalysis: CoverageAnalysis = {
  currentCoverage: 0,
  suggestedScenarios: [],
};

function CoverageOptimisation() {
  const [requirements, setRequirements] = useState("");
  const [existingScenarios, setExistingScenarios] = useState("");
  const [coverageAnalysis, setCoverageAnalysis] = useState<CoverageAnalysis>(
    defaultCoverageAnalysis
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleAnalyzeCoverage = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await withCredits("coverage-optimisation", () =>
        analyzeCoverage(requirements, existingScenarios)
      );
      if (isValidCoverageAnalysis(result)) {
        setCoverageAnalysis(result);
      } else {
        setCoverageAnalysis(defaultCoverageAnalysis);
        setError(
          "Invalid coverage analysis generated. Please try again with different inputs."
        );
      }
    } catch (error) {
      console.error("Error analyzing coverage:", error);
      setCoverageAnalysis(defaultCoverageAnalysis);
      setError("An error occurred while analyzing coverage. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const isValidCoverageAnalysis = (
    analysis: any
  ): analysis is CoverageAnalysis => {
    return (
      typeof analysis === "object" &&
      typeof analysis.currentCoverage === "number" &&
      Array.isArray(analysis.suggestedScenarios)
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <img
              src={logo}
              alt="VeloAI Logo"
              className="h-6 w-auto filter drop-shadow-glow"
            />
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">
                Testing Tools
              </div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Coverage Optimisation
              </h1>
            </div>
          </div>
          <Link
            to="/"
            className="group flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
          >
            <span>Return to Dashboard</span>
            <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
              →
            </span>
          </Link>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 gap-8">
          {/* Input Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Requirements */}
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <TextArea
                  label="Test Coverage Data"
                  value={requirements}
                  onChange={(e) => setRequirements(e.target.value)}
                  placeholder="Enter requirements here..."
                  rows={4}
                />
              </div>
            </div>

            {/* Existing Scenarios */}
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <TextArea
                  label="Test Cases"
                  value={existingScenarios}
                  onChange={(e) => setExistingScenarios(e.target.value)}
                  placeholder="Enter existing test scenarios here..."
                  rows={4}
                />
              </div>
            </div>
          </div>

          {/* Generate Button */}
          <div className="flex justify-center mt-8">
            <button
              onClick={handleAnalyzeCoverage}
              disabled={
                isLoading || !requirements.trim() || !existingScenarios.trim()
              }
              className={`
                group relative px-8 py-4 rounded-xl font-medium text-base
                ${
                  isLoading
                    ? "bg-velo-blue-900/50 cursor-not-allowed"
                    : "bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500"
                }
                text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
                transform hover:-translate-y-0.5
              `}
            >
              <span className="flex items-center space-x-2">
                {isLoading ? (
                  <>
                    <span className="opacity-70">Analyzing Coverage</span>
                    <span className="animate-pulse">...</span>
                  </>
                ) : (
                  <>
                    <span>Analyze Coverage</span>
                    <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                      →
                    </span>
                  </>
                )}
              </span>
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mt-6 backdrop-blur-sm bg-red-500/10 rounded-xl border border-red-500/20 p-4">
              <p className="text-red-400 text-center text-sm">{error}</p>
            </div>
          )}

          {/* Coverage Analysis Results */}
          {coverageAnalysis.currentCoverage > 0 && (
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 overflow-hidden shadow-xl mt-8">
              <div className="px-8 py-6">
                <h3 className="text-lg font-medium text-velo-orange-500/80 mb-4">
                  Optimization Results
                </h3>
                <div className="space-y-6">
                  <div>
                    <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">
                      Suggested Additional Test Scenarios
                    </h3>
                    <ul className="space-y-3">
                      {coverageAnalysis.suggestedScenarios.map(
                        (scenario, index) => (
                          <li
                            key={index}
                            className="text-gray-300 pl-4 border-l-2 border-velo-blue-500/30"
                          >
                            {scenario}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CoverageOptimisation;
