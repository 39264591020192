import React, { useState, useEffect, useMemo } from 'react';
import { jiraService } from '../services/jiraService';
import { Link } from 'react-router-dom';
import { FiRefreshCw, FiChevronRight, FiSettings, FiCheck, FiAlertCircle } from 'react-icons/fi';
import axiosInstance from '../services/axiosInstance';
import { useFileSystem } from '../services/fileSystemService';
import { useAuth } from '../contexts/AuthContext';
import logo from './veloai-logo.png';

interface Project {
    id: string;
    key: string;
    name: string;
}

interface JiraServiceIssue {
    id: string;
    key: string;
    summary: string;
    description?: string;
    issueType: string;
    acceptanceCriteria?: string;
}

interface Issue {
    id: string;
    key: string;
    fields: {
        summary: string;
        description?: string;
        issuetype: {
            name: string;
        };
        customfield_10020?: string;
    };
}

interface ImportedIssue {
    jiraId: string;
    status: 'pending' | 'processed' | 'error';
    syncStatus: 'synced' | 'out_of_sync' | 'error';
}

const JiraProjectExplorer: React.FC = () => {
    const { user } = useAuth();
    const fileSystem = useFileSystem();
    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const [issues, setIssues] = useState<Issue[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [importedIssues, setImportedIssues] = useState<{ [key: string]: ImportedIssue }>({});
    const [importStatus, setImportStatus] = useState<{ [key: string]: 'importing' | 'success' | 'error' }>({});
    const [selectedIssues, setSelectedIssues] = useState<Set<string>>(new Set());
    const [filterType, setFilterType] = useState<string>('all');
    const [expandedIssue, setExpandedIssue] = useState<string | null>(null);
    const [config, setConfig] = useState<{ typeMapping?: { [key: string]: string } }>({});
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [issueTypes, setIssueTypes] = useState<Record<string, number>>({});

    const isAdmin = user?.role === 'admin' || user?.role === 'super_admin';

    useEffect(() => {
        // Load Jira configuration including type mappings
        const loadConfig = async () => {
            try {
                const config = await jiraService.loadConfig();
                if (config) {
                    setConfig(config);
                }
            } catch (error) {
                console.error('Failed to load Jira configuration:', error);
            }
        };
        loadConfig();
    }, []);

    const parseDescription = (description: any): string => {
        if (!description) return '';
        if (typeof description === 'string') return description;
        // Handle Atlassian Document Format
        if (description.content) {
            return description.content
                .map((item: any) => {
                    if (item.type === 'paragraph') {
                        return item.content
                            ?.map((content: any) => content.text || '')
                            .join('') + '\n';
                    }
                    return '';
                })
                .join('\n')
                .trim();
        }
        return JSON.stringify(description);
    };

    const loadProjects = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const projectList = await jiraService.getProjects();
            setProjects(projectList);
        } catch (err) {
            setError('Failed to load projects. Please check your Jira configuration.');
        } finally {
            setIsLoading(false);
        }
    };

    const loadProjectIssues = async (project: Project) => {
        try {
            setIsLoading(true);
            const response: JiraServiceIssue[] = await jiraService.getProjectIssues(project.id);
            const mappedIssues: Issue[] = response.map(issue => ({
                id: issue.id,
                key: issue.key,
                fields: {
                    summary: issue.summary,
                    description: issue.description,
                    issuetype: {
                        name: issue.issueType
                    },
                    customfield_10020: issue.acceptanceCriteria
                }
            }));
            setIssues(mappedIssues);
        } catch (error) {
            setError('Failed to load project issues');
        } finally {
            setIsLoading(false);
        }
    };

    const loadImportedIssues = async () => {
        try {
            const response = await axiosInstance.get('/api/jira/imported-issues');
            const importedMap: { [key: string]: ImportedIssue } = {};
            response.data.forEach((issue: ImportedIssue) => {
                importedMap[issue.jiraId] = issue;
            });
            setImportedIssues(importedMap);
        } catch (error) {
            console.error('Error loading imported issues:', error);
        }
    };

    useEffect(() => {
        loadProjects();
        loadImportedIssues();
    }, []);

    const handleImportIssue = async (issue: Issue) => {
        console.log('Importing issue:', {
            key: issue.key,
            type: issue.fields.issuetype.name,
            category: config.typeMapping?.[issue.fields.issuetype.name] || 'Requirements' // Default to Requirements if no mapping
        });

        setImportStatus(prev => ({ ...prev, [issue.id]: 'importing' }));
        try {
            const response = await axiosInstance.post('/api/jira/import-issue', {
                issueId: issue.id,
                issueKey: issue.key,
                projectKey: selectedProject?.key,
                summary: issue.fields.summary,
                description: parseDescription(issue.fields.description),
                issueType: issue.fields.issuetype.name,
                category: config.typeMapping?.[issue.fields.issuetype.name] || 'Requirements' // Default to Requirements if no mapping
            });

            // Add the issue to the file system
            fileSystem.addJiraIssue({
                key: issue.key,
                fields: {
                    summary: issue.fields.summary,
                    description: parseDescription(issue.fields.description),
                    customfield_10020: issue.fields.customfield_10020,
                    issuetype: {
                        name: issue.fields.issuetype.name
                    }
                }
            });

            setImportStatus(prev => ({ ...prev, [issue.id]: 'success' }));
            await loadImportedIssues();
        } catch (error) {
            console.error('Error importing issue:', error);
            setImportStatus(prev => ({ ...prev, [issue.id]: 'error' }));
        }
    };

    const handleBulkImport = async () => {
        const selectedIssuesList = issues.filter(issue => selectedIssues.has(issue.id));
        for (const issue of selectedIssuesList) {
            await handleImportIssue(issue);
        }
        setSelectedIssues(new Set());
    };

    const toggleIssueSelection = (issueId: string) => {
        const newSelection = new Set(selectedIssues);
        if (newSelection.has(issueId)) {
            newSelection.delete(issueId);
        } else {
            newSelection.add(issueId);
        }
        setSelectedIssues(newSelection);
    };

    const filteredIssues = useMemo(() => {
        if (!searchQuery.trim()) return issues;

        const query = searchQuery.toLowerCase();
        return issues.filter(issue =>
            issue.fields.summary.toLowerCase().includes(query) ||
            issue.key.toLowerCase().includes(query) ||
            (issue.fields.description ? parseDescription(issue.fields.description).toLowerCase().includes(query) : false)
        );
    }, [issues, searchQuery]);

    const issueTypeCounts = useMemo(() => {
        const counts: Record<string, number> = {};
        filteredIssues.forEach(issue => {
            const type = issue.fields.issuetype.name;
            counts[type] = (counts[type] || 0) + 1;
        });
        return counts;
    }, [filteredIssues]);

    const uniqueCategories = ['all', ...Array.from(
        new Set(issues.map(issue =>
            config.typeMapping?.[issue.fields.issuetype.name] || 'Requirements'
        ))
    )];

    const getImportButton = (issue: Issue) => {
        const imported = importedIssues[issue.id];
        const status = importStatus[issue.id];

        if (imported) {
            return (
                <div className="flex items-center text-green-400">
                    <FiCheck className="mr-1" />
                    <span className="text-sm">Imported</span>
                </div>
            );
        }

        if (status === 'importing') {
            return (
                <button
                    disabled
                    className="flex items-center px-3 py-1.5 rounded-md bg-neutral-600/50 text-white text-sm"
                >
                    <FiRefreshCw className="mr-2 animate-spin" />
                    Importing...
                </button>
            );
        }

        if (status === 'error') {
            return (
                <button
                    onClick={() => handleImportIssue(issue)}
                    className="flex items-center px-3 py-1.5 rounded-md bg-red-500/80 hover:bg-red-500 text-white text-sm transition-colors"
                >
                    <FiAlertCircle className="mr-2" />
                    Retry
                </button>
            );
        }

        return (
            <button
                onClick={() => handleImportIssue(issue)}
                className="flex items-center px-3 py-1.5 rounded-md bg-[#F97316] hover:bg-[#EA580C] text-white text-sm transition-colors"
            >
                Import
            </button>
        );
    };

    const handleProjectSelect = (projectId: string) => {
        const project = projects.find(p => p.id === projectId);
        if (project) {
            setSelectedProject(project);
            loadProjectIssues(project);
        }
    };

    useEffect(() => {
        if (issues.length > 0) {
            const types: Record<string, number> = {};
            issues.forEach(issue => {
                const type = issue.fields.issuetype.name;
                types[type] = (types[type] || 0) + 1;
            });
            setIssueTypes(types);
        }
    }, [issues]);

    if (error) {
        return (
            <div className="p-4 bg-red-500/10 text-red-400 rounded-lg border border-red-500/20">
                {error}
                <Link to="/jira-config" className="ml-2 text-red-400 hover:text-red-300">
                    <FiSettings className="inline-block mr-1" />
                    Check Configuration
                </Link>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                {/* Header Section */}
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center space-x-6">
                        <img src={logo} alt="VeloAI Logo" className="h-6 w-auto filter drop-shadow-glow" />
                        <div>
                            <div className="text-velo-orange-500/80 text-sm font-medium mb-1">
                                Jira Integration
                            </div>
                            <h1 className="text-4xl font-bold text-white tracking-tight">
                                Projects
                            </h1>
                        </div>
                    </div>
                    {isAdmin && (
                        <Link
                            to="/jira-config"
                            className="flex items-center px-4 py-2 rounded-lg bg-[#1E293B] text-white hover:bg-[#2D3B4E] transition-colors"
                        >
                            <FiSettings className="mr-2" />
                            Configure Jira
                        </Link>
                    )}
                </div>

                {/* Project Selection */}
                <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 p-6">
                    <h2 className="text-xl font-semibold mb-4 text-velo-orange-500">Select Project</h2>
                    <div className="relative">
                        <select
                            onChange={(e) => handleProjectSelect(e.target.value)}
                            value={selectedProject?.id || ''}
                            className="w-full bg-neutral-800/50 border border-neutral-700/50 rounded-xl py-3 px-4 text-neutral-200 appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-velo-orange-500/30 focus:border-velo-orange-500/50"
                        >
                            <option value="" className="bg-neutral-900 text-neutral-400">Select a Project</option>
                            {projects.map(project => (
                                <option
                                    key={project.id}
                                    value={project.id}
                                    className="bg-neutral-900 text-neutral-200 py-2"
                                >
                                    {project.name} ({project.key})
                                </option>
                            ))}
                        </select>
                        <div className="absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none text-velo-orange-500">
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>
                    </div>
                </div>

                {/* Search Bar */}
                <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 p-6">
                    <div className="relative">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search issues by title, key, or description..."
                            className="w-full bg-neutral-800/50 border border-neutral-700/50 rounded-xl py-3 px-4 pl-12 text-neutral-200 placeholder-neutral-500 focus:outline-none focus:ring-2 focus:ring-velo-orange-500/30 focus:border-velo-orange-500/50"
                        />
                        <div className="absolute left-4 top-1/2 transform -translate-y-1/2 text-neutral-500">
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </div>
                    </div>
                </div>

                {/* Issue Type Tabs */}
                <div className="flex space-x-2 overflow-x-auto pb-2">
                    {Object.entries(issueTypes).map(([type, count]) => (
                        <button
                            key={type}
                            onClick={() => setSelectedType(type)}
                            className={`px-4 py-2 rounded-lg whitespace-nowrap transition-all duration-200 ${selectedType === type
                                ? "bg-velo-orange-500/20 text-velo-orange-500 border border-velo-orange-500/30"
                                : "bg-neutral-800/50 text-neutral-400 border border-neutral-700/50 hover:bg-neutral-700/50"
                                }`}
                        >
                            {type} ({issueTypeCounts[type] || 0})
                        </button>
                    ))}
                </div>

                {/* Issues List */}
                <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 p-6">
                    <div className="space-y-4">
                        {filteredIssues
                            .filter(issue => !selectedType || issue.fields.issuetype.name === selectedType)
                            .map((issue) => (
                                <div
                                    key={issue.id}
                                    className="bg-neutral-800/50 rounded-xl border border-neutral-700/50 p-4 hover:bg-neutral-700/50 transition-colors duration-200"
                                >
                                    <div className="flex items-start justify-between">
                                        <div className="flex-1">
                                            <div className="flex items-center">
                                                <h3 className="font-medium text-velo-orange-500">
                                                    {issue.fields.summary}
                                                </h3>
                                                <button
                                                    onClick={() => setExpandedIssue(expandedIssue === issue.id ? null : issue.id)}
                                                    className="ml-2 p-1 rounded-lg hover:bg-neutral-700/50 transition-colors"
                                                >
                                                    <FiChevronRight
                                                        className={`w-4 h-4 text-neutral-400 transform transition-transform duration-200 ${expandedIssue === issue.id ? 'rotate-90' : ''
                                                            }`}
                                                    />
                                                </button>
                                            </div>
                                            <div className="mt-1 flex items-center space-x-3 text-sm text-neutral-400">
                                                <span>{issue.key}</span>
                                                <span>•</span>
                                                <span>{issue.fields.issuetype.name}</span>
                                            </div>
                                            {expandedIssue === issue.id && issue.fields.description && (
                                                <div className="mt-3 p-3 bg-neutral-900/50 rounded-lg border border-neutral-800">
                                                    <div className="text-sm text-neutral-300 whitespace-pre-wrap">
                                                        {parseDescription(issue.fields.description)}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {getImportButton(issue)}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JiraProjectExplorer; 