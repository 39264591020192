import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import axios from "../config/axios";

interface CompanyCredits {
  totalCredits: number;
  creditsUsed: number;
  name: string;
}

interface CreditUpdateEvent extends CustomEvent {
  detail: {
    Company: {
      totalCredits: number;
      creditsUsed: number;
      name: string;
    };
  };
}

const UserCreditsDisplay: React.FC = () => {
  const { user } = useAuth();
  const [companyCredits, setCompanyCredits] = useState<CompanyCredits | null>(
    null
  );
  const [loading, setLoading] = useState(true);

  const fetchCompanyCredits = async () => {
    if (!user) return;

    try {
      const response = await axios.get("/auth/me");
      console.log('Fetched company credits:', response.data);
      if (response.data.Company) {
        setCompanyCredits({
          totalCredits: response.data.Company.totalCredits,
          creditsUsed: response.data.Company.creditsUsed,
          name: response.data.Company.name,
        });
      }
    } catch (error) {
      console.error("Error fetching company credits:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyCredits();
  }, [user]);

  // Listen for credit updates
  useEffect(() => {
    const handleCreditUpdate = (event: CreditUpdateEvent) => {
      console.log('Credit update event received:', event.detail);
      if (event.detail?.Company) {
        console.log('Updating company credits to:', event.detail.Company);
        setCompanyCredits({
          totalCredits: event.detail.Company.totalCredits,
          creditsUsed: event.detail.Company.creditsUsed,
          name: event.detail.Company.name,
        });
      }
    };

    // Add event listener with explicit casting
    window.addEventListener('creditUpdate', handleCreditUpdate as EventListener);

    return () => {
      window.removeEventListener('creditUpdate', handleCreditUpdate as EventListener);
    };
  }, []);

  if (!user || loading) return null;

  const availableCredits = companyCredits
    ? companyCredits.totalCredits - companyCredits.creditsUsed
    : 0;

  return (
    <div className="flex items-center space-x-4">
      <div className="flex items-center space-x-2">
        <div className="w-8 h-8 rounded-full bg-white/5 backdrop-blur-lg flex items-center justify-center">
          <svg
            className="w-4 h-4 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
        </div>
        <div className="flex flex-col">
          <span className="text-orange-400 text-sm font-medium">
            {user.name}
          </span>
          {companyCredits && (
            <span className="text-gray-400 text-xs">{companyCredits.name}</span>
          )}
        </div>
      </div>
      <div className="flex items-center space-x-2 px-3 py-1.5 bg-white/5 backdrop-blur-lg rounded-lg border border-white/10">
        <svg
          className="w-4 h-4 text-orange-400"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-1.95-9H8v2h2.05a3.001 3.001 0 005.9 0H18v-2h-2.05a3.001 3.001 0 00-5.9 0zM12 13a1 1 0 100-2 1 1 0 000 2z" />
        </svg>
        <div className="flex flex-col">
          <div className="flex items-center space-x-1">
            <span className="text-orange-400 text-sm font-semibold">
              {availableCredits}
            </span>
            <span className="text-gray-400 text-xs">
              / {companyCredits?.totalCredits || 0} tokens
            </span>
          </div>
          <span className="text-gray-400 text-xs">
            {companyCredits?.creditsUsed || 0} used
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserCreditsDisplay;
