import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { TreeNode } from '../services/fileSystemService';
import { FileSystemService } from '../services/fileSystemService';

interface TestCaseDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  artifact: TreeNode | null;
  onSave: (updatedArtifact: TreeNode) => void;
}

const TestCaseDetailsDialog: React.FC<TestCaseDetailsDialogProps> = ({
  open,
  onClose,
  artifact,
  onSave,
}) => {
  const [editedTestCaseId, setTestCaseId] = useState('');
  const [editedTestScenarioId, setTestScenarioId] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedPreconditions, setPreconditions] = useState('');
  const [editedSteps, setSteps] = useState('');
  const [editedTestData, setTestData] = useState('');
  const [editedExpectedResults, setExpectedResults] = useState('');
  const [editedActualResults, setActualResults] = useState('');
  const [editedStatus, setStatus] = useState('');
  const [editedComments, setComments] = useState('');
  const [editedRequirements, setRequirements] = useState('');
  const fileSystem = FileSystemService.getInstance();

  useEffect(() => {
    if (artifact) {
      const testCaseData = artifact.content?.content || {};
      setTestCaseId(testCaseData.id || '');
      setTestScenarioId(testCaseData.testScenarioId || '');
      setEditedDescription(testCaseData.description || '');
      setPreconditions(testCaseData.preconditions || '');
      setSteps(Array.isArray(testCaseData.steps) ? testCaseData.steps.join('\n') : testCaseData.steps || '');
      setTestData(testCaseData.testData || '');
      setExpectedResults(testCaseData.expectedResults || '');
      setActualResults(testCaseData.actualResults || '');
      setStatus(testCaseData.status || '');
      setComments(testCaseData.comments || '');

    }
  }, [artifact]);

  const handleSave = () => {
    if (!artifact) return;
    
    // Update the node's content while preserving the type and other fields
    const updatedContent = {
      ...artifact.content,
      content: {
        ...artifact.content?.content,
        id: editedTestCaseId,
        testScenarioId: editedTestScenarioId,
        description: editedDescription,
        preconditions: editedPreconditions,
        steps: editedSteps.split('\n'),
        testData: editedTestData,
        expectedResults: editedExpectedResults,
        actualResults: editedActualResults,
        status: editedStatus,
        comments: editedComments
      }
    };

    // Update the artifact with new content while preserving children
    const updatedArtifact = {
      ...artifact,
      content: updatedContent,
      displayName: editedDescription,
      fullDescription: editedDescription,
      children: artifact.children
    };

    onSave(updatedArtifact);
    onClose();
  };

  if (!artifact) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#1a1b26',
          color: 'white',
          minWidth: '600px',
          maxHeight: '90vh'
        }
      }}
    >
      <DialogTitle 
        style={{ 
          wordBreak: 'break-word',
          paddingRight: '48px'
        }}
      >
        {artifact?.content?.content?.id || 'Test Case Details'}
      </DialogTitle>
      <DialogContent>
        <div className="space-y-4">
          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Test Case ID
            </Typography>
            <TextField
              fullWidth
              value={editedTestCaseId}
              onChange={(e) => setTestCaseId(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                }
              }}
            />
          </div>

          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Test Scenario ID
            </Typography>
            <TextField
              fullWidth
              value={editedTestScenarioId}
              onChange={(e) => setTestScenarioId(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                }
              }}
            />
          </div>

          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Description
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }
              }}
            />
          </div>

          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Preconditions
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={editedPreconditions}
              onChange={(e) => setPreconditions(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }
              }}
            />
          </div>

          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Steps
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={editedSteps}
              onChange={(e) => setSteps(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }
              }}
            />
          </div>

          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Test Data
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={editedTestData}
              onChange={(e) => setTestData(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }
              }}
            />
          </div>

          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Expected Results
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={editedExpectedResults}
              onChange={(e) => setExpectedResults(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }
              }}
            />
          </div>

          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Actual Results
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={editedActualResults}
              onChange={(e) => setActualResults(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }
              }}
            />
          </div>

          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Status
            </Typography>
            <TextField
              fullWidth
              value={editedStatus}
              onChange={(e) => setStatus(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                }
              }}
            />
          </div>

          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Comments
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={editedComments}
              onChange={(e) => setComments(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }
              }}
            />
          </div>

          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Requirements
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={editedRequirements}
              onChange={(e) => setRequirements(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '16px 24px' }}>
        <Button onClick={onClose} variant="outlined" style={{ color: '#e0e0e0' }}>
          CLOSE
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          SAVE CHANGES
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TestCaseDetailsDialog;
