import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { TreeNode } from '../services/fileSystemService';
import { FileSystemService } from '../services/fileSystemService';

interface ArtifactDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  artifact: TreeNode | null;
  onSave: (updatedArtifact: TreeNode) => void;
}

const ArtifactDetailsDialog: React.FC<ArtifactDetailsDialogProps> = ({
  open,
  onClose,
  artifact,
  onSave,
}) => {
  const [editedDescription, setEditedDescription] = useState('');
  const [editedCriteria, setEditedCriteria] = useState<string[]>([]);
  const fileSystem = FileSystemService.getInstance();

  // Get description and criteria from the nested structure
  useEffect(() => {
    if (artifact) {
      // Find description folder's content
      const descriptionFolder = artifact.children?.find(child => child.name === 'Description');
      const descriptionContent = descriptionFolder?.children?.find(child => child.name === 'content')?.content;
      const description = descriptionContent?.fullDescription || '';

      // Find acceptance criteria folder's content
      const criteriaFolder = artifact.children?.find(child => child.name === 'Acceptance Criteria');
      const criteriaNodes = criteriaFolder?.children || [];
      
      const criteria = criteriaNodes.map(node => {
        const content = node.content;
        return content?.fullDescription || '';
      }).filter(Boolean);

      setEditedDescription(description);
      setEditedCriteria(criteria);
    }
  }, [artifact]);

  const handleSave = () => {
    if (!artifact) return;
    
    // Update the node's content
    const updatedContent = {
      id: artifact.content.id,
      type: 'requirement',
      fullDescription: editedDescription
    };

    // Get the path to this node
    const path = ["Requirements", artifact.name];
    
    try {
      // Update the node in the file system
      fileSystem.updateNode(path, updatedContent);
      
      // Also update the nested structure for Description and Acceptance Criteria
      const updatedArtifact: TreeNode = {
        ...artifact,
        content: updatedContent, // Add this to ensure the main node has the updated content
        children: [
          {
            id: 'description',
            name: 'Description',
            type: 'folder' as const,
            children: [{
              id: 'description-content',
              name: 'content',
              type: 'file' as const,
              content: {
                type: 'description-content',
                displayName: editedDescription,
                fullDescription: editedDescription
              }
            }]
          },
          {
            id: 'criteria',
            name: 'Acceptance Criteria',
            type: 'folder' as const,
            children: editedCriteria.map((criteria, index) => ({
              id: `criteria-${index}`,
              name: `${index + 1}`,
              type: 'file' as const,
              content: {
                type: 'acceptance-criteria',
                displayName: criteria,
                fullDescription: criteria
              }
            }))
          }
        ]
      };

      // Update the file system with the full updated structure
      const descPath = [...path, "Description"];
      fileSystem.updateNode(descPath, { type: 'folder' });
      fileSystem.updateNode([...descPath, "content"], {
        type: 'description-content',
        displayName: editedDescription,
        fullDescription: editedDescription
      });

      const acPath = [...path, "Acceptance Criteria"];
      fileSystem.updateNode(acPath, { type: 'folder' });
      // Remove existing criteria and add new ones
      const acFolder = fileSystem.getFile(acPath);
      if (acFolder?.children) {
        // Remove old criteria
        acFolder.children.length = 0;
        // Add new criteria
        editedCriteria.forEach((criteria, index) => {
          fileSystem.updateNode([...acPath, `${index + 1}`], {
            type: 'acceptance-criteria',
            displayName: criteria,
            fullDescription: criteria
          });
        });
      }

      onSave(updatedArtifact);
      onClose();
    } catch (error) {
      console.error('Error updating requirement:', error);
      // You might want to show an error message to the user here
    }
  };

  if (!artifact) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#1a1b26',
          color: 'white',
          minWidth: '600px',
          maxHeight: '90vh'
        }
      }}
    >
      <DialogTitle 
        style={{ 
          wordBreak: 'break-word',
          paddingRight: '48px' // Make room for the close button
        }}
      >
        {artifact?.content?.id || 'Requirement Details'}
      </DialogTitle>
      <DialogContent>
        <div className="space-y-4">
          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Description
            </Typography>
            <TextField
              multiline
              fullWidth
              minRows={3}
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              variant="outlined"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
              InputProps={{
                style: {
                  color: '#e0e0e0',
                }
              }}
            />
          </div>
          
          <div>
            <Typography variant="h6" style={{ color: '#e0e0e0', marginBottom: '8px' }}>
              Acceptance Criteria
            </Typography>
            <div className="space-y-2">
              {editedCriteria.map((criteria, index) => (
                <div key={index} className="flex gap-2 items-start">
                  <TextField
                    multiline
                    fullWidth
                    value={criteria}
                    onChange={(e) => {
                      const newCriteria = [...editedCriteria];
                      newCriteria[index] = e.target.value;
                      setEditedCriteria(newCriteria);
                    }}
                    variant="outlined"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    }}
                    InputProps={{
                      style: {
                        color: '#e0e0e0',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word'
                      }
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      const newCriteria = editedCriteria.filter((_, i) => i !== index);
                      setEditedCriteria(newCriteria);
                    }}
                    style={{ minWidth: '100px' }}
                  >
                    REMOVE
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '16px 24px' }}>
        <Button onClick={onClose} variant="outlined" style={{ color: '#e0e0e0' }}>
          CANCEL
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          SAVE CHANGES
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArtifactDetailsDialog;
