import React, { useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    Alert,
    Container,
    Checkbox,
    FormControlLabel,
    Link
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../config/axios';
import { useAuth } from '../contexts/AuthContext';

const SetInitialPassword: React.FC = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const navigate = useNavigate();
    const { user, updateUser } = useAuth();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');

        if (!termsAccepted) {
            setError('Please accept the Terms and Conditions to continue');
            return;
        }

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (newPassword.length < 6) {
            setError('Password must be at least 6 characters long');
            return;
        }

        try {
            await axiosInstance.post('/auth/set-initial-password', {
                password: newPassword
            });

            if (user) {
                updateUser({ ...user, isFirstLogin: false });
            }

            navigate('/');
        } catch (err) {
            setError('Failed to update password. Please try again.');
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
            <Container maxWidth="sm">
                <Box sx={{ 
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 8
                }}>
                    <Paper 
                        className="backdrop-blur-sm bg-black/40 border border-velo-orange-500/20 overflow-hidden shadow-xl p-8 w-full"
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        }}
                    >
                        <Typography 
                            variant="h4" 
                            component="h1" 
                            sx={{ 
                                color: '#F97316',
                                mb: 2,
                                fontWeight: 'bold'
                            }}
                        >
                            Welcome to VeloAI!
                        </Typography>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                color: 'rgba(255, 255, 255, 0.87)',
                                mb: 3 
                            }}
                        >
                            Please set your permanent password to continue.
                        </Typography>

                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}

                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                label="New Password"
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                margin="normal"
                                required
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'rgba(249, 115, 22, 0.2)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'rgba(249, 115, 22, 0.4)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#F97316',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'rgba(249, 115, 22, 0.8)',
                                    },
                                    '& .MuiInputBase-input': {
                                        color: 'white',
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Confirm Password"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                margin="normal"
                                required
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'rgba(249, 115, 22, 0.2)',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'rgba(249, 115, 22, 0.4)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#F97316',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'rgba(249, 115, 22, 0.8)',
                                    },
                                    '& .MuiInputBase-input': {
                                        color: 'white',
                                    },
                                }}
                            />

                            <Box sx={{ mt: 2, mb: 3 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={termsAccepted}
                                            onChange={(e) => setTermsAccepted(e.target.checked)}
                                            sx={{
                                                color: 'rgba(249, 115, 22, 0.8)',
                                                '&.Mui-checked': {
                                                    color: '#F97316',
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography className="text-gray-300">
                                            I accept the{' '}
                                            <Link
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    // Add terms modal or navigation here
                                                }}
                                                sx={{
                                                    color: '#F97316',
                                                    textDecoration: 'underline',
                                                    '&:hover': {
                                                        color: 'rgba(249, 115, 22, 0.8)',
                                                    },
                                                }}
                                            >
                                                Terms and Conditions
                                            </Link>
                                        </Typography>
                                    }
                                />
                            </Box>

                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                disabled={!termsAccepted}
                                sx={{
                                    mt: 3,
                                    background: 'linear-gradient(to right, #F97316, #EA580C)',
                                    '&:hover': {
                                        background: 'linear-gradient(to right, #EA580C, #F97316)',
                                    },
                                    '&:disabled': {
                                        background: 'rgba(249, 115, 22, 0.3)',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                    },
                                }}
                            >
                                Set Password
                            </Button>
                        </form>
                    </Paper>
                </Box>
            </Container>
        </div>
    );
};

export default SetInitialPassword;