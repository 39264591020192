import React from 'react';
import { Link } from 'react-router-dom';
import { FiFileText, FiCode, FiCheckSquare, FiLayers, FiBox, FiGlobe, FiPieChart, FiActivity, FiSettings, FiTrello, FiUploadCloud } from 'react-icons/fi';
import logo from './veloai-logo.png';
import { useAuth } from '../contexts/AuthContext';

interface ModuleTile {
  title: string;
  description: string;
  icon: React.ElementType;
  href: string;
  color: string;
  adminOnly?: boolean;
}

const modules: ModuleTile[] = [
  {
    title: 'Global Context',
    description: 'Manage and view global testing context and configurations',
    icon: FiGlobe,
    href: '/global-context',
    color: 'from-teal-400/20 to-teal-600/20'
  },
  {
    title: 'Requirements Validator',
    description: 'Validate and analyse requirements with AI assistance',
    icon: FiFileText,
    href: '/requirements-validator',
    color: 'from-orange-400/20 to-orange-600/20'
  },
  {
    title: 'Test Scenario Design',
    description: 'Design comprehensive test scenarios and flows',
    icon: FiLayers,
    href: '/test-scenario-design',
    color: 'from-blue-400/20 to-blue-600/20'
  },
  {
    title: 'Test Case Creation',
    description: 'Create and manage test cases with AI assistance',
    icon: FiCheckSquare,
    href: '/test-case-creation',
    color: 'from-green-400/20 to-green-600/20'
  },
  {
    title: 'Automation Development',
    description: 'Develop and manage test automation scripts',
    icon: FiCode,
    href: '/automation-development',
    color: 'from-purple-400/20 to-purple-600/20'
  },
  {
    title: 'Coverage Optimisation',
    description: 'Optimise and analyse test coverage metrics',
    icon: FiPieChart,
    href: '/coverage-optimisation',
    color: 'from-pink-400/20 to-pink-600/20'
  },
  {
    title: 'Unit Test Generator',
    description: 'Automatically generate unit tests with AI',
    icon: FiBox,
    href: '/unit-test-generator',
    color: 'from-indigo-400/20 to-indigo-600/20'
  },
  {
    title: 'Unit Test Coverage Analyser',
    description: 'Analyse and improve unit test coverage',
    icon: FiActivity,
    href: '/unit-test-coverage-analyzer',
    color: 'from-red-400/20 to-red-600/20'
  },
  {
    title: 'Jira Integration',
    description: 'Import and manage Jira issues and requirements',
    icon: FiTrello,
    href: '/jira-projects',
    color: 'from-cyan-400/20 to-cyan-600/20'
  },
  {
    title: 'Admin Dashboard',
    description: 'Manage system settings and user permissions',
    icon: FiSettings,
    href: '/admin',
    color: 'from-gray-400/20 to-gray-600/20',
    adminOnly: true
  }
];

const LandingPage: React.FC = () => {
  const { user } = useAuth();
  const isAdmin = user?.role === 'admin' || user?.role === 'super_admin';

  // Filter modules based on user role
  const filteredModules = modules.filter(module => !module.adminOnly || isAdmin);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-8">
      {/* Logo Section */}
      <div className="text-center mb-12">
        <img src={logo} alt="VeloAI" className="h-24 w-auto mb-8 filter drop-shadow-glow" />
      </div>

      {/* Modules Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl w-full">
        {filteredModules.map((module) => (
          <Link
            key={module.title}
            to={module.href}
            className="group relative overflow-hidden rounded-xl bg-neutral-800/50 border border-neutral-700/50 p-6 transition-all duration-300 hover:bg-neutral-800/80 hover:border-neutral-600/50"
          >
            {/* Background Gradient */}
            <div className={`absolute inset-0 bg-gradient-to-br ${module.color} opacity-20 group-hover:opacity-30 transition-opacity`} />

            <div className="relative flex items-start">
              {/* Icon */}
              <div className="flex-shrink-0 p-3 rounded-lg bg-neutral-800/80 border border-neutral-700/50">
                <module.icon className="w-6 h-6 text-orange-400" />
              </div>

              {/* Content */}
              <div className="ml-4 flex-1">
                <h3 className="text-lg font-medium text-neutral-200 mb-1 group-hover:text-orange-400 transition-colors">
                  {module.title}
                </h3>
                <p className="text-sm text-neutral-400 group-hover:text-neutral-300 transition-colors">
                  {module.description}
                </p>
              </div>

              {/* Arrow indicator */}
              <div className="absolute right-2 top-1/2 -translate-y-1/2 opacity-0 transform translate-x-4 group-hover:opacity-100 group-hover:translate-x-0 transition-all">
                <FiBox className="w-5 h-5 text-orange-400" />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default LandingPage;
