import React, { useState, useContext, useEffect } from "react";
import {
  validateRequirements,
  rewriteRequirements,
  validateRequirementsWithReflection,
  rewriteRequirementsWithReflection,
} from "../services/aiService";
import { Link, useOutletContext } from "react-router-dom";
import logo from "./veloai-logo.png";
import TextArea from "./TextArea";
import { GlobalContext } from "./shared/GlobalContext";
import { TreeNode } from "../services/fileSystemService";
import axiosInstance from "../services/axiosInstance";
import { withCredits } from "../utils/withCredits";

interface ValidationResult {
  feedback: any;
}

interface NewRewrittenRequirements {
  feedback: any;
}

interface OutletContextType {
  fileSystem: any;
  selectedFile: TreeNode | null;
  refreshTrigger: number;
}

function ValidationResultsDisplay({
  results,
  newrequirements,
}: {
  results: ValidationResult;
  newrequirements: NewRewrittenRequirements;
}) {
  const [activeCategories, setActiveCategories] = useState<string[]>(["final"]);
  let feedback: any;
  let feedbackrewritten: any;

  try {
    feedback = typeof results === "string" ? JSON.parse(results) : results;
    feedbackrewritten =
      typeof newrequirements === "string"
        ? JSON.parse(newrequirements)
        : newrequirements;
  } catch (error) {
    console.error("Error parsing feedback:", error);
    feedback = {};
  }

  return (
    <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
      <div className="px-8 py-6">
        <h2 className="text-2xl font-bold text-white mb-6">
          Validation Results
        </h2>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-velo-blue-500/20">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-velo-orange-500/80 uppercase tracking-wider">
                  Requirement ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-velo-orange-500/80 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-velo-orange-500/80 uppercase tracking-wider">
                  Issues
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-velo-orange-500/80 uppercase tracking-wider">
                  Recommendations
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-velo-blue-500/20">
              {feedback?.FinalReport?.ValidatedRequirements.map(
                (req: any, index: number) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-black/20" : "bg-black/10"}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      {req.RequirementID}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span
                        className={`px-2 py-1 rounded-full text-xs ${req.Status === "Validated"
                          ? "bg-green-500/20 text-green-400"
                          : req.Status === "Validated with Recommendations"
                            ? "bg-yellow-500/20 text-yellow-400"
                            : req.Status === "Incomplete"
                              ? "bg-red-500/20 text-red-400"
                              : "bg-yellow-500/20 text-yellow-400"
                          }`}
                      >
                        {req.Status}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-300">
                      <ul className="list-disc list-inside">
                        {req.Issues.map((issue: string, idx: number) => (
                          <li key={idx}>{issue}</li>
                        ))}
                      </ul>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-300">
                      <ul className="list-disc list-inside">
                        {req.Recommendations.map((rec: string, idx: number) => (
                          <li key={idx}>{rec}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>

        <div className="mt-6 bg-black/20 border border-velo-blue-500/20 rounded-lg p-4">
          <h3 className="text-lg font-medium text-velo-orange-500/80 mb-3">
            Summary
          </h3>
          <p className="text-gray-300">{feedback?.FinalReport?.Summary}</p>
        </div>
      </div>
    </div>
  );
}

function RequirementsValidator() {
  const [requirements, setRequirements] = useState("");
  const [validationResults, setValidationResults] = useState<ValidationResult | null>(null);
  const [rewrittenRequirements, setRewrittenRequirements] = useState<NewRewrittenRequirements | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [savedFileName, setSavedFileName] = useState("");
  const [processingStatus, setProcessingStatus] = useState<string>("");
  const { contextData } = useContext(GlobalContext);
  const { fileSystem, selectedFile, refreshTrigger } = useOutletContext<OutletContextType>();
  const [refreshTriggerState, setRefreshTriggerState] = useState(refreshTrigger);


  useEffect(() => {
    if (selectedFile && selectedFile.type === "file" && selectedFile.content) {
      try {
        const content = selectedFile.content;
        if (content.requirements) {
          setRequirements(content.requirements);
        }
        if (content.validationResults) {
          setValidationResults(content.validationResults);
        }
        if (content.rewrittenRequirements) {
          setRewrittenRequirements(content.rewrittenRequirements);
        }
        setSavedFileName(selectedFile.name);
      } catch (error) {
        console.error("Error loading file:", error);
        setError("Error loading the selected file");
      }
    }
  }, [selectedFile, refreshTrigger]);

  const saveToFileSystem = async (
    results: ValidationResult,
    rewritten: NewRewrittenRequirements
  ) => {
    try {
      // Extract requirements from the feedback structure
      const rewrittenReqs = rewritten?.feedback?.requirements || [];

      if (rewrittenReqs.length === 0) {
        console.warn("No rewritten requirements to save");
        return;
      }
      // Process each rewritten requirement
      for (const req of rewrittenReqs) {
 
        
        // Create requirement folder directly under Requirements
        const reqPath = ["Requirements", `${req.id} - ${req.description}`];
        
        // Store the full description in the folder's content
        const folderContent = {
          fullDescription: req.description,
          id: req.id,
          type: 'requirement'
        };
  
        await fileSystem.createFolder(reqPath, folderContent);

        // Create Description folder
        const descPath = [...reqPath, "Description"];
        await fileSystem.createFolder(descPath);
        
        // Create content node with the actual description
        await fileSystem.createFolder([...descPath, "content"], {
          type: 'description-content',
          displayName: req.description,

          fullDescription: req.description
        });

        // Create Acceptance Criteria folder
        const acPath = [...reqPath, "Acceptance Criteria"];
        await fileSystem.createFolder(acPath);

        // Create acceptance criteria items
        if (req.acceptanceCriteria && req.acceptanceCriteria.length > 0) {

          for (let i = 0; i <req.acceptanceCriteria.length; i++) {
            const criteria = req.acceptanceCriteria[i];
            // Handle both string criteria and object criteria
            const criteriaText = typeof criteria === 'string' ? criteria : criteria.criterion;
            
            await fileSystem.createFolder([...acPath, `${i + 1}`], {
              type: 'acceptance-criteria',
              displayName: criteriaText,
              fullDescription: criteriaText
            });
          }
        }
      }
    } catch (error) {
      console.error("Error saving to file system:", error);
      throw error;
    }
  };

  const handleValidateRequirements = async () => {
    setIsLoading(true);
    setError(null);
    setProcessingStatus("Starting requirements validation...");

    try {
      // Split requirements into individual lines
      const requirementLines = requirements.split('\n').filter(line => line.trim());
     

      // Get validation results
      setProcessingStatus("Validating requirements...");
      
      const results = await withCredits("requirements-validator", () => validateRequirementsWithReflection(
        requirements,
        contextData
      )
    );

      setValidationResults(results);
      setProcessingStatus("Requirements validated. Starting rewrite process...");

      // Extract individual requirements from validation results
      const validatedReqs = typeof results === 'string' ? JSON.parse(results) : results;
 
      // Get the requirements from the FinalReport's ValidatedRequirements
      const validatedRequirements = validatedReqs?.FinalReport?.ValidatedRequirements || [];
      
      // Process each requirement separately
      const allRewrittenRequirements = [];
      for (let i = 0; i < validatedRequirements.length; i++) {
        const req = validatedRequirements[i];
        const originalContent = requirementLines[i] || '';
        setProcessingStatus(`Rewriting requirement ${i + 1} of ${validatedRequirements.length}...`);
        
        // Send the requirement in the expected format
        const reqToRewrite = {
          requirements: [{
            id: req.RequirementID,
            description: originalContent,
            acceptanceCriteria: []
          }]
        };
            
        try {
          const rewrittenReq = await rewriteRequirementsWithReflection(
            JSON.stringify(reqToRewrite),
            validatedReqs
          );
          
          if (rewrittenReq?.requirements?.length > 0) {
            allRewrittenRequirements.push(...rewrittenReq.requirements);
          } else if (rewrittenReq?.feedback?.requirements?.length > 0) {
            allRewrittenRequirements.push(...rewrittenReq.feedback.requirements);
          }
        } catch (error) {
          console.error("Error rewriting requirement:", req.RequirementID, error);
        }
      }

      setProcessingStatus("Requirements rewritten. Saving to file system...");
    
      const finalRewrittenRequirements: NewRewrittenRequirements = {
        feedback: {
          requirements: allRewrittenRequirements
        }
      };

      setRewrittenRequirements(finalRewrittenRequirements);
      await saveToFileSystem(results, finalRewrittenRequirements);
      setSavedFileName("requirements.json");
      setProcessingStatus("Requirements processed and saved successfully!");

    } catch (error: any) {
      console.error("Error validating requirements:", error);
      setError(error.message || "An error occurred while validating requirements");
      setProcessingStatus("Error processing requirements");
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewValidation = () => {
    setRequirements("");
    setValidationResults(null);
    setRewrittenRequirements(null);
    setSavedFileName("");
    setError(null);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <img
              src={logo}
              alt="VeloAI Logo"
              className="h-6 w-auto filter drop-shadow-glow"
            />
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">
                Testing Tools
              </div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Requirements Validator
              </h1>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <button
              onClick={handleNewValidation}
              className="group flex items-center space-x-2 px-4 py-2 rounded-lg text-gray-300 hover:text-white hover:bg-neutral-800/50 transition-all duration-200"
            >
              <span>New Validation</span>
              <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                +
              </span>
            </button>
            <Link
              to="/"
              className="group flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
            >
              <span>Return to Dashboard</span>
              <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                →
              </span>
            </Link>
          </div>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 gap-8">
          {/* Status Indicator */}
          {(isLoading || processingStatus) && (
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-4">
                <div className="flex items-center space-x-3">
                  {isLoading && (
                    <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-velo-orange-500"></div>
                  )}
                  <span className="text-velo-orange-500">{processingStatus}</span>
                </div>
              </div>
            </div>
          )}
          
          {/* Requirements Input */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <label className="block text-sm font-medium text-velo-orange-500/80 mb-2">
                Requirements
              </label>
              <textarea
                value={requirements}
                onChange={(e) => setRequirements(e.target.value)}
                placeholder="Enter your requirements here..."
                rows={10}
                className="w-full bg-black/20 border border-velo-orange-500/20 rounded-lg p-3 text-white placeholder-gray-400"
              />
            </div>
          </div>

          {/* Validate Button */}
          <div className="flex justify-center">
            <button
              onClick={handleValidateRequirements}
              disabled={isLoading || !requirements.trim()}
              className={`group relative px-8 py-4 rounded-xl font-medium text-base
                ${isLoading
                  ? "bg-velo-blue-900/50 cursor-not-allowed"
                  : "bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500"
                }
                text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
                transform hover:-translate-y-0.5
              `}
            >
              <span className="flex items-center space-x-2">
                {isLoading ? (
                  <>
                    <span className="opacity-70">Validating Requirements</span>
                    <span className="animate-pulse">...</span>
                  </>
                ) : (
                  <>
                    <span>Validate Requirements</span>
                    <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                      →
                    </span>
                  </>
                )}
              </span>
            </button>
          </div>

          {/* Error Display */}
          {error && <div className="text-red-500 text-center">{error}</div>}

          {/* Validation Results */}
          {validationResults && (
            <ValidationResultsDisplay
              results={validationResults}
              newrequirements={rewrittenRequirements!}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default RequirementsValidator;

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};
