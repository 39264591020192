import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Navigate } from "react-router-dom";
import UserManagement from "./UserManagement";
import CreditMetrics from "./CreditMetrics";

const AdminDashboard: React.FC = () => {
  const { user } = useAuth();

  // Redirect if user is not admin or super_admin
  if (!user || (user.role !== "admin" && user.role !== "super_admin")) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">
                Administration
              </div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Admin Dashboard
              </h1>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 overflow-hidden shadow-xl">
          <div className="px-8 py-6">
            <UserManagement />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;