import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, Outlet, useNavigate } from "react-router-dom";
import {
  useFileSystem,
  FileSystemService,
} from "../services/fileSystemService";
import { TreeNode } from "../services/fileSystemService";
import TreeView from "./TreeView";
import { useAuth } from "../contexts/AuthContext";
import UserCreditsDisplay from "./UserCreditsDisplay";
import logo from "./veloai-logo.png";
import {
  FiMenu,
  FiX,
  FiCode,
  FiCheckSquare,
  FiLayers,
  FiFileText,
  FiGitBranch,
  FiPieChart,
  FiBox,
  FiActivity,
  FiRefreshCw,
  FiChevronLeft,
  FiSettings,
  FiHelpCircle,
  FiGlobe,
  FiGrid,
  FiChevronDown,
  FiTrello,
  FiDownload,
} from "react-icons/fi";

export interface OutletContextType {
  selectedNodes: TreeNode[];
  fileSystem: FileSystemService;
  selectedFile: TreeNode | null;
}

const Layout: React.FC = () => {
  const fileSystem = useFileSystem();
  const { user } = useAuth();
  const [selectedFile, setSelectedFile] = useState<TreeNode | null>(null);
  const [selectedNodes, setSelectedNodes] = useState<TreeNode[]>([]);
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isExplorerCollapsed, setExplorerCollapsed] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [explorerWidth, setExplorerWidth] = useState(320); // 16rem default
  const [isResizing, setIsResizing] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Close dropdown when route changes
  useEffect(() => {
    setMoreMenuOpen(false);
  }, [location.pathname]);

  const MIN_EXPLORER_WIDTH = 240; // Strict minimum width

  const navigation = [
    { name: "Global Context", href: "/global-context", icon: FiGlobe },
    {
      name: "Requirements Validator",
      href: "/requirements-validator",
      icon: FiFileText,
    },
    {
      name: "Test Scenario Design",
      href: "/test-scenario-design",
      icon: FiLayers,
    },
    {
      name: "Test Case Creation",
      href: "/test-case-creation",
      icon: FiCheckSquare,
    },
    {
      name: "Automation Development",
      href: "/automation-development",
      icon: FiCode,
    },
    {
      name: "Coverage Optimisation",
      href: "/coverage-optimisation",
      icon: FiPieChart,
    },
    { name: "Unit Test Generator", href: "/unit-test-generator", icon: FiBox },
    {
      name: "Unit Test Coverage Analyser",
      href: "/unit-test-coverage-analyzer",
      icon: FiActivity,
    },
    {
      name: "Jira Integration",
      href: "/jira-projects",
      icon: FiTrello,
      children: [
        {
          name: "Projects & Issues",
          href: "/jira-projects",
          icon: FiTrello,
        },
        {
          name: "Configuration",
          href: "/jira-config",
          icon: FiSettings,
          adminOnly: true,
        },
      ],
    },
    {
      name: "Export Options",
      href: "/csv-export",
      icon: FiDownload,
      children: [
        {
          name: "CSV Export",
          href: "/csv-export",
          icon: FiDownload,
        },
        {
          name: "Jira Export",
          href: "/jira-export",
          icon: FiTrello,
        },
      ],
    },
    {
      name: "Admin Dashboard",
      href: "/admin",
      icon: FiSettings,
      adminOnly: true,
    },
    {
      name: "Metrics",
      href: "/metrics",
      icon: FiPieChart,
      adminOnly: true,
    },
  ];

  // Get visible navigation items based on user role
  const visibleNavigation = navigation.filter((item) => {
    if (item.adminOnly) {
      return user && (user.role === "admin" || user.role === "super_admin");
    }
    if (item.children) {
      item.children = item.children.filter((child) => {
        if (child.adminOnly) {
          return user && (user.role === "admin" || user.role === "super_admin");
        }
        return true;
      });
    }
    return true;
  });

  // Function to get next/previous module
  const getAdjacentModules = () => {
    const currentIndex = visibleNavigation.findIndex(
      (item) => item.href === location.pathname
    );

    return {
      prev: currentIndex > 0 ? visibleNavigation[currentIndex - 1] : null,
      next: currentIndex < visibleNavigation.length - 1 ? visibleNavigation[currentIndex + 1] : null,
    };
  };

  // Navigation component
  const ModuleNavigation = () => {
    const { prev, next } = getAdjacentModules();

    if (!prev && !next) return null;

    return (
      <div className="fixed bottom-8 right-8 flex items-center space-x-4 z-40">
        {prev && (
          <button
            onClick={() => navigate(prev.href)}
            className="flex items-center px-4 py-2 rounded-lg bg-neutral-800/90 backdrop-blur-sm border border-neutral-700/50 text-neutral-400 hover:text-orange-400 hover:bg-neutral-700/50 transition-all duration-150 shadow-lg"
          >
            <FiChevronLeft className="h-4 w-4 mr-2" />
            <span className="text-sm font-medium">Previous: {prev.name}</span>
          </button>
        )}
        {next && (
          <button
            onClick={() => navigate(next.href)}
            className="flex items-center px-4 py-2 rounded-lg bg-neutral-800/90 backdrop-blur-sm border border-neutral-700/50 text-neutral-400 hover:text-orange-400 hover:bg-neutral-700/50 transition-all duration-150 shadow-lg"
          >
            <span className="text-sm font-medium">Next: {next.name}</span>
            <FiChevronLeft className="h-4 w-4 ml-2 rotate-180" />
          </button>
        )}
      </div>
    );
  };

  const renderNavigationItems = () => {
    return navigation.map((item) => {
      if (
        item.adminOnly &&
        (!user || (user.role !== "admin" && user.role !== "super_admin"))
      ) {
        return null;
      }

      return (
        <Link
          key={item.name}
          to={item.href}
          className={`group flex items-center px-3 py-2 rounded-lg transition-all duration-150
            ${location.pathname === item.href
              ? "bg-orange-400/10 text-orange-400"
              : "text-neutral-400 hover:bg-neutral-700/50 hover:text-orange-400"
            }
          `}
        >
          <item.icon
            className={`flex-shrink-0 h-4 w-4 ${location.pathname === item.href
              ? "text-orange-400"
              : "text-neutral-400 group-hover:text-orange-400"
              }`}
          />
          <span className="ml-2 text-sm font-medium whitespace-nowrap">
            {item.name}
          </span>
        </Link>
      );
    });
  };

  const handleFileSelect = (node: TreeNode) => {
    setSelectedFile(node);
  };

  const handleSelectionChange = (nodes: TreeNode[]) => {
    setSelectedNodes(nodes);
  };

  const handleRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const handleResizeStart = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setIsResizing(true);
  }, []);

  const handleResizeEnd = useCallback(() => {
    setIsResizing(false);
  }, []);

  const handleResize = useCallback(
    (e: MouseEvent) => {
      if (!isResizing) return;

      const newWidth = Math.max(
        MIN_EXPLORER_WIDTH,
        Math.min(e.clientX, window.innerWidth * 0.8)
      );
      setExplorerWidth(newWidth);
    },
    [isResizing]
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Set initial width
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleModuleClick = (href: string) => {
    navigate(href);
    setMoreMenuOpen(false);
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleResize);
      window.addEventListener("mouseup", handleResizeEnd);
      return () => {
        window.removeEventListener("mousemove", handleResize);
        window.removeEventListener("mouseup", handleResizeEnd);
      };
    }
  }, [isResizing, handleResize, handleResizeEnd]);

  return (
    <div className="min-h-screen bg-neutral-900">
      {/* Top Navigation Bar */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-neutral-800/95 backdrop-blur-sm border-b border-neutral-700/50">
        <div className="flex items-center h-16 px-4">
          {/* Logo Area */}
          <div className="flex items-center pr-8 border-r border-neutral-700/50 h-full">
            <Link to="/" className="flex items-center">
              <img src={logo} alt="VeloAI" className="h-8 w-auto" />
            </Link>
          </div>

          {/* Modules Dropdown */}
          <div className="relative ml-4">
            <button
              id="modules-button"
              onClick={() => setMoreMenuOpen(!moreMenuOpen)}
              className={`
                flex items-center px-3 py-2 rounded-lg transition-all duration-150 space-x-2
                ${moreMenuOpen ? "bg-orange-400/10 text-orange-400" : "text-neutral-400 hover:bg-neutral-700/50 hover:text-orange-400"}
              `}
            >
              <FiGrid className="h-4 w-4" />
              <span className="text-sm font-medium">Modules</span>
              <FiChevronDown className={`h-4 w-4 transition-transform duration-200 ${moreMenuOpen ? "rotate-180" : ""}`} />
            </button>

            {moreMenuOpen && (
              <div
                id="modules-dropdown"
                className="absolute left-0 mt-2 w-64 rounded-lg bg-neutral-800 border border-neutral-700/50 shadow-lg py-1"
              >
                {navigation.map((item) => {
                  if (
                    item.adminOnly &&
                    (!user || (user.role !== "admin" && user.role !== "super_admin"))
                  ) {
                    return null;
                  }

                  const handleClick = (e: React.MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(item.href);
                  };

                  return (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={handleClick}
                      className={`
                        block w-full flex items-center px-4 py-2 text-sm transition-all duration-150 cursor-pointer
                        ${location.pathname === item.href
                          ? "bg-orange-400/10 text-orange-400"
                          : "text-neutral-400 hover:bg-neutral-700/50 hover:text-orange-400"
                        }
                      `}
                    >
                      <item.icon
                        className={`flex-shrink-0 h-4 w-4 mr-3 ${location.pathname === item.href
                          ? "text-orange-400"
                          : "text-neutral-400 group-hover:text-orange-400"
                          }`}
                      />
                      {item.name}
                    </a>
                  );
                })}
              </div>
            )}
          </div>

          {/* Right Actions */}
          <div className="flex items-center ml-auto">
            <UserCreditsDisplay />
          </div>
        </div>
      </div>

      {/* Mobile Menu Button - Only visible on mobile */}
      <div className="lg:hidden fixed bottom-4 right-4 z-50">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="p-3 rounded-full bg-orange-400 text-white shadow-lg hover:bg-orange-500 transition-colors"
        >
          {isMobileMenuOpen ? (
            <FiX className="h-6 w-6" />
          ) : (
            <FiMenu className="h-6 w-6" />
          )}
        </button>
      </div>

      {/* File Explorer Panel */}
      <div
        className={`fixed inset-y-0 z-20 flex transition-all duration-300 ease-in-out hidden lg:flex
          left-0 top-16
          ${isExplorerCollapsed ? "w-6" : ""}
        `}
        style={{
          width: isExplorerCollapsed ? "1.5rem" : `${explorerWidth}px`,
          maxWidth: "80%",
        }}
      >
        {/* Main Panel */}
        <div
          className="relative flex-1 flex flex-col bg-neutral-800/95 backdrop-blur-sm border-r border-neutral-700/50 transition-all duration-300 ease-in-out overflow-hidden"
          style={{
            transform: isExplorerCollapsed
              ? `translateX(-${explorerWidth}px)`
              : "translateX(0)",
          }}
        >
          <div className="flex items-center justify-between h-12 px-4 border-b border-neutral-700/50">
            <span className="text-sm font-medium text-orange-400">
              Explorer
            </span>
            <div className="flex items-center space-x-2">
              <button
                onClick={handleRefresh}
                className="p-1.5 rounded-lg text-neutral-400 hover:text-orange-400 hover:bg-neutral-700/50"
              >
                <FiRefreshCw className="h-4 w-4" />
              </button>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto overflow-x-hidden p-4">
            <TreeView
              data={fileSystem.getFileSystem()}
              onFileSelect={handleFileSelect}
              onSelectionChange={handleSelectionChange}
              refreshTrigger={refreshCounter}
            />
          </div>

          {/* Resize Handle */}
          <div
            className={`absolute right-0 top-0 bottom-0 w-1 cursor-col-resize hover:bg-orange-400/50 ${isResizing ? "bg-orange-400" : ""
              } ${isExplorerCollapsed ? "hidden" : ""}`}
            onMouseDown={handleResizeStart}
          />
        </div>

        {/* Toggle Button */}
        <button
          onClick={() => setExplorerCollapsed(!isExplorerCollapsed)}
          className={`
            flex items-center justify-center w-6 h-12
            bg-neutral-800/95 backdrop-blur-sm 
            border-r border-t border-b border-neutral-700/50 
            text-neutral-400 hover:text-orange-400 hover:bg-neutral-700/50 
            transition-colors
            ${isExplorerCollapsed ? "absolute right-0" : ""}
          `}
        >
          <FiChevronLeft
            className={`h-4 w-4 transition-transform duration-200 ${isExplorerCollapsed ? "rotate-180" : ""
              }`}
          />
        </button>
      </div>

      {/* Main Content Area */}
      <main
        className="fixed inset-x-0 bottom-0 transition-all duration-300 ease-in-out top-16"
        style={{
          left: isExplorerCollapsed ? "1.5rem" : `${explorerWidth}px`,
          right: 0,
          width: isExplorerCollapsed
            ? "calc(100% - 1.5rem)"
            : `calc(100% - ${explorerWidth}px)`,
        }}
      >
        <div className="h-full w-full bg-gradient-to-br from-velo-blue-900/90 via-black to-black overflow-auto">
          <Outlet context={{ selectedNodes, fileSystem, selectedFile }} />
          <ModuleNavigation />
        </div>
      </main>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black/50 z-40 lg:hidden">
          <div className="fixed inset-y-0 right-0 w-64 bg-neutral-800/95 backdrop-blur-sm border-l border-neutral-700/50">
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between h-16 px-4 border-b border-neutral-700/50">
                <span className="text-sm font-medium text-orange-400">Menu</span>
                <button
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="p-2 rounded-lg text-neutral-400 hover:text-orange-400 hover:bg-neutral-700/50"
                >
                  <FiX className="h-5 w-5" />
                </button>
              </div>
              <nav className="flex-1 overflow-y-auto py-4 px-3">
                {renderNavigationItems()}
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;