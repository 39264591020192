import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { TreeNode } from '../services/fileSystemService';
import {
  FaFolder,
  FaFolderOpen,
  FaFile,
  FaFileAlt,
  FaFileCode,
  FaSearch,
  FaEllipsisV,
  FaPencilAlt,
  FaRegCopy,
  FaCheck,
  FaFileExport
} from 'react-icons/fa';

import { JiraExportDialog } from './JiraExportDialog';
import { FiX } from 'react-icons/fi';
import ArtifactDetailsDialog from './ArtifactDetailsDialog';
import TestScenarioDetailsDialog from './TestScenarioDetailsDialog';
import TestCaseDetailsDialog from './TestCaseDetailsDialog';


interface TreeViewProps {
  data: TreeNode;
  onFileSelect?: (node: TreeNode) => void;
  className?: string;
  refreshTrigger?: number;
  onSelectionChange?: (selectedNodes: TreeNode[]) => void;
  initialSelectedNodes?: Set<string>;
}

interface TreeNodeProps {
  data: TreeNode;
  level: number;
  path: string[];
  onFileSelect?: (node: TreeNode) => void;
  onNodeSelect: (node: TreeNode) => void;
  selectedNode: TreeNode | null;
  refreshTrigger: number;
  selectedNodes: Set<string>;
  onNodeCheckChange: (node: TreeNode, level: number, checked: boolean) => void;
  onContextMenu?: (e: React.MouseEvent, node: TreeNode) => void;
  onTextEdit?: (node: TreeNode, path: string[], newText: string) => void;
  formatNodeDisplay?: (node: TreeNode) => string;
  shouldShowNode?: (node: TreeNode) => boolean;
}

const getFileIcon = (fileName: string, type: string) => {
  if (type === 'folder') return FaFolder;
  if (fileName.endsWith('.json')) return FaFileCode;
  if (fileName.endsWith('.txt')) return FaFileAlt;
  return FaFile;
};

const getItemStyle = (type: string, componentType?: string) => {
  if (type === 'folder') {
    if (componentType === 'feature') return 'text-velo-orange-400/90 font-medium';
    if (componentType === 'step-definitions') return 'text-velo-orange-400/90 font-medium';
    if (componentType === 'page-objects') return 'text-velo-orange-400/90 font-medium';
    if (componentType === 'jira-container') return 'text-blue-400/90 font-medium';
    if (componentType === 'jira-requirement') return 'text-blue-400/90 font-medium';
    if (componentType === 'jira-metadata') return 'text-neutral-400/90 font-medium';
    if (componentType === 'jira-content-folder') return 'text-neutral-400/90 font-medium';
    return 'text-velo-orange-400/90 font-medium';
  }

  // Special styles for automation code files
  if (componentType === 'feature-content') return 'text-neutral-300 text-sm';
  if (componentType === 'step-definitions-content') return 'text-neutral-300 text-sm';
  if (componentType === 'page-objects-content') return 'text-neutral-300 text-sm';
  if (componentType === 'jira-content') return 'text-blue-300 text-sm';

  return 'text-neutral-400';
};

const formatContent = (content: any, type: string, name: string, componentType?: string) => {

  // For requirement folders within test scenarios
  if (type === 'folder' && content?.type === 'requirement-folder') {
    return name;
  }

  // For individual test scenarios
  if (type === 'folder' && content?.type === 'test-scenario') {
    return content.fullDescription || name;
  }

  // Hide all technical folders
  if (type === 'folder' && (
    content?.type === 'test-scenario-root' ||
    content?.type === 'test-details-folder' ||
    content?.type === 'description-folder' ||
    content?.type === 'requirements-folder' ||
    content?.type === 'acceptance-criteria-folder' ||
    name === 'Description' ||
    name === 'Acceptance Criteria'
  )) {
    return null;
  }
  // For requirement root folders
  if (type === 'folder' && content?.type === 'requirement-root') {
    return content.fullDescription || name;
  }

  // For regular requirement folders
  if (type === 'folder' && content?.type === 'requirement') {
    return content.fullDescription || name;
  }

  // For folders with content containing full description
  if (type === 'folder' && content?.fullDescription) {
    return content.fullDescription;
  }

  // For Jira requirements folder
  if (componentType === 'jira-requirement') {
    return content.summary || name;
  }

  // For Jira content
  if (componentType === 'jira-content') {
    if (content?.description) {
      return content.description;
    }
    if (Array.isArray(content)) {
      return content.join('\n');
    }
    if (typeof content === 'string') {
      return content;
    }
  }

  if (!content) {
    return name;
  }


  // For automation code folders, show their names
  if (componentType === 'step-definitions' ||
    componentType === 'page-objects' ||
    componentType === 'feature') {
    return name;
  }

  // For automation code content files
  if (componentType?.endsWith('-content')) {
    if (typeof content === 'string') {
      return content;
    }
  }

  // Handle automation code content
  if (typeof content === 'object') {
    if (content.featureFile) return content.featureFile;
    if (content.stepDefinitions) return content.stepDefinitions;
    if (content.pageObjects) return content.pageObjects;
    if (content.requirements) return content.requirements;
    if (content.validationResults) return content.validationResults;
    if (Array.isArray(content)) return content.join('\n');
    if (content.fullDescription) return content.fullDescription;
    if (content.description) return content.description;
    return JSON.stringify(content, null, 2);
  }

  // For strings
  if (typeof content === 'string') {
    return content;
  }

  return name;
};

const getTooltipContent = (data: TreeNode) => {
  if (!data.content) return data.name;

  // Return full description for all content types
  if (data.content.fullDescription) {
    return data.content.fullDescription;
  }

  return data.content?.description || data.name;
};

const getDisplayName = (data: TreeNode) => {
  // For Jira nodes, show the summary
  if (data.componentType === 'jira-requirement') {
    return data.content?.summary || data.name;
  }

  // For content nodes and folders with display names, show the display name
  if (data.content?.displayName) {
    return data.content.displayName;
  }

  return data.name;
};

const collectChildDescriptions = (node: TreeNode): string => {
  // For requirement nodes, return description plus acceptance criteria
  if (node.content?.type === 'requirement') {
    let text = node.content.fullDescription;

    // Find Acceptance Criteria folder
    const acFolder = node.children?.find(child => child.name === "Acceptance Criteria");
    if (acFolder?.children && acFolder.children.length > 0) {
      const criteria = acFolder.children
        .map(child => child.content?.fullDescription)
        .filter(desc => desc)
        .map((desc, index) => `${index + 1}. ${desc}`);
      if (criteria.length > 0) {
        text += "\n\nAcceptance Criteria:\n" + criteria.join("\n");
      }
    }

    return text;
  }

  // For test scenario nodes, return scenario description plus details
  if (node.content?.type === 'test-scenario') {
    let text = node.content.fullDescription;

    // Add Test Details if they exist
    const detailsFolder = node.children?.find(child => child.name === "Test Details");
    if (detailsFolder?.content?.fullDescription) {
      text += "\n\nTest Details:\n" + detailsFolder.content.fullDescription;
    }

    // Add Requirements if they exist
    const reqFolder = node.children?.find(child => child.name === "Requirements");
    if (reqFolder?.content?.fullDescription) {
      text += "\n\nRequirements:\n" + reqFolder.content.fullDescription;
    }

    return text;
  }

  let descriptions: string[] = [];

  // Handle Jira content
  if (node.componentType === 'jira-content') {
    if (node.content?.description) {
      return node.content.description;
    }
  }

  // Handle Jira requirement (parent folder)
  if (node.componentType === 'jira-requirement') {
    const description = node.content?.description || '';
    const children = node.children?.map(child => {
      if (child.content?.description) {
        return child.content.description;
      }
      return '';
    }).filter(Boolean);

    return [description, ...(children || [])].join('\n\n');
  }

  // Add current node's description if it exists
  if (node.content?.fullDescription) {
    descriptions.push(node.content.fullDescription);
  }

  // Special handling for specific folder types
  if (node.name === "Acceptance Criteria" && node.children) {
    const criteria = node.children
      .map(child => child.content?.fullDescription || child.content?.description)
      .filter(desc => desc)
      .map((desc, index) => `${index + 1}. ${desc}`);
    if (criteria.length > 0) {
      return "Acceptance Criteria:\n" + criteria.join("\n");
    }
  }

  if (node.name === "Test Steps" && node.children) {
    const steps = node.children
      .map(child => child.content?.fullDescription || child.content?.description)
      .filter(desc => desc)
      .map((desc, index) => `Step ${index + 1}: ${desc}`);
    if (steps.length > 0) {
      return "Test Steps:\n" + steps.join("\n");
    }
  }

  // Only recurse if not a special folder type and not a requirement/test scenario
  if (node.children &&
    node.name !== "Acceptance Criteria" &&
    node.name !== "Test Steps" &&
    node.content?.type !== 'requirement' &&
    node.content?.type !== 'test-scenario') {
    node.children.forEach(child => {
      const childDesc = collectChildDescriptions(child);
      if (childDesc) {
        descriptions.push(childDesc);
      }
    });
  }

  return descriptions.filter(Boolean).join("\n\n");
};

const getJiraType = (node: TreeNode): string => {
  // Check if this is a Test Scenario folder
  if (node.name.startsWith('TS-') ||
    (node.children?.some(child => child.name === 'Test Details') &&
      node.children?.some(child => child.name === 'Requirements'))) {
    return 'subtask-description';
  }

  // Check if this is a Test Case
  if (node.name.startsWith('TC-') ||
    (node.children?.some(child => child.name === 'Test Steps') &&
      node.children?.some(child => child.name === 'Expected Result'))) {
    return 'bug-description';
  }

  // Check if this is a Requirement
  if (node.name.startsWith('REQ-') ||
    node.children?.some(child => child.name === 'Acceptance Criteria')) {
    return 'user-story';
  }

  // Then check the jiraMetadata if it exists
  if (node.jiraMetadata?.issueType) {
    switch (node.jiraMetadata.issueType.toLowerCase()) {
      case 'sub-task': return 'subtask-description';
      case 'bug': return 'bug-description';
      case 'story': return 'user-story';
      default: return node.jiraMetadata.issueType;
    }
  }

  // Finally fall back to component type or node type
  return node.componentType || node.type || 'user-story';
};

const DropdownMenu: React.FC<{ data: TreeNode }> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleExport = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowExportDialog(true);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        className="p-1 rounded hover:bg-black/20"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <FaEllipsisV className="w-3 h-3" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-1 w-48 rounded-md bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="py-1">
            <button
              className="text-gray-300 hover:bg-gray-700 hover:text-white group flex w-full items-center px-4 py-2 text-sm"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              Open
            </button>
            {data.componentType !== 'jira-requirement' && (
              <button
                className="text-gray-300 hover:bg-gray-700 hover:text-white group flex w-full items-center px-4 py-2 text-sm"
                onClick={handleExport}
              >
                Export to Jira
              </button>
            )}
          </div>
        </div>
      )}

      {showExportDialog && (
        <JiraExportDialog
          isOpen={showExportDialog}
          onClose={() => setShowExportDialog(false)}
          items={[{
            id: data.id,
            name: data.content?.fullDescription || data.name,
            type: getJiraType(data),
            content: collectChildDescriptions(data)
          }]}
        />
      )}
    </div>
  );
};

const TreeNodeComponent: React.FC<TreeNodeProps> = React.memo(({
  data,
  level,
  path,
  onFileSelect,
  onNodeSelect,
  selectedNode,
  refreshTrigger,
  selectedNodes,
  onNodeCheckChange,
  onContextMenu,
  onTextEdit,
  formatNodeDisplay,
  shouldShowNode,
}) => {
  const [isOpen, setIsOpen] = useState(level < 2);
  const [isDragging, setIsDragging] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState('');
  const [showExportDialog, setShowExportDialog] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Auto-resize textarea
  useEffect(() => {
    if (textareaRef.current && isEditing) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  }, [editText, isEditing]);

  if (shouldShowNode && !shouldShowNode(data)) {
    return null;
  }

  // Function to get display text
  const getDisplayText = () => {
    if (formatNodeDisplay) {
      return formatNodeDisplay(data);
    }
    if (data.content?.fullDescription) {
      return data.content.fullDescription;
    }
    return getDisplayName(data);
  };

  // Handle edit mode activation
  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(true);
    setEditText(getDisplayText());
    // Focus and select all text in textarea after render
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
        textareaRef.current.select();
      }
    }, 0);
  };

  // Handle edit save
  const handleEditSave = () => {
    if (onTextEdit) {
      onTextEdit(data, path, editText);
    }
    setIsEditing(false);
  };

  // Handle edit cancel
  const handleEditCancel = () => {
    setIsEditing(false);
    setEditText('');
  };

  return (
    <div className="select-none">
      <div
        className={`flex items-center gap-1 py-1 px-2 rounded cursor-pointer text-gray-300 hover:bg-black/20 ${selectedNode?.id === data.id
          ? "bg-black/30"
          : ""
          }`}
        style={{
          paddingLeft: `${level * 20}px`,
          whiteSpace: 'normal',
          wordBreak: 'break-word'
        }}
        onClick={() => {
          if (!isEditing) {
            onNodeSelect(data);
            if (data.type === 'file' && onFileSelect) {
              onFileSelect(data);
            }
            if (data.type === 'folder') {
              setIsOpen(!isOpen);
            }
          }
        }}
        draggable={!isEditing}
        onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
          if (!isEditing) {
            setIsDragging(true);
            let dragText = '';

            if (data.content?.type === 'test-case') {
              const testCaseData = data.content?.content || {};
              dragText = `Test Case ID: ${testCaseData.id || 'N/A'}\n` +
                `Test Scenario ID: ${testCaseData.testScenarioId || 'N/A'}\n` +
                `Description: ${testCaseData.description || 'N/A'}\n` +
                `Preconditions: ${testCaseData.preconditions || 'N/A'}\n` +
                `Steps:\n${Array.isArray(testCaseData.steps) ? testCaseData.steps.map((step: string, i: number) => `${i + 1}. ${step}`).join('\n') : 'N/A'}\n` +
                `Test Data: ${testCaseData.testData || 'N/A'}\n` +
                `Expected Results: ${testCaseData.expectedResults || 'N/A'}\n` +
                `Actual Results: ${testCaseData.actualResults || 'N/A'}\n` +
                `Status: ${testCaseData.status || 'N/A'}\n` +
                `Comments: ${testCaseData.comments || 'N/A'}`;
            } else {
              dragText = collectChildDescriptions(data);
            }

            e.dataTransfer.setData('text/plain', dragText || data.name);
          }
        }}
        onDragEnd={() => setIsDragging(false)}
      >
        <div className="flex items-center gap-1 min-w-0 flex-1">
          {/* Checkbox */}
          <div className="flex-shrink-0 mr-1.5">
            <input
              type="checkbox"
              className="w-3 h-3 rounded-sm border-neutral-600 bg-neutral-800/80 text-velo-orange-400 focus:ring-1 focus:ring-velo-orange-400/30 focus:ring-offset-0"
              checked={selectedNodes.has(`${data.id}-${data.name}-${level}`)}
              onChange={(e) => {
                e.stopPropagation();
                onNodeCheckChange(data, level, e.target.checked);
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </div>

          {/* Icon */}
          <div className="flex-shrink-0 w-4 h-4 mr-1.5">
            {React.createElement(getFileIcon(data.name, data.type), {
              className: `flex-shrink-0 w-3.5 h-3.5 ${data.type === 'folder' ? 'mr-1' : 'mr-1.5'} ${getItemStyle(data.type, data.componentType)}`
            })}
          </div>

          {/* Text Content */}
          <div className="break-words flex-1" style={{ minWidth: 0 }}>
            {isEditing ? (
              <div className="flex flex-col space-y-2 py-2" onClick={e => e.stopPropagation()}>
                <textarea
                  ref={textareaRef}
                  value={editText}
                  onChange={(e) => setEditText(e.target.value)}
                  className="w-full bg-neutral-800 text-neutral-200 text-sm p-2 rounded border border-neutral-600 focus:border-velo-orange-400 focus:ring-1 focus:ring-velo-orange-400/30"
                  rows={1}
                  style={{ resize: 'none' }}
                />
                <div className="flex space-x-2">
                  <button
                    onClick={handleEditSave}
                    className="px-2 py-1 text-xs rounded bg-velo-orange-500 text-white hover:bg-velo-orange-600 transition-colors"
                  >
                    Save
                  </button>
                  <button
                    onClick={handleEditCancel}
                    className="px-2 py-1 text-xs rounded bg-neutral-600 text-white hover:bg-neutral-700 transition-colors"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="group/text relative">
                <div className={`text-sm whitespace-pre-wrap break-words ${getItemStyle(data.type, data.componentType)}`}>
                  {getDisplayText()}
                </div>
                {!data.componentType?.includes('jira-') && (
                  <div className="absolute right-2 top-1/2 -translate-y-1/2 opacity-0 group-hover/text:opacity-100 transition-opacity flex items-center space-x-2">
                    <button
                      onClick={handleEditClick}
                      className="p-1 rounded hover:bg-neutral-600/30"
                    >
                      <FaPencilAlt className="w-3 h-3 text-neutral-400" />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowExportDialog(true);
                      }}
                      className="p-1 rounded hover:bg-neutral-600/30"
                    >
                      <FaFileExport className="w-3 h-3 text-neutral-400" />
                    </button>
                  </div>
                )}
              </div>
            )}

            {/* Tooltip */}
            <div className="absolute left-0 -top-1 translate-y--100 hidden group-hover/item:block z-50 pointer-events-none">
              <div className="bg-neutral-800 text-neutral-200 text-sm py-1 px-2 rounded shadow-lg border border-neutral-700/50 whitespace-pre-wrap max-w-md">
                {getTooltipContent(data)}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
          <DropdownMenu data={data} />
        </div>
      </div>

      {/* Children */}
      {data.children && data.children.length > 0 && isOpen && (
        <div className="ml-4">
          {data.children.map((child, index) => (
            <TreeNodeComponent
              key={`${child.id}-${level + 1}-${index}`}
              data={child}
              level={level + 1}
              path={[...path, child.name]}
              onFileSelect={onFileSelect}
              onNodeSelect={onNodeSelect}
              selectedNode={selectedNode}
              refreshTrigger={refreshTrigger}
              selectedNodes={selectedNodes}
              onNodeCheckChange={onNodeCheckChange}
              onContextMenu={onContextMenu}
              onTextEdit={onTextEdit}
              formatNodeDisplay={formatNodeDisplay}
              shouldShowNode={shouldShowNode}
            />
          ))}
        </div>
      )}

      {showExportDialog && (
        <JiraExportDialog
          isOpen={showExportDialog}
          onClose={() => setShowExportDialog(false)}
          items={[{
            id: data.id,
            name: data.content?.fullDescription || data.name,
            type: getJiraType(data),
            content: collectChildDescriptions(data)
          }]}
        />
      )}
    </div>
  );
});

const TreeView: React.FC<TreeViewProps> = React.memo(({
  data,
  onFileSelect,
  className = '',
  refreshTrigger = 0,
  onSelectionChange,
  initialSelectedNodes = new Set<string>(),
}) => {
  const [selectedNode, setSelectedNode] = useState<TreeNode | null>(null);
  const [selectedNodes, setSelectedNodes] = useState<Set<string>>(initialSelectedNodes);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [treeData, setTreeData] = useState<TreeNode>(data);
  const [showCopyToast, setShowCopyToast] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [testScenarioDialogOpen, setTestScenarioDialogOpen] = useState(false);
  const [testCaseDialogOpen, setTestCaseDialogOpen] = useState(false);
  const [selectedArtifact, setSelectedArtifact] = useState<TreeNode | null>(null);


  const handleNodeSelect = useCallback((node: TreeNode) => {
    // Don't open dialog for root folders
    if (node.content?.type === 'test-case-root' ||
      node.content?.type === 'test-case-folder') {
      return;
    }

    setSelectedNode(node);
    if (node.content?.type === 'requirement') {
      setSelectedArtifact(node);
      setDialogOpen(true);
    } else if (node.content?.type === 'test-scenario') {
      setSelectedArtifact(node);
      setTestScenarioDialogOpen(true);
    } else if (node.content?.type === 'test-case') {
      setSelectedArtifact(node);
      setTestCaseDialogOpen(true);
    }
  }, []);

  const handleSaveArtifact = useCallback((updatedArtifact: TreeNode) => {
    // Here you would typically update the artifact in your backend
  }, []);

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
    setTestScenarioDialogOpen(false);
    setTestCaseDialogOpen(false);
  }, []);

  const handleSave = useCallback((updatedNode: TreeNode) => {
    // Update the node in the tree
    const updateNodeInTree = (nodes: TreeNode[]): TreeNode[] => {
      return nodes.map(node => {
        if (node.id === updatedNode.id) {
          return {
            ...updatedNode,
            children: node.children // Preserve existing children
          };
        }
        if (node.children) {
          return {
            ...node,
            children: updateNodeInTree(node.children)
          };
        }
        return node;
      });
    };

    // Create a new copy of the data to trigger re-render
    const newData = {
      ...data,
      children: data.children ? updateNodeInTree([...data.children]) : []
    };

    // Update the data reference
    Object.assign(data, newData);

    setSelectedNode(null);
    handleDialogClose();
  }, [data, handleDialogClose]);

  const shouldShowNode = useCallback((node: TreeNode) => {
    // Hide all technical folders
    if (node.content?.type === 'test-scenario-root' ||
      node.content?.type === 'test-details-folder' ||
      node.content?.type === 'description-folder' ||
      node.content?.type === 'requirements-folder' ||
      node.content?.type === 'acceptance-criteria-folder' ||
      node.content?.type === 'test-case-details-folder' ||
      node.content?.type === 'preconditions-folder' ||
      node.content?.type === 'steps-folder' ||
      node.content?.type === 'test-data-folder' ||
      node.content?.type === 'expected-results-folder' ||
      node.content?.type === 'actual-results-folder' ||
      node.content?.type === 'status-folder' ||
      node.content?.type === 'comments-folder' ||
      node.name === 'Description' ||
      node.name === 'Acceptance Criteria' ||
      node.name === 'Test Details' ||
      node.name === 'Preconditions' ||
      node.name === 'Steps' ||
      node.name === 'Test Data' ||
      node.name === 'Expected Results' ||
      node.name === 'Actual Results' ||
      node.name === 'Status' ||
      node.name === 'Comments') {
      return false;
    }

    // Show requirement folders and test scenarios/cases
    if (node.content?.type === 'requirement-folder' ||
      node.content?.type === 'test-scenario' ||
      node.content?.type === 'test-case') {
      return true;
    }

    return true;
  }, []);

  const renderNode = useCallback((node: TreeNode, level: number, path: string[]) => {
    // Skip rendering the test-scenario-root node but render its children
    if (node.content?.type === 'test-scenario-root' && node.children) {
      return (
        <React.Fragment key={node.id || path.join('/')}>
          {node.children.map((child, index) =>
            renderNode(child, level, [...path, child.name])
          )}
        </React.Fragment>
      );
    }

    if (!shouldShowNode(node)) {
      return null;
    }

    const nodeContent = formatContent(node.content, node.type, node.name, node.componentType);
    if (!nodeContent) {
      return null;
    }

    return (
      <TreeNodeComponent
        key={node.id || path.join('/')}
        data={node}
        level={level}
        path={path}
        onFileSelect={onFileSelect}
        onNodeSelect={handleNodeSelect}
        selectedNode={selectedNode}
        refreshTrigger={refreshTrigger}
        selectedNodes={selectedNodes}
        onNodeCheckChange={(node, level, checked) => {
          const nodeId = `${node.id}-${node.name}-${level}`;
          setSelectedNodes(prev => {
            const newSet = new Set(prev);
            if (checked) {
              newSet.add(nodeId);
            } else {
              newSet.delete(nodeId);
            }
            return newSet;
          });
        }}
        formatNodeDisplay={(node) => {
          if (node.content?.fullDescription) {
            return node.content.fullDescription;
          }
          return getDisplayName(node);
        }}
        shouldShowNode={shouldShowNode}
      />
    );
  }, [selectedNode, handleNodeSelect, selectedNodes, refreshTrigger, shouldShowNode]);

  // Update the memoized selected nodes array for external components
  const selectedNodesArray = useMemo(() => {
    return Array.from(selectedNodes).map(nodeId => {
      const [id, name, level] = nodeId.split('-');
      return {
        id,
        name,
        type: 'file',
        content: '',
        children: [],
        level: parseInt(level, 10)
      } as TreeNode;
    });
  }, [selectedNodes]);

  // Notify parent components of selection changes
  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedNodesArray);
    }
  }, [selectedNodesArray, onSelectionChange]);



  const handleBulkExport = useCallback(() => {
    const selectedNodesForExport = Array.from(selectedNodes).map(nodeId => {
      const [id] = nodeId.split('-');
      // Find the actual node in the tree
      const findNode = (node: TreeNode): TreeNode | null => {
        if (node.id === id) return node;
        if (node.children) {
          for (const child of node.children) {
            const found = findNode(child);
            if (found) return found;
          }
        }
        return null;
      };
      const node = findNode(treeData);
      if (!node) return null;
      return {
        id: node.id,
        name: node.content?.fullDescription || node.name,
        type: getJiraType(node),
        content: collectChildDescriptions(node)
      };
    }).filter((item): item is NonNullable<typeof item> => item !== null);

    if (selectedNodesForExport.length > 0) {
      setShowExportDialog(true);
    }
  }, [selectedNodes, treeData]);

  // Function to get node by path
  const getNodeByPath = useCallback((nodePath: string[]): TreeNode | null => {
    let current = treeData;
    for (const name of nodePath.slice(1)) { // Skip root name
      const child = current.children?.find(c => c.name === name);
      if (!child) return null;
      current = child;
    }
    return current;
  }, [treeData]);

  // Function to copy selected nodes
  const handleCopySelected = useCallback(() => {
    const selectedTexts = Array.from(selectedNodes).map(nodeId => {
      const [id, name, level] = nodeId.split('-');
      const path = name.split('/');
      const node = getNodeByPath(path);
      if (node) {
        return collectChildDescriptions(node);
      }
      return null;
    }).filter(Boolean);

    if (selectedTexts.length > 0) {
      navigator.clipboard.writeText(selectedTexts.join('\n\n'));
      setShowCopyToast(true);
      setTimeout(() => setShowCopyToast(false), 2000);
    }
  }, [selectedNodes, getNodeByPath]);



  return (
    <div className={`w-full h-full overflow-auto ${className}`}>
      {data.children?.map((child, index) => (
        renderNode(child, 0, [child.name])
      ))}
      <ArtifactDetailsDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        artifact={selectedArtifact}
        onSave={handleSaveArtifact}
      />
      <TestScenarioDetailsDialog
        open={testScenarioDialogOpen}
        onClose={handleDialogClose}
        artifact={selectedArtifact}
        onSave={handleSave}
      />
      {testCaseDialogOpen && (
        <TestCaseDetailsDialog
          open={testCaseDialogOpen}
          onClose={handleDialogClose}
          artifact={selectedArtifact}
          onSave={handleSave}
        />
      )}
    </div>
  );
});

// Helper function to compare sets
const areSetsEqual = (a: Set<string>, b: Set<string>) => {
  if (a.size !== b.size) return false;
  return Array.from(a).every(item => b.has(item));
};

export default TreeView;