import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from './shared/GlobalContext';
import { generateTestScenarios, generateTestCases, generateAutomationCode, TestScenario } from '../services/aiService';
import logo from './veloai-logo.png';

interface ComponentTestCase {
  id: string;
  description: string;
  preconditions: string[];
  steps: string[];
  expectedResults: string[];
  testData?: string[];
  automationStatus: 'Not Started' | 'In Progress' | 'Automated' | 'Failed';
  coverage?: number;
  automationCode?: AutomationCode;
}

interface AITestCase {
  TestCaseID: string;
  ScenarioID?: string;
  TestCaseDescription: string;
  Preconditions: string;
  TestSteps: string[];
  ExpectedResult: string;
  TestData: string | string[] | Record<string, any>;
}

interface AutomationCode {
  featureFile?: string;
  stepDefinitions?: string;
  pageObjects?: string;
}

interface AutomationResult {
  id: string;
  code: AutomationCode;
}

function IntegratedTestingFlow() {
  const { contextData } = useContext(GlobalContext);
  const [requirements, setRequirements] = useState('');
  const [scenarios, setScenarios] = useState<any[]>([]);
  const [testCases, setTestCases] = useState<ComponentTestCase[]>([]);
  const [expandedScenarios, setExpandedScenarios] = useState<Record<string, boolean>>({});
  const [expandedTestCases, setExpandedTestCases] = useState<Record<string, boolean>>({});
  const [generatingScenarios, setGeneratingScenarios] = useState(false);
  const [generatingTestCases, setGeneratingTestCases] = useState(false);
  const [generatingAutomation, setGeneratingAutomation] = useState(false);
  const [automationLanguage, setAutomationLanguage] = useState('python');
  const [automationFramework, setAutomationFramework] = useState('selenium');

  // Function to extract requirement and scenario numbers from scenario ID
  const parseScenarioId = (scenarioId: string) => {
    const reqMatch = scenarioId.match(/REQ-(\d+)/);
    const tsMatch = scenarioId.match(/TS-(\d+)/);
    return {
      reqNum: reqMatch ? reqMatch[1] : '1',
      tsNum: tsMatch ? tsMatch[1] : '1'
    };
  };

  // Function to deduplicate test cases
  const deduplicateTestCases = (testCases: ComponentTestCase[]): ComponentTestCase[] => {
    const seen = new Set<string>();
    return testCases.filter(tc => {
      if (seen.has(tc.id)) {
        return false;
      }
      seen.add(tc.id);
      return true;
    });
  };

  // Map test cases to scenarios
  const getTestCasesForScenario = (scenarioId: string, testCases: ComponentTestCase[]): ComponentTestCase[] => {
    const { reqNum, tsNum } = parseScenarioId(scenarioId);
    const prefix = `REQ-${reqNum}-TS-${tsNum}`;

    // First deduplicate the test cases
    const uniqueTestCases = deduplicateTestCases(testCases);

    // Find test cases that match this scenario's prefix
    return uniqueTestCases.filter(tc => tc.id.startsWith(prefix));
  };

  const convertAITestCaseToComponentTestCase = (aiTestCase: AITestCase, scenarioId: string): ComponentTestCase => {
    const { reqNum, tsNum } = parseScenarioId(scenarioId);
    const tcMatch = aiTestCase.TestCaseID.match(/TC-(\d+)/);
    const tcNum = tcMatch ? tcMatch[1].padStart(3, '0') : '001';

    return {
      id: `REQ-${reqNum}-TS-${tsNum}-TC-${tcNum}`,
      description: aiTestCase.TestCaseDescription,
      preconditions: [aiTestCase.Preconditions],
      steps: aiTestCase.TestSteps,
      expectedResults: [aiTestCase.ExpectedResult],
      testData: Array.isArray(aiTestCase.TestData)
        ? aiTestCase.TestData
        : [typeof aiTestCase.TestData === 'string'
          ? aiTestCase.TestData
          : JSON.stringify(aiTestCase.TestData)],
      automationStatus: 'Not Started',
      coverage: 0,
      automationCode: undefined
    };
  };

  // Helper function to get statistics for a scenario
  const getScenarioStatistics = (scenarioId: string) => {
    const scenarioTestCases = testCases.filter(tc => tc.id.startsWith(scenarioId));
    return {
      totalTestCases: scenarioTestCases.length,
      automatedTestCases: scenarioTestCases.filter(tc => tc.automationStatus === 'Automated').length,
      failedTestCases: scenarioTestCases.filter(tc => tc.automationStatus === 'Failed').length
    };
  };

  // Handle automated generation of test artifacts
  const handleGenerateAll = async () => {
    setGeneratingScenarios(true);
    setGeneratingTestCases(true);
    setGeneratingAutomation(true);

    try {
      // Build context string
      const contextString = `
Business Context:
${contextData.businessContext}
Technical Context:
${contextData.technicalContext}
Constraints:
${contextData.constraints}
Assumptions:
${contextData.assumptions}
Diagrams:
${Object.entries(contextData.diagrams).map(([fileName, content]) => `${fileName}: ${content}`).join('\n')}
Documents:
${Object.entries(contextData.documents).map(([fileName, content]) => `${fileName}: ${content}`).join('\n')}
      `.trim();

      // Generate test scenarios
      const scenariosResult = await generateTestScenarios(requirements, contextString);
      // Limit to 5 scenarios for testing
      const limitedScenarios = scenariosResult.scenarios.slice(0, 5);

      setScenarios(limitedScenarios);

      // Generate test cases for each scenario
      let allTestCases: ComponentTestCase[] = [];
      for (const scenario of limitedScenarios) {
        try {
          const response = await generateTestCases(JSON.stringify(scenario), contextString);
          const caseResults = typeof response === 'string' ? JSON.parse(response) : response;

          if (caseResults && typeof caseResults === 'object') {
            let testCases = caseResults.TestCases;
            if (!testCases && Array.isArray(caseResults)) {
              testCases = caseResults;
            }

            if (Array.isArray(testCases)) {
              // Limit to 5 test cases per scenario for testing
              const limitedTestCases = testCases.slice(0, 5);

              const convertedTestCases = limitedTestCases.map((tc: AITestCase) => convertAITestCaseToComponentTestCase(tc, scenario["Scenario ID"]));
              allTestCases = [...allTestCases, ...convertedTestCases];
            } else {
              console.error('Test cases are not in array format:', testCases);
            }
          } else {
            console.error('Invalid test case results format:', caseResults);
          }
        } catch (error) {
          console.error('Error generating test cases for scenario:', scenario, error);
        }
      }
      console.log('Final test cases:', allTestCases);

      setTestCases(allTestCases);

      // Generate automation code
      try {
        // Prepare a simplified version of test cases for automation
        const automationTestCases = allTestCases.map(tc => ({
          id: tc.id,
          description: tc.description,
          steps: tc.steps,
          expectedResults: tc.expectedResults,
          testData: tc.testData
        }));
        console.log('Generating automation code for test cases:', automationTestCases.length);
        const automationResults: AutomationResult[] = await Promise.all(
          allTestCases.map(async (tc) => {
            const response = await generateAutomationCode(
              JSON.stringify({
                testCase: tc,
                language: automationLanguage,
                framework: automationFramework
              }),
              '', // Changed from undefined to empty string
              automationLanguage,
              automationFramework
            );
            return {
              id: tc.id,
              code: typeof response === 'string'
                ? { featureFile: response }
                : response as AutomationCode
            };
          })
        );
        console.log('Automation code generated:', automationResults);

        // Update test cases with automation status and code
        const updatedTestCases: ComponentTestCase[] = allTestCases.map(tc => ({
          ...tc,
          automationStatus: 'Automated' as const,
          automationCode: automationResults.find((ar: AutomationResult) => ar.id === tc.id)?.code
        }));
        setTestCases(updatedTestCases);
      } catch (error) {
        console.error('Error generating automation code:', error);
        // Update test cases with failed automation status
        const updatedTestCases: ComponentTestCase[] = allTestCases.map(tc => ({
          ...tc,
          automationStatus: 'Failed' as const
        }));
        setTestCases(updatedTestCases);
      }
    } catch (error) {
      console.error('Error in test artifact generation:', error);
    } finally {
      setGeneratingScenarios(false);
      setGeneratingTestCases(false);
      setGeneratingAutomation(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <img 
              src={logo} 
              alt="VeloAI Logo" 
              className="h-6 w-auto filter drop-shadow-glow" 
            />
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">Testing Tools</div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Integrated Testing Flow
              </h1>
            </div>
          </div>
          <Link 
            to="/" 
            className="group flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
          >
            <span>Return to Dashboard</span>
            <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">→</span>
          </Link>
        </div>

        {/* Requirements Input Section */}
        <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 overflow-hidden shadow-xl">
          <div className="px-8 py-6">
            <label className="block text-sm font-medium text-velo-orange-500/80 mb-2">Requirements</label>
            <textarea
              value={requirements}
              onChange={(e) => setRequirements(e.target.value)}
              placeholder="Enter project requirements..."
              className="w-full bg-black/20 border border-velo-orange-500/20 rounded-lg text-white placeholder-gray-500 px-4 py-3 focus:ring-2 focus:ring-velo-orange-500/50 focus:border-transparent focus:outline-none transition duration-200"
              rows={4}
            />
            <div className="flex flex-col space-y-2">
              <button
                onClick={handleGenerateAll}
                disabled={generatingScenarios || generatingTestCases || generatingAutomation}
                className={`
                  group relative px-8 py-4 rounded-xl font-medium text-base
                  ${generatingScenarios || generatingTestCases || generatingAutomation
                    ? 'bg-velo-blue-900/50 cursor-not-allowed' 
                    : 'bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500'
                  }
                  text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
                  transform hover:-translate-y-0.5
                `}
              >
                <span className="flex items-center space-x-2">
                  {generatingScenarios || generatingTestCases || generatingAutomation ? (
                    <>
                      <span className="opacity-70">Generating Test Artifacts</span>
                      <span className="animate-pulse">...</span>
                    </>
                  ) : (
                    <>
                      <span>Generate Test Artifacts</span>
                      <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                        →
                      </span>
                    </>
                  )}
                </span>
              </button>
              <div className="flex space-x-2">
                <select
                  value={automationLanguage}
                  onChange={(e) => setAutomationLanguage(e.target.value)}
                  className="w-full bg-black/20 border border-velo-orange-500/20 rounded-lg text-white px-4 py-2 focus:ring-2 focus:ring-velo-orange-500/50 focus:border-transparent focus:outline-none transition duration-200"
                >
                  <option value="python">Python</option>
                  <option value="java">Java</option>
                  <option value="javascript">JavaScript</option>
                  <option value="csharp">C#</option>
                </select>
                <select
                  value={automationFramework}
                  onChange={(e) => setAutomationFramework(e.target.value)}
                  className="w-full bg-black/20 border border-velo-orange-500/20 rounded-lg text-white px-4 py-2 focus:ring-2 focus:ring-velo-orange-500/50 focus:border-transparent focus:outline-none transition duration-200"
                >
                  <option value="selenium">Selenium</option>
                  <option value="cypress">Cypress</option>
                  <option value="playwright">Playwright</option>
                  <option value="puppeteer">Puppeteer</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Scenarios Section */}
        <div className="space-y-4 mt-8">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium text-velo-orange-500/80 mb-4">Generated Scenarios</h3>
            <div className="flex space-x-4 text-sm text-gray-400">
              <div className="flex items-center space-x-2">
                <div className="w-2 h-2 rounded-full bg-velo-orange-500"></div>
                <span>Total Scenarios: {scenarios.length}</span>
              </div>
              {testCases.length > 0 && (
                <>
                  <div className="flex items-center space-x-2">
                    <div className="w-2 h-2 rounded-full bg-green-500"></div>
                    <span>Automated: {testCases.filter(tc => tc.automationStatus === 'Automated').length}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className="w-2 h-2 rounded-full bg-red-500"></div>
                    <span>Failed: {testCases.filter(tc => tc.automationStatus === 'Failed').length}</span>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Scenarios List */}
          <div className="space-y-4">
            {scenarios.map((scenario, index) => {
              const scenarioId = scenario["Scenario ID"];
              const scenarioTestCases = getTestCasesForScenario(scenarioId, testCases);
              const stats = getScenarioStatistics(scenarioId);
              
              return (
                <div 
                  key={scenarioId} 
                  className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-orange-500/20 overflow-hidden shadow-xl"
                >
                  {/* Scenario Header */}
                  <div 
                    className="px-6 py-4 cursor-pointer hover:bg-velo-blue-900/20 transition-colors duration-200"
                    onClick={() => setExpandedScenarios(prev => ({ ...prev, [scenarioId]: !prev[scenarioId] }))}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <h4 className="text-lg font-semibold text-white">
                          {scenario["Scenario ID"]}: {scenario["Scenario Title"]}
                        </h4>
                        <p className="text-gray-400 text-sm mt-1">
                          {scenario["Description"]}
                        </p>
                      </div>
                      <div className="flex items-center space-x-4">
                        <div className="flex space-x-2 text-sm">
                          <span className="text-gray-400">Test Cases: {stats.totalTestCases}</span>
                          {stats.automatedTestCases > 0 && (
                            <span className="text-green-400">Automated: {stats.automatedTestCases}</span>
                          )}
                          {stats.failedTestCases > 0 && (
                            <span className="text-red-400">Failed: {stats.failedTestCases}</span>
                          )}
                        </div>
                        <span className={`transform transition-transform duration-200 ${
                          expandedScenarios[scenarioId] ? 'rotate-180' : ''
                        }`}>
                          ▼
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Test Cases */}
                  {expandedScenarios[scenarioId] && (
                    <div className="border-t border-velo-orange-500/20 divide-y divide-velo-orange-500/20">
                      {scenarioTestCases.map(testCase => (
                        <div key={testCase.id} className="p-4 bg-velo-blue-900/5">
                          <div 
                            className="cursor-pointer"
                            onClick={() => setExpandedTestCases(prev => ({ ...prev, [testCase.id]: !prev[testCase.id] }))}
                          >
                            <div className="flex justify-between items-center">
                              <div>
                                <h5 className="font-medium text-white">
                                  {testCase.id}
                                </h5>
                                <p className="text-sm text-gray-400 mt-1">
                                  {testCase.description}
                                </p>
                              </div>
                              <div className="flex items-center space-x-4">
                                <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                                  testCase.automationStatus === 'Automated'
                                    ? 'bg-green-500/20 text-green-400'
                                    : testCase.automationStatus === 'Failed'
                                    ? 'bg-red-500/20 text-red-400'
                                    : 'bg-gray-500/20 text-gray-400'
                                }`}>
                                  {testCase.automationStatus}
                                </span>
                                <span className={`transform transition-transform duration-200 ${
                                  expandedTestCases[testCase.id] ? 'rotate-180' : ''
                                }`}>
                                  ▼
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* Test Case Details */}
                          {expandedTestCases[testCase.id] && (
                            <div className="mt-4 pl-4 space-y-4">
                              {/* Preconditions */}
                              {testCase.preconditions.length > 0 && (
                                <div>
                                  <h6 className="text-sm font-medium text-gray-400 mb-2">Preconditions</h6>
                                  <ul className="list-disc list-inside text-sm text-gray-300 space-y-1">
                                    {testCase.preconditions.map((precondition, i) => (
                                      <li key={i}>{precondition}</li>
                                    ))}
                                  </ul>
                                </div>
                              )}

                              {/* Steps */}
                              <div>
                                <h6 className="text-sm font-medium text-gray-400 mb-2">Test Steps</h6>
                                <ol className="list-decimal list-inside text-sm text-gray-300 space-y-1">
                                  {testCase.steps.map((step, i) => (
                                    <li key={i}>{step}</li>
                                  ))}
                                </ol>
                              </div>

                              {/* Expected Results */}
                              <div>
                                <h6 className="text-sm font-medium text-gray-400 mb-2">Expected Results</h6>
                                <ul className="list-disc list-inside text-sm text-gray-300 space-y-1">
                                  {testCase.expectedResults.map((result, i) => (
                                    <li key={i}>{result}</li>
                                  ))}
                                </ul>
                              </div>

                              {/* Test Data */}
                              {testCase.testData && testCase.testData.length > 0 && (
                                <div>
                                  <h6 className="text-sm font-medium text-gray-400 mb-2">Test Data</h6>
                                  <ul className="list-disc list-inside text-sm text-gray-300 space-y-1">
                                    {testCase.testData.map((data, i) => (
                                      <li key={i}>{data}</li>
                                    ))}
                                  </ul>
                                </div>
                              )}

                              {/* Automation Code */}
                              {testCase.automationCode && (
                                <div>
                                  <h6 className="text-sm font-medium text-gray-400 mb-2">Automation Code</h6>
                                  <div className="space-y-4">
                                    {testCase.automationCode.featureFile && (
                                      <div>
                                        <h6 className="text-xs font-medium text-gray-500 mb-1">Feature File</h6>
                                        <pre className="bg-black/30 rounded-lg p-4 text-xs text-gray-300 overflow-x-auto">
                                          {testCase.automationCode.featureFile}
                                        </pre>
                                      </div>
                                    )}
                                    {testCase.automationCode.stepDefinitions && (
                                      <div>
                                        <h6 className="text-xs font-medium text-gray-500 mb-1">Step Definitions</h6>
                                        <pre className="bg-black/30 rounded-lg p-4 text-xs text-gray-300 overflow-x-auto">
                                          {testCase.automationCode.stepDefinitions}
                                        </pre>
                                      </div>
                                    )}
                                    {testCase.automationCode.pageObjects && (
                                      <div>
                                        <h6 className="text-xs font-medium text-gray-500 mb-1">Page Objects</h6>
                                        <pre className="bg-black/30 rounded-lg p-4 text-xs text-gray-300 overflow-x-auto">
                                          {testCase.automationCode.pageObjects}
                                        </pre>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntegratedTestingFlow;