import React, { useState, useEffect } from 'react';
import { FileSystemService, TreeNode } from '../services/fileSystemService';
import { jiraService } from '../services/jiraService';
import { FiCheck, FiAlertCircle, FiChevronRight, FiAlertTriangle } from 'react-icons/fi';

interface ExportItem extends TreeNode {
    selected: boolean;
    status?: 'pending' | 'success' | 'error';
    parentKey?: string;
}

interface ExportSection {
    title: string;
    items: ExportItem[];
}

interface ConsolidatedContent {
    mainDescription: string;
    acceptanceCriteria: string[];
    exportDescription: string;
    source: 'internal' | 'jira';
    relationships: {
        parentId: undefined;
        linkedItems: [];
    };
    lastModified: string;
}

type ExportMode = 'new' | 'existing';

export const JiraExportManager: React.FC = () => {
    const [sections, setSections] = useState<ExportSection[]>([]);
    const [selectedNode, setSelectedNode] = useState<ExportItem | null>(null);
    const [projects, setProjects] = useState<Array<{ key: string; name: string }>>([]);
    const [selectedProject, setSelectedProject] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [exportMode, setExportMode] = useState<ExportMode>('new');
    const [existingRequirements, setExistingRequirements] = useState<Array<{ key: string; summary: string }>>([]);
    const [selectedRequirement, setSelectedRequirement] = useState<string>('');

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (selectedProject && exportMode === 'existing') {
            loadExistingRequirements();
        }
    }, [selectedProject, exportMode]);

    const loadExistingRequirements = async () => {
        try {
            const requirements = await jiraService.getRequirementsForProject(selectedProject);
            setExistingRequirements(requirements);
            if (requirements.length > 0) {
                setSelectedRequirement(requirements[0].key);
            }
        } catch (error) {
            console.error('Error loading requirements:', error);
            setError('Failed to load existing requirements');
        }
    };

    useEffect(() => {
        console.log('Sections updated:', sections.map(section => ({
            title: section.title,
            itemCount: section.items.length,
            items: section.items.map(item => ({
                name: item.name,
                selected: item.selected,
                hasContent: !!item.content,
                hasConsolidatedContent: !!item.consolidatedContent
            }))
        })));
    }, [sections]);

    const loadData = async () => {
        try {
            // Load projects
            const projectList = await jiraService.getProjects();
            setProjects(projectList);
            if (projectList.length > 0) {
                setSelectedProject(projectList[0].key);
            }

            // Load file system data
            const fs = FileSystemService.getInstance();
            const root = fs.getFileSystem();

            // Process each main section
            const newSections: ExportSection[] = [];
            const processSection = (folder: TreeNode, title: string) => {
           
                // Get all non-Jira folders
                const items: ExportItem[] = [];
                folder.children?.forEach(outerChild => {
                    if (outerChild.type === 'folder' && !outerChild.name.includes('Jira')) {
                        console.log(`Found outer item in ${title}:`, outerChild);

                        // Create consolidated content for the item
                        const consolidatedContent = createConsolidatedContent(outerChild);

                        items.push({
                            ...outerChild,
                            selected: false,
                            consolidatedContent
                        });
                    }
                });

                if (items.length > 0) {
                    console.log(`Adding section ${title} with items:`, items);
                    newSections.push({ title, items });
                }
            };

            const reqFolder = root.children?.find(c => c.name === 'Requirements');
            const scenFolder = root.children?.find(c => c.name === 'Test Scenarios');
            const caseFolder = root.children?.find(c => c.name === 'Test Cases');

            if (reqFolder) processSection(reqFolder, 'Requirements');
            if (scenFolder) processSection(scenFolder, 'Test Scenarios');
            if (caseFolder) processSection(caseFolder, 'Test Cases');

            setSections(newSections);
        } catch (error) {
            setError('Failed to load data');
            console.error('Error loading data:', error);
        }
    };

    const handleItemSelect = (sectionIndex: number, itemIndex: number) => {
        setSections(prev => {
            const newSections = [...prev];
            const item = newSections[sectionIndex].items[itemIndex];
            item.selected = !item.selected;

            // Update preview if this item was selected
            if (item.selected) {
                console.log('Selected item structure:', {
                    name: item.name,
                    type: item.type,
                    content: item.content,
                    children: item.children?.map(child => ({
                        name: child.name,
                        type: child.type,
                        content: child.content,
                        children: child.children?.map(grandchild => ({
                            name: grandchild.name,
                            type: grandchild.type,
                            content: grandchild.content
                        }))
                    })),
                    consolidatedContent: item.consolidatedContent
                });
                setSelectedNode(item);
            } else if (selectedNode?.id === item.id) {
                setSelectedNode(null);
            }

            return newSections;
        });
    };

    const handleExport = async () => {
        if (!selectedProject) {
            setError('Please select a project');
            return;
        }

        const selectedItems = sections.flatMap(section =>
            section.items.filter(item => item.selected)
        ).map(item => ({
            id: item.id,
            name: item.name,
            type: item.type,
            content: item.content?.fullDescription,
            consolidatedContent: item.consolidatedContent,
            parentKey: item.parentKey
        }));

        if (selectedItems.length === 0) {
            setError('Please select at least one item to export');
            return;
        }

        if (exportMode === 'existing' && !selectedRequirement) {
            setError('Please select a target requirement');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const fs = FileSystemService.getInstance();
            await fs.exportToJira(selectedItems, {
                mode: exportMode,
                targetRequirement: exportMode === 'existing' ? selectedRequirement : undefined,
                projectKey: selectedProject
            });

            // Update status for exported items
            setSections(prev => prev.map(section => ({
                ...section,
                items: section.items.map(item => ({
                    ...item,
                    status: item.selected ? 'success' : undefined
                }))
            })));

            // Refresh data after export
            await loadData();
        } catch (error) {
            setError('Failed to export items to Jira');
            console.error('Export error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getNodeContent = (node: TreeNode): string => {
        // Check for direct content first
        if (node.content?.fullDescription) {
            return node.content.fullDescription;
        }

        // Look for description in Description folder
        const descFolder = node.children?.find(child => child.name === 'Description');
        if (descFolder) {
            // Try to find content node
            const contentNode = descFolder.children?.find(child => child.name === 'content');
            if (contentNode?.content?.fullDescription) {
                return contentNode.content.fullDescription;
            }
            // If no content node, try first child
            if (descFolder.children?.[0]?.content?.fullDescription) {
                return descFolder.children[0].content.fullDescription;
            }
        }

        // Try looking in the first child if it's a folder
        const firstChild = node.children?.[0];
        if (firstChild?.type === 'folder') {
            return getNodeContent(firstChild);
        }

        return '';
    };

    const getAcceptanceCriteria = (node: TreeNode): string[] => {
        // Look for Acceptance Criteria folder
        const acFolder = node.children?.find(child => child.name === 'Acceptance Criteria');
        if (acFolder?.children) {
            return acFolder.children
                .filter(child => child.content?.fullDescription || child.name)
                .map(child => child.content?.fullDescription || child.name);
        }

        // Try looking in the first child if it's a folder
        const firstChild = node.children?.[0];
        if (firstChild?.type === 'folder') {
            return getAcceptanceCriteria(firstChild);
        }

        return [];
    };

    const createConsolidatedContent = (node: TreeNode): ConsolidatedContent => {
        const mainDescription = getNodeContent(node);
        const acceptanceCriteria = getAcceptanceCriteria(node);

        // Create export description in Jira format
        let exportDescription = mainDescription + '\n\n';

        if (acceptanceCriteria.length > 0) {
            exportDescription += 'h2. Acceptance Criteria\n';
            acceptanceCriteria.forEach((criterion, index) => {
                exportDescription += `# ${criterion}\n`;
            });
        }

        return {
            mainDescription,
            acceptanceCriteria,
            exportDescription: exportDescription.trim(),
            source: 'internal' as const,
            relationships: {
                parentId: undefined,
                linkedItems: []
            },
            lastModified: new Date().toISOString()
        };
    };

    const getTestDetails = (node: TreeNode): string => {
        const detailsFolder = node.children?.find(child => child.name === 'Test Details');
        return detailsFolder?.content?.fullDescription || '';
    };

    const getRequirements = (node: TreeNode): string => {
        const reqFolder = node.children?.find(child => child.name === 'Requirements');
        return reqFolder?.content?.fullDescription || '';
    };

    const getTestSteps = (node: TreeNode): string[] => {
        const stepsFolder = node.children?.find(child => child.name === 'Test Steps');
        if (!stepsFolder?.children) return [];

        return stepsFolder.children
            .filter(child => child.content?.fullDescription)
            .map(child => child.content.fullDescription);
    };

    const getExpectedResults = (node: TreeNode): string => {
        const resultsFolder = node.children?.find(child => child.name === 'Results');
        const expectedResult = resultsFolder?.children?.find(child => child.name === 'Expected Result');
        return expectedResult?.content?.fullDescription || '';
    };

    const handleNodeSelect = (node: TreeNode) => {
        console.log('Selected node:', {
            node,
            content: getNodeContent(node),
            acceptanceCriteria: node.name.startsWith('REQ-') ? getAcceptanceCriteria(node) : [],
            testDetails: node.name.startsWith('TS-') ? getTestDetails(node) : '',
            requirements: node.name.startsWith('TS-') ? getRequirements(node) : '',
            testSteps: node.name.startsWith('TC-') ? getTestSteps(node) : [],
            expectedResults: node.name.startsWith('TC-') ? getExpectedResults(node) : ''
        });

        // Create consolidated content if it doesn't exist
        const consolidatedContent = createConsolidatedContent(node);

        // Convert TreeNode to ExportItem
        const exportItem: ExportItem = {
            ...node,
            selected: true,
            consolidatedContent
        };
        setSelectedNode(exportItem);
    };

    return (
        <div className="jira-export-manager">
            <div className="export-controls">
                <select
                    value={selectedProject}
                    onChange={(e) => setSelectedProject(e.target.value)}
                    disabled={isLoading}
                >
                    <option value="">Select Project</option>
                    {projects.map(project => (
                        <option key={project.key} value={project.key}>
                            {project.name}
                        </option>
                    ))}
                </select>

                {sections.find(s => s.title === 'Requirements')?.items.some(i => i.selected) && (
                    <div className="export-mode-controls">
                        <select
                            value={exportMode}
                            onChange={(e) => setExportMode(e.target.value as ExportMode)}
                            disabled={isLoading}
                        >
                            <option value="new">Create as new requirement</option>
                            <option value="existing">Export to existing requirement</option>
                        </select>

                        {exportMode === 'existing' && (
                            <div className="existing-requirement-selector">
                                <select
                                    value={selectedRequirement}
                                    onChange={(e) => setSelectedRequirement(e.target.value)}
                                    disabled={isLoading}
                                >
                                    <option value="">Select Target Requirement</option>
                                    {existingRequirements.map(req => (
                                        <option key={req.key} value={req.key}>
                                            {req.key} - {req.summary}
                                        </option>
                                    ))}
                                </select>
                                <div className="warning-message">
                                    <FiAlertTriangle className="warning-icon" />
                                    <span>New content will be appended to the existing requirement</span>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <button
                    onClick={handleExport}
                    disabled={isLoading || !selectedProject}
                >
                    {isLoading ? 'Exporting...' : 'Export to Jira'}
                </button>
            </div>

            {/* Left panel - Export items */}
            <div className="w-1/2 p-4 border-r border-neutral-200 dark:border-neutral-700 overflow-y-auto">
                <div className="mb-6">
                    <select
                        value={selectedProject}
                        onChange={(e) => setSelectedProject(e.target.value)}
                        className="w-full p-2 border border-neutral-200 dark:border-neutral-700 rounded-lg bg-white dark:bg-neutral-800 text-neutral-900 dark:text-white"
                    >
                        {projects.map(project => (
                            <option key={project.key} value={project.key}>
                                {project.name} ({project.key})
                            </option>
                        ))}
                    </select>
                </div>

                {sections.map((section, sectionIndex) => (
                    <div key={section.title} className="mb-6">
                        <h4 className="font-medium text-neutral-900 dark:text-white mb-2">
                            {section.title}
                        </h4>
                        <div className="space-y-2">
                            {section.items.map((item, itemIndex) => (
                                <div
                                    key={item.id}
                                    className={`p-3 rounded-lg cursor-pointer transition-colors ${item.selected
                                        ? 'bg-blue-50 dark:bg-blue-900/20'
                                        : 'bg-white dark:bg-neutral-800 hover:bg-neutral-50 dark:hover:bg-neutral-700/50'
                                        }`}
                                    onClick={() => handleItemSelect(sectionIndex, itemIndex)}
                                >
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 mr-3">
                                            <div className={`w-5 h-5 rounded border ${item.selected
                                                ? 'border-blue-500 bg-blue-500 dark:border-blue-400 dark:bg-blue-400'
                                                : 'border-neutral-300 dark:border-neutral-600'
                                                }`}>
                                                {item.selected && (
                                                    <FiCheck className="w-full h-full text-white" />
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex-grow">
                                            <div className="font-medium text-neutral-900 dark:text-white">
                                                {item.name}
                                            </div>
                                            {item.jiraMetadata?.issueKey && (
                                                <div className="text-sm text-neutral-500">
                                                    Jira: {item.jiraMetadata.issueKey}
                                                </div>
                                            )}
                                        </div>
                                        {item.status && (
                                            <div className="ml-3">
                                                {item.status === 'success' ? (
                                                    <FiCheck className="w-5 h-5 text-green-500" />
                                                ) : item.status === 'error' ? (
                                                    <FiAlertCircle className="w-5 h-5 text-red-500" />
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

                {error && (
                    <div className="mb-6 p-3 bg-red-50 dark:bg-red-900/20 text-red-700 dark:text-red-400 rounded-lg">
                        {error}
                    </div>
                )}

                <div className="sticky bottom-0 bg-white dark:bg-neutral-900 pt-4">
                    <button
                        onClick={handleExport}
                        className="btn-primary w-full"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Exporting...' : 'Export Selected Items'}
                    </button>
                </div>
            </div>

            {/* Right panel - Preview */}
            <div className="w-1/2 p-4 bg-neutral-50 dark:bg-neutral-800/50 overflow-y-auto">
                <h3 className="text-lg font-semibold mb-4 text-neutral-900 dark:text-white">
                    Preview
                </h3>
                {selectedNode ? (
                    <div className="space-y-4">
                        <div className="p-4 bg-white dark:bg-neutral-800 rounded-lg">
                            <h4 className="font-medium mb-2 text-neutral-900 dark:text-white">
                                {selectedNode.name}
                            </h4>
                            <div className="prose dark:prose-invert max-w-none">
                                {selectedNode.children?.map((child, index) => {
                                    if (child.name === 'Description') {
                                        const contentNode = child.children?.find(c => c.name === 'content');
                                        if (contentNode?.content?.fullDescription) {
                                            return (
                                                <div key={index} className="mb-4">
                                                    <h5 className="font-medium text-neutral-900 dark:text-white mb-2">Description</h5>
                                                    <p className="text-neutral-700 dark:text-neutral-300">
                                                        {contentNode.content.fullDescription}
                                                    </p>
                                                </div>
                                            );
                                        }
                                        return null;
                                    }
                                    const hasAcceptanceCriteria = child.children && child.children.length > 0;
                                    if (child.name === 'Acceptance Criteria' && hasAcceptanceCriteria) {
                                        return (
                                            <div key={index} className="mb-4">
                                                <h5 className="font-medium text-neutral-900 dark:text-white mb-2">Acceptance Criteria</h5>
                                                <ul className="list-disc pl-5 space-y-2">
                                                    {child.children?.map((criterion, idx) => (
                                                        <li key={idx} className="text-neutral-700 dark:text-neutral-300">
                                                            {criterion.content?.fullDescription || criterion.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                                {(!selectedNode.children || selectedNode.children.length === 0) && (
                                    <div className="text-neutral-500">
                                        No structured content available. Using consolidated content:
                                        <div className="mt-2">
                                            {selectedNode.consolidatedContent ? (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedNode.consolidatedContent.exportDescription
                                                            .replace(/\n/g, '<br />')
                                                            .replace(/h2\./g, '<h2>')
                                                            .replace(/h3\./g, '<h3>')
                                                    }}
                                                />
                                            ) : (
                                                <p>No preview available</p>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Export Format Preview */}
                        <div className="p-4 bg-white dark:bg-neutral-800 rounded-lg">
                            <h5 className="font-medium text-neutral-900 dark:text-white mb-2">Export Format Preview</h5>
                            <div className="prose dark:prose-invert max-w-none">
                                {selectedNode.consolidatedContent ? (
                                    <div
                                        className="p-3 bg-neutral-50 dark:bg-neutral-700/50 rounded"
                                        dangerouslySetInnerHTML={{
                                            __html: selectedNode.consolidatedContent.exportDescription
                                                .replace(/\n/g, '<br />')
                                                .replace(/h2\./g, '<h2>')
                                                .replace(/h3\./g, '<h3>')
                                        }}
                                    />
                                ) : (
                                    <p className="text-neutral-500">No export format preview available</p>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="text-neutral-500 dark:text-neutral-400">
                        Select an item to preview its content
                    </div>
                )}
            </div>
        </div>
    );
}; 