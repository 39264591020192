import React, { useState, useEffect } from 'react';
import { TreeNode, FileSystemService } from '../services/fileSystemService';
import { FiDownload, FiSettings, FiCheck } from 'react-icons/fi';

interface ExportItem extends TreeNode {
  selected: boolean;
  consolidatedContent?: ConsolidatedContent;
}

interface ExportSection {
  title: string;
  items: ExportItem[];
}

interface ConsolidatedContent {
  mainDescription: string;
  acceptanceCriteria: string[];
  testSteps?: string[];
  expectedResults?: string[];
  exportDescription: string;
  source: 'internal' | 'jira';
  relationships: {
    parentId?: string;
    linkedItems?: string[];
  };
  lastModified: string;
}

const CsvExportPage: React.FC = () => {
  const [sections, setSections] = useState<ExportSection[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'id',
    'name',
    'type',
    'description',
    'acceptanceCriteria',
    'testSteps',
    'expectedResults'
  ]);
  const [previewData, setPreviewData] = useState<string[][]>([]);

  useEffect(() => {
    loadData();
  }, []);

  // Update preview when selections change
  useEffect(() => {
    updatePreview();
  }, [sections, selectedColumns]);

  const loadData = async () => {
    try {
      const fs = FileSystemService.getInstance();
      const root = fs.getFileSystem();

      // Process each main section
      const newSections: ExportSection[] = [];
      
      const processSection = (folder: TreeNode, title: string) => {
        const items: ExportItem[] = [];
        
        const processFolder = (node: TreeNode) => {
          // Skip Jira folders
          if (node.name.includes('Jira')) return;

          // Check if this is a content node
          const isContentNode = 
            // Check content types
            node.content?.content?.type === 'requirement' ||
            node.content?.content?.type === 'test-scenario' ||
            node.content?.content?.type === 'test-case' ||
            // Check name patterns, but only if they're not parent folders
            (node.type === 'folder' && (
              (node.name.startsWith('REQ-') && !node.children?.some(child => child.name.startsWith('REQ-'))) ||
              (node.name.startsWith('TS-') && !node.children?.some(child => child.name.startsWith('TS-'))) ||
              (node.name.startsWith('TC-') && !node.children?.some(child => child.name.startsWith('TC-')))
            ));

          if (isContentNode) {
            console.log('Found content node:', node.name, node.content?.type || node.content?.content?.type);
            const consolidatedContent = createConsolidatedContent(node);
            
            // Get the full name/description for display
            let displayName = node.name;
            if (node.content?.content?.description) {
              displayName = node.content.content.description;
            } else if (node.content?.description) {
              displayName = node.content.description;
            } else if (node.content?.fullDescription) {
              displayName = node.content.fullDescription;
            } else {
              // Try to get description from Description folder
              const descFolder = node.children?.find(child => child.name === 'Description');
              if (descFolder?.content?.description) {
                displayName = descFolder.content.description;
              } else if (descFolder?.content?.fullDescription) {
                displayName = descFolder.content.fullDescription;
              } else if (descFolder?.children?.[0]?.content?.fullDescription) {
                displayName = descFolder.children[0].content.fullDescription;
              }
            }

            // Ensure the ID is always part of the display name
            if (!displayName.includes(node.name) && node.name.match(/^(REQ|TS|TC)-/)) {
              displayName = `${node.name} - ${displayName}`;
            }

            items.push({
              ...node,
              name: displayName,
              selected: false,
              consolidatedContent
            });
          }
          
          // Always recurse into children unless it's a special folder
          if (node.children && !node.name.match(/^(Description|Acceptance Criteria|Test Steps|Results|content)$/)) {
            node.children.forEach(child => {
              if (child.type === 'folder') {
                processFolder(child);
              }
            });
          }
        };

        // Start processing from the main folder
        processFolder(folder);

        if (items.length > 0) {
          newSections.push({ title, items });
        }
      };

      const reqFolder = root.children?.find(c => c.name === 'Requirements');
      const scenFolder = root.children?.find(c => c.name === 'Test Scenarios');
      const caseFolder = root.children?.find(c => c.name === 'Test Cases');

      if (reqFolder) processSection(reqFolder, 'Requirements');
      if (scenFolder) processSection(scenFolder, 'Test Scenarios');
      if (caseFolder) processSection(caseFolder, 'Test Cases');

      setSections(newSections);
    } catch (error) {
      console.error('Error loading data:', error);
    }
  };

  const getNodeContent = (node: TreeNode): string => {
    // For test cases, get the description from content
    if (node.content?.content?.description) {
      return node.content.content.description;
    }

    // For other nodes, try direct content
    if (node.content?.description) {
      return node.content.description;
    }
    if (node.content?.fullDescription) {
      return node.content.fullDescription;
    }

    // Try description folder
    const descFolder = node.children?.find(child => child.name === 'Description');
    if (descFolder?.content?.description) {
      return descFolder.content.description;
    }
    if (descFolder?.content?.fullDescription) {
      return descFolder.content.fullDescription;
    }

    return '';
  };

  const getAcceptanceCriteria = (node: TreeNode): string[] => {
    const criteria: string[] = [];

    // Helper function to recursively collect criteria
    const collectCriteria = (currentNode: TreeNode) => {
      if (currentNode.name === 'Acceptance Criteria' && currentNode.children) {
        currentNode.children.forEach(child => {
          if (child.content?.description) {
            criteria.push(child.content.description);
          } else if (child.content?.fullDescription) {
            criteria.push(child.content.fullDescription);
          } else if (child.name && !child.name.includes('Acceptance Criteria')) {
            criteria.push(child.name);
          }
        });
      }

      // Recursively check children
      currentNode.children?.forEach(child => collectCriteria(child));
    };

    collectCriteria(node);
    return criteria;
  };

  const getTestSteps = (node: TreeNode): string[] => {
    // For test cases, get steps from content
    if (node.content?.content?.steps) {
      return Array.isArray(node.content.content.steps) 
        ? node.content.content.steps 
        : [node.content.content.steps];
    }

    // For other nodes, try tree structure
    const steps: string[] = [];
    const stepsNode = node.children?.find(child => child.name === 'Test Steps');
    if (stepsNode?.children) {
      stepsNode.children.forEach(child => {
        if (child.content?.description) {
          steps.push(child.content.description);
        } else if (child.content?.fullDescription) {
          steps.push(child.content.fullDescription);
        }
      });
    }
    return steps;
  };

  const getExpectedResults = (node: TreeNode): string[] => {
    // For test cases, get expected results from content
    if (node.content?.content?.expectedResults) {
      return Array.isArray(node.content.content.expectedResults)
        ? node.content.content.expectedResults
        : [node.content.content.expectedResults];
    }

    // For other nodes, try tree structure
    const results: string[] = [];
    const resultsNode = node.children?.find(child => 
      child.name === 'Expected Results' || child.name === 'Results'
    );
    if (resultsNode?.children) {
      resultsNode.children.forEach(child => {
        if (child.content?.description) {
          results.push(child.content.description);
        } else if (child.content?.fullDescription) {
          results.push(child.content.fullDescription);
        }
      });
    }
    return results;
  };

  const getPreconditions = (node: TreeNode): string[] => {
    // For test cases, get preconditions from content
    if (node.content?.content?.preconditions) {
      return [node.content.content.preconditions];
    }

    // For other nodes, try tree structure
    const preconditions: string[] = [];
    const preconditionsNode = node.children?.find(child => child.name === 'Preconditions');
    if (preconditionsNode?.children) {
      preconditionsNode.children.forEach(child => {
        if (child.content?.description) {
          preconditions.push(child.content.description);
        } else if (child.content?.fullDescription) {
          preconditions.push(child.content.fullDescription);
        }
      });
    }
    return preconditions;
  };

  const getTestData = (node: TreeNode): string => {
    // For test cases, get test data from content
    if (node.content?.content?.testData) {
      return node.content.content.testData;
    }

    // For other nodes, try tree structure
    const testDataNode = node.children?.find(child => child.name === 'Test Data');
    if (testDataNode?.content?.description) {
      return testDataNode.content.description;
    }
    if (testDataNode?.content?.fullDescription) {
      return testDataNode.content.fullDescription;
    }
    return '';
  };

  const getActualResults = (node: TreeNode): string => {
    // For test cases, get actual results from content
    if (node.content?.content?.actualResults) {
      return node.content.content.actualResults;
    }

    // For other nodes, try tree structure
    const resultsNode = node.children?.find(child => child.name === 'Actual Results');
    if (resultsNode?.content?.description) {
      return resultsNode.content.description;
    }
    if (resultsNode?.content?.fullDescription) {
      return resultsNode.content.fullDescription;
    }
    return '';
  };

  const getStatus = (node: TreeNode): string => {
    // For test cases, get status from content
    if (node.content?.content?.status) {
      return node.content.content.status;
    }

    // For other nodes, try tree structure
    const statusNode = node.children?.find(child => child.name === 'Status');
    if (statusNode?.content?.description) {
      return statusNode.content.description;
    }
    if (statusNode?.content?.fullDescription) {
      return statusNode.content.fullDescription;
    }
    return '';
  };

  const getComments = (node: TreeNode): string => {
    // For test cases, get comments from content
    if (node.content?.content?.comments) {
      return node.content.content.comments;
    }

    // For other nodes, try tree structure
    const commentsNode = node.children?.find(child => child.name === 'Comments');
    if (commentsNode?.content?.description) {
      return commentsNode.content.description;
    }
    if (commentsNode?.content?.fullDescription) {
      return commentsNode.content.fullDescription;
    }
    return '';
  };

  const createConsolidatedContent = (node: TreeNode): ConsolidatedContent => {
    const mainDescription = getNodeContent(node);
    const acceptanceCriteria = getAcceptanceCriteria(node);
    const testSteps = getTestSteps(node);
    const expectedResults = getExpectedResults(node);
    const preconditions = getPreconditions(node);
    const testData = getTestData(node);
    const actualResults = getActualResults(node);
    const status = getStatus(node);
    const comments = getComments(node);

    // Create a comprehensive description that includes all test case data
    let fullDescription = mainDescription;
    if (preconditions.length > 0) {
      fullDescription += '\n\nPreconditions:\n' + preconditions.join('\n');
    }
    if (testData) {
      fullDescription += '\n\nTest Data:\n' + testData;
    }
    if (testSteps.length > 0) {
      fullDescription += '\n\nSteps:\n' + testSteps.join('\n');
    }
    if (expectedResults.length > 0) {
      fullDescription += '\n\nExpected Results:\n' + expectedResults.join('\n');
    }
    if (actualResults) {
      fullDescription += '\n\nActual Results:\n' + actualResults;
    }
    if (status) {
      fullDescription += '\n\nStatus:\n' + status;
    }
    if (comments) {
      fullDescription += '\n\nComments:\n' + comments;
    }

    return {
      mainDescription: fullDescription,
      acceptanceCriteria,
      testSteps,
      expectedResults,
      exportDescription: fullDescription,
      source: 'internal' as const,
      relationships: {
        parentId: undefined,
        linkedItems: []
      },
      lastModified: new Date().toISOString()
    };
  };

  const handleItemSelect = (sectionIndex: number, itemIndex: number) => {
    setSections(prevSections => {
      const newSections = prevSections.map((section, secIdx) => {
        if (secIdx !== sectionIndex) return section;
        
        const newItems = section.items.map((item, itemIdx) => {
          if (itemIdx !== itemIndex) return item;
          return { ...item, selected: !item.selected };
        });
        
        return { ...section, items: newItems };
      });
      
      return newSections;
    });
  };

  const handleColumnToggle = (column: string) => {
    setSelectedColumns(prev => {
      if (prev.includes(column)) {
        return prev.filter(c => c !== column);
      }
      return [...prev, column];
    });
  };

  const getNodeName = (node: TreeNode): string => {
    // Try to get the full name from various sources
    if (node.content?.fullDescription) {
      // Extract the first line which is typically the full title
      const firstLine = node.content.fullDescription.split('\n')[0];
      if (firstLine.includes(node.name.split(' - ')[0])) {
        return firstLine;
      }
    }
    
    // If we have a description folder, try to get the name from there
    const descFolder = node.children?.find(child => child.name === 'Description');
    if (descFolder?.children?.[0]?.content?.fullDescription) {
      const firstLine = descFolder.children[0].content.fullDescription.split('\n')[0];
      if (firstLine.includes(node.name.split(' - ')[0])) {
        return firstLine;
      }
    }

    // Fallback to the node's name if we couldn't find a better source
    return node.name;
  };

  const updatePreview = () => {
    const selectedItems = sections.flatMap(section =>
      section.items.filter(item => item.selected)
    );

    if (selectedItems.length === 0) {
      setPreviewData([]);
      return;
    }

    const headers = selectedColumns.map(col => col.replace(/([A-Z])/g, ' $1').trim());
    const rows = selectedItems.map(item => {
      return selectedColumns.map(column => {
        switch (column) {
          case 'id':
            return item.id;
          case 'name':
            return item.name;
          case 'type':
            return item.content?.type || item.type || '';
          case 'description':
            return item.consolidatedContent?.mainDescription || '';
          case 'acceptanceCriteria':
            return item.consolidatedContent?.acceptanceCriteria?.join('\n') || '';
          case 'testSteps':
            return item.consolidatedContent?.testSteps?.join('\n') || '';
          case 'expectedResults':
            return item.consolidatedContent?.expectedResults?.join('\n') || '';
          default:
            return '';
        }
      });
    });

    setPreviewData([headers, ...rows]);
  };

  const handleExport = () => {
    if (previewData.length === 0) return;

    const csvContent = previewData
      .map(row => 
        row.map(cell => 
          `"${(cell || '').toString().replace(/"/g, '""')}"`
        ).join(',')
      )
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-full">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-semibold text-neutral-200">Export to CSV</h1>
        <button
          className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors flex items-center gap-2"
          onClick={handleExport}
          disabled={previewData.length === 0}
        >
          <FiDownload className="w-4 h-4" />
          Export Selected
        </button>
      </div>

      {/* Top Section - Selection and Settings */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 mb-8">
        {/* Left Panel - Hierarchical View */}
        <div className="lg:col-span-3 bg-neutral-800 rounded-lg p-6 border border-neutral-700">
          <h2 className="text-lg font-medium text-neutral-200 mb-4">Select Items to Export</h2>
          <div className="space-y-6">
            {sections.map((section, sectionIndex) => (
              <div key={section.title}>
                <h3 className="text-sm font-medium text-neutral-400 mb-2">{section.title}</h3>
                <div className="space-y-2">
                  {section.items.map((item, itemIndex) => (
                    <div
                      key={`${sectionIndex}-${itemIndex}-${item.id}`}
                      className={`p-3 rounded-lg cursor-pointer transition-colors ${
                        item.selected
                          ? 'bg-orange-400/10 border border-orange-400/30'
                          : 'bg-neutral-700/30 border border-transparent hover:border-neutral-600'
                      }`}
                      onClick={() => handleItemSelect(sectionIndex, itemIndex)}
                    >
                      <div className="flex items-center gap-3">
                        <div 
                          className={`w-5 h-5 rounded border flex items-center justify-center ${
                            item.selected
                              ? 'border-orange-400 bg-orange-400'
                              : 'border-neutral-600'
                          }`}
                        >
                          {item.selected && <FiCheck className="w-3 h-3 text-white" />}
                        </div>
                        <span className="text-sm text-neutral-200">{item.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Right Panel - Export Configuration */}
        <div className="bg-neutral-800 rounded-lg p-6 border border-neutral-700">
          <div className="flex items-center gap-2 mb-4">
            <FiSettings className="w-5 h-5 text-neutral-400" />
            <h2 className="text-lg font-medium text-neutral-200">Export Settings</h2>
          </div>

          <div>
            <label className="block text-sm font-medium text-neutral-400 mb-2">
              Select Columns to Export
            </label>
            <div className="space-y-2">
              {[/* eslint-disable @typescript-eslint/no-unused-vars */
                'name',
                'type',
                'description',
                'acceptanceCriteria',
                'testSteps',
                'expectedResults'
              /* eslint-enable @typescript-eslint/no-unused-vars */].map(column => (
                <div key={column} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={selectedColumns.includes(column)}
                    onChange={() => handleColumnToggle(column)}
                    className="w-4 h-4 rounded border-neutral-600 bg-neutral-700 text-orange-500 focus:ring-orange-500"
                  />
                  <span 
                    className="text-sm text-neutral-300 capitalize select-none"
                    onClick={() => handleColumnToggle(column)}
                  >
                    {column.replace(/([A-Z])/g, ' $1').trim()}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section - Preview */}
      <div className="bg-neutral-800 rounded-lg p-6 border border-neutral-700">
        <h2 className="text-lg font-medium text-neutral-200 mb-4">Preview</h2>
        <div className="bg-neutral-900 rounded-lg p-4 border border-neutral-700 overflow-x-auto">
          {previewData.length > 0 ? (
            <table className="min-w-full divide-y divide-neutral-700">
              <thead>
                <tr>
                  {previewData[0].map((header, i) => (
                    <th
                      key={i}
                      className="px-3 py-2 text-left text-xs font-medium text-neutral-400 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-neutral-800">
                {previewData.slice(1).map((row, i) => (
                  <tr key={i}>
                    {row.map((cell, j) => (
                      <td
                        key={j}
                        className="px-3 py-2 text-sm text-neutral-300 whitespace-nowrap overflow-hidden text-ellipsis"
                        style={{ maxWidth: '200px' }}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-sm text-neutral-500">
              Select items to see preview...
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CsvExportPage;