import React from 'react';
import axiosInstance from './axiosInstance';
import { jiraService } from './jiraService';

export interface ConsolidatedContent {
  mainDescription: string;
  acceptanceCriteria: string[];
  testSteps?: string[];
  expectedResults?: string[];
  exportDescription: string;
  source: 'internal' | 'jira';
  relationships: {
    parentId?: string;
    linkedItems?: string[];
  };
  lastModified: string;
}

export interface TreeNode {
  id: string;
  name: string;
  type: 'file' | 'folder';
  children?: TreeNode[];
  content?: any;
  componentType?: string;
  consolidatedContent?: ConsolidatedContent;
  jiraMetadata?: {
    issueKey?: string;
    issueType?: string;
    lastSyncedAt?: string;
    syncStatus?: 'synced' | 'modified' | 'new';
    relationships?: {
      type: 'parent' | 'implements' | 'tests' | 'relates';
      targetKey: string;
    }[];
  };
}

// Helper function to create consolidated content
function createConsolidatedContent(
  node: TreeNode,
  source: 'internal' | 'jira' = 'internal'
): ConsolidatedContent {
  let mainDescription = '';
  const acceptanceCriteria: string[] = [];
  const testSteps: string[] = [];
  const expectedResults: string[] = [];

  // Process main description
  if (node.children) {
    const descFolder = node.children.find(child => child.name === 'Description');
    if (descFolder?.children?.[0]?.content?.description) {
      mainDescription = descFolder.children[0].content.description;
    }

    // Process acceptance criteria
    const acFolder = node.children.find(child => child.name === 'Acceptance Criteria');
    if (acFolder?.children) {
      acFolder.children.forEach(criterion => {
        if (criterion.name && !criterion.name.includes('Acceptance Criteria')) {
          acceptanceCriteria.push(criterion.name);
        }
      });
    }

    // Process test steps if they exist
    const stepsFolder = node.children.find(child => child.name === 'Test Steps');
    if (stepsFolder?.children) {
      stepsFolder.children.forEach(step => {
        if (step.name && !step.name.includes('Test Steps')) {
          testSteps.push(step.name);
        }
      });
    }

    // Process expected results if they exist
    const resultsFolder = node.children.find(child => child.name === 'Expected Result');
    if (resultsFolder?.children?.[0]?.content?.description) {
      expectedResults.push(resultsFolder.children[0].content.description);
    }
  }

  // Create export description in Jira format
  let exportDescription = mainDescription + '\n\n';

  if (acceptanceCriteria.length > 0) {
    exportDescription += 'h2. Acceptance Criteria\n';
    acceptanceCriteria.forEach((criterion, index) => {
      exportDescription += `${index + 1}. ${criterion}\n`;
    });
    exportDescription += '\n';
  }

  if (testSteps.length > 0) {
    exportDescription += 'h2. Test Steps\n';
    testSteps.forEach((step, index) => {
      exportDescription += `${index + 1}. ${step}\n`;
    });
    exportDescription += '\n';
  }

  if (expectedResults.length > 0) {
    exportDescription += 'h2. Expected Results\n';
    expectedResults.forEach(result => {
      exportDescription += `${result}\n`;
    });
  }

  return {
    mainDescription,
    acceptanceCriteria,
    testSteps,
    expectedResults,
    exportDescription: exportDescription.trim(),
    source,
    relationships: {
      parentId: node.jiraMetadata?.relationships?.find(r => r.type === 'parent')?.targetKey,
      linkedItems: node.jiraMetadata?.relationships?.filter(r => r.type !== 'parent').map(r => r.targetKey) || []
    },
    lastModified: new Date().toISOString()
  };
}

export class FileSystemService {
  private static instance: FileSystemService;
  private fileSystem: TreeNode;
  private listeners: (() => void)[] = [];

  private constructor() {
    // Initialize root structure
    this.fileSystem = {
      id: 'root',
      name: 'Project',
      type: 'folder',
      children: [
        {
          id: 'requirements',
          name: 'Requirements',
          type: 'folder',
          children: []
        },
        {
          id: 'scenarios',
          name: 'Test Scenarios',
          type: 'folder',
          children: []
        },
        {
          id: 'testcases',
          name: 'Test Cases',
          type: 'folder',
          children: []
        },
        {
          id: 'automation',
          name: 'Automation Code',
          type: 'folder',
          children: []
        }
      ]
    };

    // Load imported issues
    this.loadImportedIssues();
  }

  public async loadImportedIssues(): Promise<void> {
    try {
      const response = await axiosInstance.get('/api/jira/imported-issues');
      const importedIssues = response.data;

      // Process each imported issue
      importedIssues.forEach((issue: any) => {
        this.addJiraIssue({
          key: issue.issueKey,
          fields: {
            summary: issue.summary,
            description: issue.description,
            customfield_10020: issue.acceptanceCriteria,
            issuetype: {
              name: issue.issueType
            }
          }
        });
      });
    } catch (error) {
      console.error('Error loading imported issues:', error);
    }
  }

  public static getInstance(): FileSystemService {
    if (!FileSystemService.instance) {
      FileSystemService.instance = new FileSystemService();
    }
    return FileSystemService.instance;
  }

  public getFileSystem(): TreeNode {
    return this.fileSystem;
  }

  public subscribe(listener: () => void): void {
    this.listeners.push(listener);
  }

  public unsubscribe(listener: () => void): void {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  private notifyListeners(): void {
    this.listeners.forEach(listener => listener());
  }

  public saveFile(path: string[], content: any, componentType: string): void {
    let current = this.fileSystem;

    // Navigate to the correct folder
    for (let i = 0; i < path.length - 1; i++) {
      const folder = current.children?.find(child => child.name === path[i]);
      if (!folder) {
        throw new Error(`Folder ${path[i]} not found`);
      }
      current = folder;
    }

    // Create new file
    const fileName = path[path.length - 1];
    const newFile: TreeNode = {
      id: `${Date.now()}`,
      name: fileName,
      type: 'file',
      content,
      componentType
    };

    if (!current.children) {
      current.children = [];
    }
    current.children.push(newFile);
    this.notifyListeners();
  }

  public createFolder(path: string[], content?: any): void {
    let current = this.fileSystem;

    for (const folderName of path) {
      let folder = current.children?.find(child => child.name === folderName);

      if (!folder) {
        folder = {
          id: `${Date.now()}`,
          name: folderName,
          type: 'folder',
          children: [],
          content: content
        };

        if (!current.children) {
          current.children = [];
        }
        current.children.push(folder);
      } else if (content && path[path.length - 1] === folderName) {
        folder.content = content;
      }

      // If this is a root-level folder for requirements, test scenarios, or test cases
      // create consolidated content
      if (path.length === 1 &&
        (folderName.startsWith('REQ-') ||
          folderName.startsWith('TS-') ||
          folderName.startsWith('TC-'))) {
        folder.consolidatedContent = createConsolidatedContent(folder);
      }

      current = folder;
    }
    this.notifyListeners();
  }

  public getFile(path: string[]): TreeNode | null {
    let current = this.fileSystem;

    for (const segment of path) {
      const node = current.children?.find(child => child.name === segment);
      if (!node) {
        return null;
      }
      current = node;
    }

    return current;
  }

  public updateRequirements(requirements: any[]): void {
    // Find the Requirements folder
    const requirementsFolder = this.fileSystem.children?.find(child => child.name === 'Requirements');
    if (!requirementsFolder) {
      throw new Error('Requirements folder not found');
    }

    // Create a new timestamp folder
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    const timestampFolder: TreeNode = {
      id: `timestamp-${Date.now()}`,
      name: timestamp,
      type: 'folder',
      children: []
    };

    // Add requirements to the timestamp folder
    requirements.forEach((req, index) => {
      const reqFolder: TreeNode = {
        id: `req-${Date.now()}-${index}`,
        name: `Requirement ${index + 1}`,
        type: 'folder',
        children: [
          {
            id: `desc-${Date.now()}-${index}`,
            name: 'description.txt',
            type: 'file',
            content: req.description
          }
        ]
      };

      // Add acceptance criteria if they exist
      if (req.acceptanceCriteria && req.acceptanceCriteria.length > 0) {
        req.acceptanceCriteria.forEach((criteria: string, criteriaIndex: number) => {
          reqFolder.children?.push({
            id: `ac-${Date.now()}-${index}-${criteriaIndex}`,
            name: `${criteriaIndex + 1}`,
            type: 'file',
            content: criteria
          });
        });
      }

      timestampFolder.children?.push(reqFolder);
    });

    requirementsFolder.children?.push(timestampFolder);
    this.notifyListeners();
  }


  public addJiraIssue(issue: any): void {
    // Determine the target folder based on issue type
    const issueType = issue.fields.issuetype?.name || 'Story';
    console.log('Adding Jira issue:', {
      key: issue.key,
      type: issueType,
      rawType: issue.fields.issuetype
    });

    let targetFolderName: string;
    let contentType: string;

    switch (issueType) {
      case 'Bug':
      case 'Test':
        targetFolderName = 'Test Cases';
        contentType = 'bug-description';
        break;
      case 'Subtask':
        targetFolderName = 'Test Scenarios';
        contentType = 'subtask-description';
        break;
      default:
        targetFolderName = 'Requirements';
        contentType = 'user-story';
    }

    console.log('Target folder:', targetFolderName);

    // Find the target folder
    const targetFolder = this.fileSystem.children?.find(child => child.name === targetFolderName);
    if (!targetFolder) {
      throw new Error(`${targetFolderName} folder not found`);
    }

    // Create a Jira folder if it doesn't exist
    let jiraFolder = targetFolder.children?.find(child => child.name === 'Jira');
    if (!jiraFolder) {
      jiraFolder = {
        id: `jira-${Date.now()}`,
        name: 'Jira',
        type: 'folder',
        componentType: 'jira-container',
        children: []
      };
      targetFolder.children?.push(jiraFolder);
    }

    // Check if issue already exists
    const existingIssue = jiraFolder.children?.find(child =>
      child.content?.key === issue.key || child.name === issue.fields.summary
    );
    if (existingIssue) {
      console.log(`Issue ${issue.key} already exists`);
      return;
    }

    // Create issue folder with the summary as its display name
    const issueFolder: TreeNode = {
      id: `jira-issue-${Date.now()}`,
      name: `${issue.fields.summary} (${issue.key})`,
      type: 'folder',
      content: {
        key: issue.key,
        summary: issue.fields.summary,
        issueType: issueType,
        description: issue.fields.description || 'No description provided'
      },
      componentType: 'jira-requirement',
      jiraMetadata: {
        issueKey: issue.key,
        issueType: issueType,
        lastSyncedAt: new Date().toISOString(),
        syncStatus: 'synced',
        relationships: []
      },
      children: [
        {
          id: `description-${Date.now()}`,
          name: issueType === 'Bug' ? 'Bug Description' :
            issueType === 'Subtask' ? 'Subtask Description' :
              'User Story',
          type: 'file',
          content: {
            description: issue.fields.description || 'No description provided',
            type: contentType
          },
          componentType: 'jira-content'
        }
      ]
    };

    // Add parent relationship for subtasks
    if (issueType === 'Subtask' && issue.fields.parent) {
      issueFolder.jiraMetadata!.relationships = [{
        type: 'parent',
        targetKey: issue.fields.parent.key
      }];
    }

    // Add acceptance criteria if they exist
    if (issue.fields.customfield_10020) {
      issueFolder.children?.push({
        id: `ac-${Date.now()}`,
        name: 'Acceptance Criteria',
        type: 'file',
        content: {
          description: issue.fields.customfield_10020,
          type: 'acceptance-criteria'
        },
        componentType: 'jira-content'
      });
    }

    // Add bug-specific fields
    if (issueType === 'Bug') {
      if (issue.fields.environment) {
        issueFolder.children?.push({
          id: `env-${Date.now()}`,
          name: 'Environment',
          type: 'file',
          content: {
            description: issue.fields.environment,
            type: 'environment'
          },
          componentType: 'jira-content'
        });
      }
      if (issue.fields.customfield_10029) { // Assuming this is 'Steps to Reproduce'
        issueFolder.children?.push({
          id: `steps-${Date.now()}`,
          name: 'Steps to Reproduce',
          type: 'file',
          content: {
            description: issue.fields.customfield_10029,
            type: 'steps-to-reproduce'
          },
          componentType: 'jira-content'
        });
      }
    }

    // Create consolidated content for Jira issues
    issueFolder.consolidatedContent = createConsolidatedContent(issueFolder, 'jira');

    jiraFolder.children?.push(issueFolder);
    this.notifyListeners();
  }

  private updateNodesJiraMetadata(
    items: Array<{
      id: string;
      name: string;
      type: string;
      content?: string;
      consolidatedContent?: ConsolidatedContent;
      parentKey?: string;
    }>,
    result: { [key: string]: { status: string; key?: string; error?: string } }
  ): void {
    items.forEach(item => {
      const node = this.findNodeById(item.id);
      if (node && result[item.id]?.key) {
        node.jiraMetadata = {
          issueKey: result[item.id].key!,
          lastSyncedAt: new Date().toISOString()
        };
      }
    });
    this.notifyListeners();
  }

  public async exportToJira(items: Array<{
    id: string;
    name: string;
    type: string;
    content?: string;
    consolidatedContent?: ConsolidatedContent;
    parentKey?: string;
  }>, options?: {
    mode: 'new' | 'existing';
    targetRequirement?: string;
    projectKey: string;
  }): Promise<{ [key: string]: { status: string; key?: string; error?: string } }> {
    const result = await jiraService.exportToJira(items, options);

    // Update the nodes with the new Jira metadata
    this.updateNodesJiraMetadata(items, result);

    return result;
  }

  private getNodePath(node: TreeNode): string[] {
    const findPath = (current: TreeNode, target: TreeNode, path: string[] = []): string[] | null => {
      if (current.id === target.id) return [...path, current.name];

      if (!current.children) return null;

      for (const child of current.children) {
        const result = findPath(child, target, [...path, current.name]);
        if (result) return result;
      }

      return null;
    };

    const path = findPath(this.fileSystem, node) || [];
    return path;
  }

  private async prepareJiraExportData(nodes: TreeNode[]): Promise<any[]> {
    return nodes.map(node => {
      // Base export data
      const exportData = {
        id: node.id,
        name: node.name,
        content: node.content,
        componentType: node.componentType,
        jiraMetadata: node.jiraMetadata,
      };

      // Add type-specific data
      switch (node.componentType) {
        case 'requirement':
          return {
            ...exportData,
            issueType: 'Story',
            summary: node.name,
            description: node.content,
          };
        case 'test-case':
          return {
            ...exportData,
            issueType: 'Test',
            summary: node.name,
            description: node.content,
          };
        case 'test-scenario':
          return {
            ...exportData,
            issueType: 'Sub-task',
            summary: node.name,
            description: node.content,
          };
        case 'automation-code':
          return {
            ...exportData,
            issueType: 'Task',
            summary: `Automation: ${node.name}`,
            description: node.content,
          };
        default:
          return exportData;
      }
    });
  }

  public getNodeJiraStatus(node: TreeNode): 'synced' | 'modified' | 'new' | 'error' {
    if (!node.jiraMetadata) return 'new';
    return node.jiraMetadata.syncStatus || 'new';
  }

  public async validateJiraExport(nodes: TreeNode[]): Promise<{
    valid: boolean;
    errors?: { nodeId: string; message: string }[];
  }> {
    try {
      const response = await axiosInstance.post('/api/jira/validate-export', nodes);
      return response.data;
    } catch (error) {
      console.error('Error validating Jira export:', error);
      throw error;
    }
  }

  // Helper method to find related nodes
  public findRelatedNodes(node: TreeNode): TreeNode[] {
    if (!node.jiraMetadata?.relationships) return [];

    const relatedNodes: TreeNode[] = [];
    const relationships = node.jiraMetadata.relationships;

    relationships.forEach(rel => {
      const found = this.findNodeByJiraKey(rel.targetKey);
      if (found) relatedNodes.push(found);
    });

    return relatedNodes;
  }

  private findNodeByJiraKey(issueKey: string): TreeNode | null {
    const searchNode = (node: TreeNode): TreeNode | null => {
      if (node.jiraMetadata?.issueKey === issueKey) return node;
      if (node.children) {
        for (const child of node.children) {
          const found = searchNode(child);
          if (found) return found;
        }
      }
      return null;
    };

    return searchNode(this.fileSystem);
  }

  private findNodeById(id: string): TreeNode | null {
    const findInNode = (node: TreeNode): TreeNode | null => {
      if (node.id === id) {
        return node;
      }
      if (node.children) {
        for (const child of node.children) {
          const found = findInNode(child);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    return findInNode(this.fileSystem);
  }

  public updateNode(path: string[], updatedContent: any): void {
    let current = this.fileSystem;
    let node = current;
    
    // Navigate to the node
    for (const segment of path) {
      const found = current.children?.find(child => child.name === segment);
      if (!found) {
        // Create the node if it doesn't exist
        const newNode: TreeNode = {
          id: `${Date.now()}`,
          name: segment,
          type: 'folder',
          children: [],
          content: {}
        };
        if (!current.children) current.children = [];
        current.children.push(newNode);
        current = newNode;
        node = newNode;
      } else {
        current = found;
        node = found;
      }
    }
    
    // Update the node's content and name if it's a requirement
    if (updatedContent.type === 'requirement') {
      node.name = `${updatedContent.id} - ${updatedContent.fullDescription}`;
      node.content = updatedContent;
    } else {
      node.content = updatedContent;
    }

    // If this is an Acceptance Criteria folder and we're clearing children
    if (node.name === "Acceptance Criteria" && node.children?.length === 0) {
      // Keep the empty children array to indicate no acceptance criteria
      node.children = [];
    }
    
    this.notifyListeners();
  }
}

export function useFileSystem() {
  const [instance] = React.useState(() => FileSystemService.getInstance());
  const [, forceUpdate] = React.useState({});

  React.useEffect(() => {
    // Only load imported issues once on mount
    const loadInitialData = async () => {
      await instance.loadImportedIssues();
    };
    loadInitialData();

    const handleRefresh = () => {
      // Instead of getting the file system, just force a re-render
      forceUpdate({});
    };

    instance.subscribe(handleRefresh);
    return () => {
      instance.unsubscribe(handleRefresh);
    };
  }, []); // Empty dependency array since we only want this on mount

  return instance;
}
