import React, { useState } from "react";
import { generateUnitTests, UnitTestResult } from "../services/aiService";
import { Link } from "react-router-dom";
import logo from "./veloai-logo.png";
import { TextArea, Select } from "./ui/FormElements";
import { withCredits } from "../utils/withCredits";

const languages = [
  { value: "javascript", label: "JavaScript" },
  { value: "typescript", label: "TypeScript" },
  { value: "python", label: "Python" },
  { value: "csharp", label: "C# (.NET)" },
  { value: "java", label: "Java" },
];

const frameworks = {
  javascript: ["jest", "mocha", "jasmine"],
  typescript: ["jest", "mocha", "jasmine"],
  python: ["pytest", "unittest"],
  csharp: ["nunit", "xunit", "mstest"],
  java: ["junit", "testng"],
};

function UnitTestGenerator() {
  const [sourceCode, setSourceCode] = useState("");
  const [requirements, setRequirements] = useState("");
  const [testScenarios, setTestScenarios] = useState("");
  const [language, setLanguage] = useState("javascript");
  const [framework, setFramework] = useState("jest");
  const [generatedTests, setGeneratedTests] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    setFramework(frameworks[newLanguage as keyof typeof frameworks][0]);
  };

  const handleGenerateUnitTests = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const result: UnitTestResult = await withCredits(
        "unit-test-generator",
        () =>
          generateUnitTests(
            sourceCode,
            requirements,
            testScenarios,
            language,
            framework
          )
      );
      setGeneratedTests(result.tests);
    } catch (error) {
      console.error("Error generating unit tests:", error);
      setError(
        "An error occurred while generating unit tests. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-velo-blue-900/90 via-black to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-16">
          <div className="flex items-center space-x-6">
            <img
              src={logo}
              alt="VeloAI Logo"
              className="h-6 w-auto filter drop-shadow-glow"
            />
            <div>
              <div className="text-velo-orange-500/80 text-sm font-medium mb-1">
                Testing Tools
              </div>
              <h1 className="text-4xl font-bold text-white tracking-tight">
                Unit Test Generator
              </h1>
            </div>
          </div>
          <Link
            to="/"
            className="group flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors duration-200"
          >
            <span>Return to Dashboard</span>
            <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
              →
            </span>
          </Link>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 gap-8">
          {/* Source Code Section */}
          <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
            <div className="px-8 py-6">
              <TextArea
                label="Source Code"
                value={sourceCode}
                onChange={(e) => setSourceCode(e.target.value)}
                placeholder="Paste your source code here..."
                rows={12}
                className="font-mono text-sm"
              />
            </div>
          </div>

          {/* Configuration Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Requirements */}
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <TextArea
                  label="Requirements"
                  value={requirements}
                  onChange={(e) => setRequirements(e.target.value)}
                  placeholder="Describe your testing requirements..."
                  rows={5}
                />
              </div>
            </div>

            {/* Test Scenarios */}
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <TextArea
                  label="Test Scenarios"
                  value={testScenarios}
                  onChange={(e) => setTestScenarios(e.target.value)}
                  placeholder="Define your test scenarios..."
                  rows={5}
                />
              </div>
            </div>
          </div>

          {/* Language and Framework */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <Select
                  label="Programming Language"
                  value={language}
                  onChange={handleLanguageChange}
                  options={languages.map((lang) => ({
                    value: lang.value,
                    label: lang.label,
                  }))}
                />
              </div>
            </div>

            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <Select
                  label="Testing Framework"
                  value={framework}
                  onChange={(e) => setFramework(e.target.value)}
                  options={frameworks[language as keyof typeof frameworks].map(
                    (fw) => ({
                      value: fw,
                      label: fw,
                    })
                  )}
                />
              </div>
            </div>
          </div>

          {/* Generate Button */}
          <div className="flex justify-center mt-8">
            <button
              onClick={handleGenerateUnitTests}
              disabled={isLoading || !sourceCode.trim()}
              className={`
                group relative px-8 py-4 rounded-xl font-medium text-base
                ${
                  isLoading
                    ? "bg-velo-blue-900/50 cursor-not-allowed"
                    : "bg-gradient-to-r from-velo-orange-500 to-velo-orange-600 hover:to-velo-orange-500"
                }
                text-white transition-all duration-300 shadow-lg hover:shadow-velo-orange-500/20
                transform hover:-translate-y-0.5
              `}
            >
              <span className="flex items-center space-x-2">
                {isLoading ? (
                  <>
                    <span className="opacity-70">Generating Tests</span>
                    <span className="animate-pulse">...</span>
                  </>
                ) : (
                  <>
                    <span>Generate Unit Tests</span>
                    <span className="transform group-hover:translate-x-0.5 transition-transform duration-150">
                      →
                    </span>
                  </>
                )}
              </span>
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mt-6 backdrop-blur-sm bg-red-500/10 rounded-xl border border-red-500/20 p-4">
              <p className="text-red-400 text-center text-sm">{error}</p>
            </div>
          )}

          {/* Generated Tests Output */}
          {generatedTests && (
            <div className="backdrop-blur-sm bg-velo-blue-900/10 rounded-2xl border border-velo-blue-500/20 overflow-hidden shadow-xl">
              <div className="px-8 py-6">
                <h2 className="text-2xl font-bold text-white mb-6">
                  Generated Unit Tests
                </h2>
                <pre className="bg-black/20 border border-velo-blue-500/20 rounded-lg p-4 overflow-x-auto">
                  <code className="text-gray-300 font-mono text-sm whitespace-pre-wrap">
                    {generatedTests}
                  </code>
                </pre>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UnitTestGenerator;
